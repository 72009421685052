body {
	font-family: Roboto, sans-serif;
	color: #333;
	font-size: 1rem;
	line-height: 1.5rem;
}

h1 {
	margin-top: 20px;
	margin-bottom: 10px;
	color: #333;
	font-size: 1.8rem;
	line-height: 2.3rem;
	font-weight: 700;
}

h2 {
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	color: #333;
	font-size: 1.5rem;
	line-height: 1.9rem;
	font-weight: 600;
	letter-spacing: 0.02rem;
}

h3 {
	margin-top: 1.5rem;
	margin-bottom: 0.5rem;
	color: #333;
	font-size: 1.25rem;
	line-height: 1.775rem;
	font-weight: 600;
	letter-spacing: 0.02rem;
}

h4 {
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	color: #333;
	font-size: 1.1rem;
	line-height: 1.5rem;
	font-weight: 600;
	letter-spacing: 0.01rem;
}

h5 {
	margin-top: 1.375rem;
	margin-bottom: 0.75rem;
	color: #333;
	font-size: 0.9rem;
	line-height: 1.25rem;
	font-weight: 600;
	letter-spacing: 0.01rem;
}

h6 {
	margin-top: 1.5rem;
	margin-bottom: 0.875rem;
	color: #333;
	font-size: 0.8rem;
	line-height: 1.25rem;
	font-weight: 600;
	letter-spacing: 0.01rem;
	text-transform: uppercase;
}

.header-section {
	position: fixed;
	z-index: 100;
	width: 100%;
	box-shadow: none;
}

.top-icon-div {
	top: 0px;
	right: 0px;
	z-index: 10000;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-top: 0rem;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.top-icon-link-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 36px;
	height: 36px;
	margin-right: 0.4rem;
	margin-left: 0.4rem;
	padding: 0.25rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 50%;
	background-color: #e77c25;
	-webkit-transition: all 300ms cubic-bezier(0.842, 0.007, 0.772, 1.001);
	transition: all 300ms cubic-bezier(0.842, 0.007, 0.772, 1.001);
	text-align: center;
}

.top-icon-link-block:hover {
	background-color: #b1aba2;
}

.top-icon-link-block.first-icon-link {
	margin-left: 0rem;
	padding: 0.25rem;
}

.top-icon-link-block.first-icon-link.header {
	width: 55px;
	height: 34px;
	max-width: 55px;
	margin-right: 0.15rem;
	margin-left: 0.15rem;
	padding-right: 0.15rem;
	padding-left: 0.15rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-transition-duration: 450ms;
	transition-duration: 450ms;
	text-decoration: none;
}

.top-icon-link-block.first-icon-link.header.hidden {
	display: none;
}

.top-icon-link-block.header {
	width: 50px;
	height: 34px;
	max-width: 50px;
	margin-right: 0.25rem;
	margin-left: 0.25rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	background-color: transparent;
	text-decoration: none;
}

.top-icon-link-block.header.wishlist {
	width: 55px;
	height: 34px;
	max-height: none;
	max-width: 50px;
	min-height: 0px;
	min-width: 0px;
	margin-right: 0.15rem;
	margin-left: 0.15rem;
	padding-right: 0.15rem;
	padding-left: 0.15rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	text-decoration: none;
}

.top-icon-link-block.header.phone {
	width: 34px;
	max-width: 34px;
	margin-right: 0.5rem;
	padding: 0rem;
}

.top-icon-link-block.header.basket {
	width: 55px;
	max-width: 55px;
	margin-right: 0.15rem;
	margin-left: 0.15rem;
	padding-right: 0.15rem;
	padding-left: 0.15rem;
}

.top-icon-link-block.header.stores {
	width: 55px;
	max-width: 50px;
	margin-right: 0.15rem;
	margin-left: 0.15rem;
	padding-right: 0.15rem;
	padding-left: 0.15rem;
}

.top-icon-link-block.header.support {
	width: 55px;
	max-width: 50px;
	margin-right: 0.15rem;
	margin-left: 0.15rem;
	padding-right: 0.15rem;
	padding-left: 0.15rem;
}

.top-icon-link-block.header.mobile-cart {
	width: 55px;
	max-width: 50px;
	margin-right: 0.15rem;
	margin-left: 0.15rem;
	padding-right: 0.15rem;
	padding-left: 0.15rem;
}

.top-icon {
	max-height: 22px;
	max-width: 22px;
	min-height: 20px;
	min-width: 20px;
	-webkit-transform: translate(0px, 0px);
	-ms-transform: translate(0px, 0px);
	transform: translate(0px, 0px);
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
}

.top-icon:hover {
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.main-nav-link {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	margin-right: 1px;
	margin-left: 1px;
	padding: 0.6rem 0.25rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	color: #000;
	font-size: 0.8rem;
	line-height: 1rem;
	font-weight: 700;
	text-align: center;
	letter-spacing: 0.01rem;
	text-decoration: none;
	text-transform: uppercase;
}

.main-nav-link:hover {
	background-color: #58585a;
	color: #fff;
}

.main-nav-link.w--current {
	border-color: #e77c25;
}

.main-nav-link.mobile-duplicate {
	display: none;
}

.main-nav-link.hidden {
	display: none;
}

.main-nav-link.gaming {
	width: 100%;
}

.main-nav-link.full {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-right: 0px;
	margin-left: 0px;
	font-size: 0.85rem;
	line-height: 0.85rem;
}

.main-nav-link.full.withicon {
	display: none;
}

.nav-menu {
	z-index: 2;
	display: block;
	width: 100%;
	height: 3.25rem;
	float: right;
	background-color: #ffcb05;
	box-shadow: 0 7px 20px 0 rgba(0, 0, 0, 0.12);
	color: #fff;
}

.brand {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 100%;
	min-height: 60px;
	padding: 0.1rem;
	float: left;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
}

.brand.w--current {
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: transparent;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
}

.dropdown-link {
	display: block;
	width: 100%;
	padding: 0.2rem 0.5rem 0.2rem 0.75rem;
	border-bottom: 1px none rgba(0, 0, 0, 0.2);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #666;
	font-size: 0.83rem;
	line-height: 0.9rem;
	font-weight: 400;
	text-decoration: none;
	text-transform: none;
}

.dropdown-link:hover {
	line-height: 0.9rem;
	background-color: hsla(0, 0%, 100%, 0.7);
	color: #000;
}

.dropdown-link.w--current {
	background-color: hsla(0, 0%, 100%, 0.7);
	box-shadow: inset 4px 0 0 0 #e77c25;
	color: #1f304f;
}

.dropdown-link.subcategory {
	font-weight: 700;
	text-transform: none;
	color: #000;
	text-transform: uppercase;
	font-size: 0.85rem;
	padding-bottom: 1px;
}

.navbar {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #fff;
}

.logo-icon-image {
	-webkit-transform: translate(-17px, 0px);
	-ms-transform: translate(-17px, 0px);
	transform: translate(-17px, 0px);
	-webkit-transition: opacity 300ms ease, width 100ms ease, height 100ms ease;
	transition: opacity 300ms ease, width 100ms ease, height 100ms ease;
}

.two-part-mobile-nav-wrapper {
	display: none;
}

.container {
	display: block;
	max-width: 1300px;
	margin-right: auto;
	margin-left: auto;
}

.container.nav-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 3.25rem;
	max-width: 1600px;
	padding-left: 0rem;
	float: none;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.container.nav-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 3.25rem;
	max-width: 1600px;
	padding-left: 0rem;
	float: none;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.container.nav-container .dropdown-wrapper.dropdown-wrapper-custom:nth-child(3) {
	min-width: 155px;
}

.dropdown-list {
	position: absolute;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: auto;
	display: none;
	overflow: visible;
	height: 0px;
	max-width: none;
	min-width: 100%;
	margin-top: 51px;
	padding-right: 0rem;
	padding-left: 0rem;
	opacity: 0;
	overflow-x: auto;
	text-align: left;
	background: #fff;
	box-shadow: 1px 1px 25px 0 rgba(0, 0, 0, 0.15);
}

.icon-only {
	position: absolute;
	width: 60px;
	height: 56px;
	opacity: 0;
	-webkit-transition: width 100ms ease, height 100ms ease;
	transition: width 100ms ease, height 100ms ease;
}

.stephanis-logo {
	left: 0px;
	top: 0px;
	right: 0px;
	width: 100%;
	min-width: 350px;
	padding: 0.1rem;
}

.dropdown-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	width: 100%;
	height: 3.25rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	text-align: center;
}

.dropdown-wrapper.long-title {
	min-width: 160px;
}

.dropdown-inner-wrap {
	display: block;
	width: 93%;
	max-width: 1600px;
	margin-right: auto;
	margin-left: auto;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	text-align: left;
}

.mobile-menu-arrow {
	display: none;
}

.search-bar-div {
	left: 0px;
	top: 0px;
	z-index: 10000;
	width: 95%;
}

.search-bar::-webkit-input-placeholder {
	color: #292929;
}

.search-bar:-ms-input-placeholder {
	color: #292929;
}

.search-bar::-ms-input-placeholder {
	color: #292929;
}

.search-bar::placeholder {
	color: #292929;
}

.search-button {
	display: none;
}

.search-bar-form {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 315px;
	margin-bottom: 0px;
	padding-right: 0.5rem;
}

.search-input-hidden {
	display: block;
	width: 100%;
	margin-bottom: 0px;
	margin-left: 0.25rem;
	padding-left: 0px;
	border-style: none none solid;
	border-width: 1px;
	border-color: #fff #fff #bebebe;
	opacity: 1;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
}

.search-input-hidden:focus {
	border-bottom-color: #ffcb05;
	color: #000;
}

.slider-content-wrapper {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	margin-right: auto;
	margin-left: auto;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.slider-column-1 {
	top: 20px;
}

.slider-header {
	display: inline-block;
	margin-top: 1px;
	margin-bottom: 5px;
	padding: 0.25rem 0.75rem 0.25rem 1.25rem;
	float: left;
	clear: left;
	background-color: rgba(53, 56, 64, 0.85);
	color: #fff;
	font-size: 2rem;
	line-height: 2.65rem;
	font-weight: 300;
	text-transform: uppercase;
}

.slide-text-wrap {
	-webkit-align-self: flex-start;
	-ms-flex-item-align: start;
	align-self: flex-start;
}

.slider-cta-link {
	margin-top: 7px;
	margin-left: 11px;
	padding-top: 4px;
	padding-bottom: 4px;
	padding-left: 30px;
	float: left;
	clear: left;
	background-image: url('../images/circle_arrow_light-orange_1circle_arrow_light-orange.png');
	background-position: 0px 50%;
	background-size: 21px 21px;
	background-repeat: no-repeat;
	color: #fff;
	font-size: 16px;
	line-height: 18px;
	font-weight: 700;
	letter-spacing: 0.04rem;
	text-decoration: none;
	text-transform: uppercase;
}

.slider-cta-link:hover {
	background-image: url('../images/circle_arrow_50pct-white_1circle_arrow_50pct white.png');
	color: hsla(0, 0%, 100%, 0.5);
}

.slider-nav {
	display: none;
	padding: 2.5rem 2rem 1.5rem 1rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	font-size: 0.5rem;
	line-height: 1.5rem;
}

.slider-image-div {
	position: relative;
	top: -5px;
	right: 82px;
	width: 480px;
}

.slider-widget {
	display: block;
	margin-right: auto;
	margin-left: auto;
	background-color: hsla(0, 0%, 100%, 0);
}

.container-2.slider-container {
	max-width: 1400px;
}

.slider-header-emphasis {
	font-size: 2rem;
	font-weight: 700;
}

.slider-row {
	position: relative;
	top: 55px;
}

.slider-paragraph {
	max-width: 327px;
	margin-top: 12px;
	margin-left: 1rem;
	padding: 0.5rem 0.5rem 0.5rem 1rem;
	float: left;
	clear: left;
	border-left: 4px solid #ffcb05;
	color: #fff;
}

.slider-section {
	padding-top: 144px;
	box-shadow: inset 0 5px 9px 0 rgba(0, 0, 0, 0.07);
}

.slider-arrow {
	left: auto;
	top: auto;
	right: 0%;
	bottom: 0%;
	display: block;
	width: 45px;
	height: 45px;
	margin-right: 1rem;
	margin-bottom: 1rem;
	padding-bottom: 0px;
	border-radius: 3rem;
	background-color: transparent;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	font-size: 2rem;
}

.slider-arrow:hover {
	background-color: #ffcb05;
}

.slider-arrow.left {
	left: 0%;
	top: auto;
	right: auto;
	bottom: 0%;
	display: block;
	width: 45px;
	height: 45px;
	margin-right: 0rem;
	margin-bottom: 1rem;
	margin-left: 1rem;
	background-color: transparent;
}

.slider-arrow.left:hover {
	background-color: #ffcb05;
}

.slider-div {
	display: block;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
}

.top-telephone-div {
	display: none;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.telephone-number {
	padding-right: 0.5rem;
	color: #333;
	font-size: 0.85rem;
}

.slide {
	background-image: -webkit-gradient(linear, left top, right top, from(#000), to(rgba(51, 51, 51, 0))), url('../images/phone_1903x1270.png');
	background-image: linear-gradient(90deg, #000, rgba(51, 51, 51, 0)), url('../images/phone_1903x1270.png');
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.slide-2 {
	background-image: url('../images/tv_2_1903x1003.png');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.slide-3 {
	background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.75), transparent), url('../images/electronic-gear_1903x1427.png');
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.slider-widget .slider-arrow {
	background-color: #666;
	top: 47%;
	margin-bottom: 0px;
	bottom: auto;
	width: 50px !important;
	height: 50px !important;
}

.slider-widget .slider-arrow:hover {
	background-color: #ffcb05;
}

.slider-widget .slider-arrow .main-slider-arrow-icon,
.slider-widget .slider-arrow .main-slider-arrow-icon-2 {
	font-weight: 600;
}

@media (max-width: 479px) {
	.slider-widget .slider-arrow {
		width: 45px !important;
		height: 45px !important;
	}

	.slider-widget .slider-arrow .main-slider-arrow-icon,
	.slider-widget .slider-arrow .main-slider-arrow-icon-2 {
		font-size: 1.5rem;
	}
}

.link-block {
	width: 100%;
	height: 100%;
}

.quicklink-icon-section {
	min-width: 991px;
	padding-top: 1.25rem;
	padding-bottom: 1.25rem;
}

.quicklink-icon-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 85%;
	min-width: 991px;
	margin-right: auto;
	margin-left: auto;
	padding-right: 0.25rem;
	padding-left: 0.25rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.quicklink-icon-text-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 0.5rem;
	padding-left: 0.5rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.quicklink-icon-text-wrap.para {
	padding-right: 0.5rem;
	padding-left: 0.5rem;
}

.quicklink-text-title {
	font-size: 0.95rem;
	line-height: 1.3rem;
	font-weight: 700;
	text-transform: uppercase;
}

.quicklink-text-title.repeat {
	margin-bottom: 0.75rem;
}

.quicklink-text-subtitle {
	color: #8b8b8b;
	font-size: 0.75rem;
	line-height: 1.1rem;
}

.quicklink-text-subtitle.para {
	text-align: left;
}

.quicklink-icon-div-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 25%;
	padding-right: 0.5rem;
	padding-left: 0.5rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.quicklink-icon-div-wrapper.repeat {
	width: 33%;
	min-height: 275px;
	margin-bottom: 0.5%;
	padding: 1.25rem 2rem 2rem;
	border: 1px solid #dadada;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
}

.quicklink-icon-div-wrapper.repeat:hover {
	background-color: #f1f1f1;
}

.quicklink-icon-div-wrapper.pick-up-from-store {
	display: none;
}

.image {
	width: 50px;
	height: 50px;
}

.quicklink-icon-image {
	width: 40px;
	height: 40px;
	margin-bottom: 0px;
}

.quicklink-icon-image._1 {
	width: 45px;
	height: 45px;
}

.quicklink-icon-image._1.repeat {
	width: 65px;
	height: 65px;
	padding: 0.5rem;
	border-radius: 1rem;
	background-color: #fff;
}

.quicklink-icon-image.repeat {
	width: 65px;
	height: 65px;
	padding: 0.5rem;
	border-radius: 1rem;
	background-color: #fff;
}

.content-box-section {
	padding-top: 2rem;
	padding-bottom: 2rem;
	background-color: #f1f1f1;
}

.cb-section-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	max-width: 1600px;
	min-width: 991px;
	margin-right: auto;
	margin-left: auto;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.small-cb-div-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-image: url('../images/example-bg.png');
	background-position: 0px 0px;
	background-size: 125px;
}

.cb-link-box-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 25%;
	height: 275px;
	margin-right: 0.25rem;
	margin-left: 0.25rem;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	background-position: 50% 50%;
	background-size: cover;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	text-decoration: none;
}

.cb-link-box-div:hover {
	opacity: 0.85;
}

.cb-link-box-div.large {
	width: 50%;
	background-size: cover;
}

.cb-link-box-div.large._2 {
	background-size: cover;
	background-repeat: no-repeat;
}

.cb-link-box-div._2 {
	background-size: cover;
}

.cb-link-box-div._3 {
	background-size: cover;
}

.cb-link-box-div._4 {
	background-size: cover;
}

.cb-link-box-div > div,
.cb-link-box-div > div .adshow_container,
.cb-link-box-div > div .adshow_container .slide {
	height: 100%;
	overflow: hidden;
}

.cb-link-box-div > div .adshow_container .slide img {
	object-fit: cover;
	width: 100%;
	height: auto;
}

@media (max-width: 991px) {
	.cb-link-box-div > div,
	.cb-link-box-div > div .adshow_container,
	.cb-link-box-div > div .adshow_container .slide {
		width: 100%;
	}

	.cb-link-box-div > div .adshow_container .slide img {
		height: 100%;
	}
}

.four-link-div-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
}

.two-link-div-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0.25rem;
	margin-bottom: 1rem;
}

.cb-product-slider {
	display: none;
	width: 50%;
	height: 275px;
	margin-right: 0.25rem;
	margin-left: 0.25rem;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
}

.cb-product-slider:hover {
	opacity: 0.85;
}

.cb-product-slide-1 {
	background-image: url('../images/tablet_1903x1270_1.png');
	background-position: 50% 50%;
	background-size: cover;
}

.cb-product-slider-nav {
	display: none;
}

.ribbon {
	padding-top: 2.25rem;
	padding-bottom: 2rem;
}

.ribbon-div-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 85%;
	min-width: 991px;
	margin-right: auto;
	margin-left: auto;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.ribbon-slider {
	width: 100%;
}

.ribbon-slider-nav-bar {
	display: none;
}

.property-spotlight-wrapper {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 100%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	border: 1px solid #dadada;
	background-color: #fff;
	box-shadow: none;
}

.listing-details-column {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding: 0.25rem 0.25rem 0.5rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-top: 1px solid rgba(96, 96, 96, 0.3);
	border-right: 1px solid rgba(96, 96, 96, 0.3);
	border-bottom: 1px solid rgba(96, 96, 96, 0.3);
}

.listing-details-column._3 {
	width: 33.33%;
	padding-right: 0.2rem;
	padding-left: 0.2rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	border-top-style: none;
	border-right-style: none;
	border-bottom-style: none;
}

.listing-details-column._3.half {
	width: 50%;
}

.listing-details-column.first {
	width: 50%;
	padding-right: 0.35rem;
	padding-left: 0.35rem;
	border-top-style: none;
	border-right-style: none;
	border-bottom-style: none;
}

.listing-details-column.first.trio {
	width: 33.33%;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	border-top-style: none;
	border-right-style: none;
	border-bottom-style: none;
}

.listing-details-column.single {
	border-top-style: none;
	border-right-style: none;
	border-bottom-style: none;
}

.listing-details-column.trio {
	width: 33.33%;
	padding-right: 0.2rem;
	padding-left: 0.2rem;
	border-top-style: none;
	border-right-style: none;
	border-bottom-style: none;
}

.listing-details-column.large-now-price {
	width: 100%;
	padding-right: 0.2rem;
	padding-left: 0.2rem;
	border-top-style: none;
	border-right-style: none;
	border-bottom-style: none;
}

.listing-details-column.large-now-price.large-single {
	padding-top: 0.75rem;
}

.listing-details-column.large-stephanis-card-price {
	width: 100%;
	padding-right: 0.2rem;
	padding-left: 0.2rem;
	border-top-style: none;
	border-right-style: none;
	border-bottom-style: none;
}

.listing-details-column.large-stephanis-card-price.large-single {
	padding-top: 0.75rem;
}

.listing-details-column.large-now-price-product-details {
	width: 50%;
	margin-right: 0.5rem;
	padding-right: 0.2rem;
	padding-left: 0.2rem;
	border-style: solid;
	border-right-color: hsla(194.1322314049587, 0%, 72%, 0.5);
	border-left-width: 1px;
	border-left-color: hsla(194.1322314049587, 0%, 72%, 0.5);
}

.listing-details-column.large-now-price-product-details.large-single {
	padding-top: 0.75rem;
}

.listing-details-column.large-stephanis-card-price-product-details {
	width: 50%;
	margin-right: 0.5rem;
	padding-right: 0.2rem;
	padding-left: 0.2rem;
	border-style: solid;
	border-left-width: 1px;
	border-left-color: hsla(194.1322314049587, 0%, 72%, 0.5);
}

#products_module.responsive.cms_list .item-wrapper .present-icon-wrapper-3 .present-text-wrapper .present-text-3 {
	display: flex;
	align-items: center;
	padding-right: 0px;
}

@media (min-width: 767px) {
	#products_module.responsive.cms_list .item-wrapper .present-icon-wrapper-3 .present-text-wrapper .present-text-3 {
		max-width: 250px;
	}
}

#products_module.responsive.cms_list .item-wrapper .present-icon-wrapper-3 .present-text-wrapper .present-text-3 span {
	font-weight: bold;
	font-size: 1rem;
	padding-right: 4px;
	margin-left: -2px;
}


#products_module.responsive.cms_list .item-wrapper .present-icon-wrapper-3 .present-text-wrapper .arrow-link {
	margin-right: 0rem;
}

.listing-details-column.large-stephanis-card-price-product-details.large-single {
	padding-top: 0.75rem;
}


.prop-spotlight-details-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
	padding: 0rem 0rem 0rem 1rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	border-right: 1px solid #d9d9d9;
	border-bottom: 1px solid #d9d9d9;
	background-color: transparent;
}

.listing-details-heading {
	color: #b91313;
	font-size: 1.175rem;
	line-height: 1.75rem;
	font-weight: 700;
	letter-spacing: -0.01rem;
}

.listing-details-heading.original-price {
	color: rgba(96, 96, 96, 0.5);
	font-size: 0.7rem;
	text-decoration: line-through;
}

.listing-details-heading.now-price {
	color: #606060;
	font-size: 0.9rem;
	text-decoration: none;
}

.listing-details-heading.large-now-price {
	color: #606060;
	font-size: 1.175rem;
	line-height: 1.75rem;
	text-decoration: none;
	text-align: center;
}

.listing-details-heading.large-now-price-product-details {
	color: #606060;
	font-size: 1.175rem;
	line-height: 1.75rem;
	text-decoration: none;
}

.status-bar-div {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 32px;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	background-image: -webkit-gradient(linear, left top, right top, from(transparent), to(hsla(0, 0%, 100%, 0.55)));
	background-image: linear-gradient(90deg, transparent, hsla(0, 0%, 100%, 0.55));
	text-decoration: none;
}

.property-slider-arrow-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50px;
	height: 50px;
	margin: auto 20px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 50px;
	background-color: rgba(96, 96, 96, 0.5);
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	font-size: 1.25rem;
}

.property-slider-arrow-link:hover {
	background-color: #ffde00;
}

.property-spotlight-slide {
	width: 25%;
	height: auto;
	margin-right: 0%;
	margin-left: 0%;
	padding-right: 0.25rem;
	padding-left: 0.25rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	background-color: transparent;
}

.listing-details {
	display: block;
	width: 100%;
	color: rgba(96, 96, 96, 0.5);
	font-size: 0.75rem;
	line-height: 0.65rem;
	font-weight: 700;
	text-align: center;
}

.listing-details.now-price {
	color: rgba(96, 96, 96, 0.5);
}

.listing-details.large-was-price {
	color: rgba(96, 96, 96, 0.5);
	font-size: 0.85rem;
	font-weight: 700;
	text-decoration: line-through;
}

.listing-details.large-was-price-product-details {
	margin-bottom: 0.1rem;
	color: rgba(96, 96, 96, 0.5);
	font-size: 0.85rem;
	font-weight: 700;
	text-decoration: line-through;
}

.property-slider-widget {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	max-width: 100%;
	min-width: 991px;
	margin-right: auto;
	margin-left: auto;
	padding-top: 1rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: transparent;
	color: #fff;
}

.property-spotlight-image-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 250px;
	margin-bottom: 0rem;
	padding-left: 0px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	border-bottom: 1px solid #dadada;
	background-image: url('../images/product-1_700x428.png');
	background-position: 50% 50%;
	background-size: contain;
	background-repeat: no-repeat;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	text-decoration: none;
}

.property-spotlight-image-link:hover {
	opacity: 0.85;
}

.property-spotlight-image-link._4 {
	background-image: url('../images/product-4_700x448.png');
	background-position: 50% 50%;
	background-size: contain;
	background-repeat: no-repeat;
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
}

.property-spotlight-image-link._4:hover {
	opacity: 0.85;
}

.property-spotlight-image-link._2 {
	background-image: url('../images/product-2_700x448.png');
	background-position: 50% 50%;
	background-size: contain;
	background-repeat: no-repeat;
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
}

.property-spotlight-image-link._2:hover {
	opacity: 0.85;
}

.property-spotlight-image-link._3 {
	background-image: url('../images/product-3_700x448.png');
	background-position: 50% 50%;
	background-size: contain;
	background-repeat: no-repeat;
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
}

.property-spotlight-image-link._3:hover {
	opacity: 0.85;
}

.spotlight-unordered-list {
	width: 100%;
	height: auto;
	min-height: 105px;
	margin-bottom: 0px;
	padding-right: 5px;
	padding-left: 0px;
	float: left;
}

.property-spotlight-mask {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 85%;
	min-width: 983px;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
}

.spotlight-list-text {
	margin-bottom: 0.75rem;
	padding-right: 0.5rem;
	padding-left: 0.5rem;
	color: #606060;
	font-size: 0.95rem;
	line-height: 1.3rem;
	text-align: center;
}

.spotlight-list-text.specs {
	margin-bottom: 0.25rem;
	font-size: 0.75rem;
	line-height: 1rem;
	word-break: break-word;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
	.spotlight-list-text.specs {
		display: inline-block;
		text-overflow: ellipsis;
		word-wrap: break-word;
		overflow: hidden;
		max-height: 3.5em;
		line-height: 0.9rem;
	}
}


.listing-price {
	width: 100%;
	padding-left: 0px;
	float: left;
	color: #0c3467;
	font-size: 1.65rem;
	line-height: 2.25rem;
	text-align: left;
}

.listing-status {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 100%;
	min-width: 100px;
	margin-top: -0.5rem;
	padding-right: 1rem;
	padding-left: 1rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-left: 4px solid #000;
	background-color: #bd571a;
	color: #fff;
}

.listing-status.for-sale {
	border-left-style: none;
	border-left-color: #9d3e06;
	background-color: #ffde00;
}

.listing-status.sold {
	border-left-style: none;
	border-left-color: #216183;
	background-color: #ffde00;
}

.listing-status.pending {
	position: relative;
	border-left-style: none;
	background-color: #ffde00;
	text-decoration: none;
}

.listing-status.in-tender {
	border-left-style: none;
	border-left-color: #052650;
	background-color: #ffde00;
}

.listing-status.for-sale {
	border-left-color: #9d3e06;
	background-color: #da6723;
}

.listing-status.sold {
	border-left-color: #216183;
	background-color: #288ab1;
}

.listing-status.pending {
	position: relative;
	border-left-color: #9d7d06;
	background-color: #e0b300;
}

.listing-status.in-tender {
	border-left-color: #052650;
	background-color: #325b8f;
}

.image-listing-text {
	display: none;
	width: 100%;
	height: 100%;
	margin-top: -32px;
}

.listing-status-text {
	font-weight: 700;
	text-decoration: none;
	text-transform: uppercase;
}

.listing-detail-column-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
}

.listing-detail-column-wrapper.trio {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 0rem;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	grid-auto-columns: 1fr;
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	-ms-grid-columns: 1fr 1fr;
	grid-template-columns: 1fr 1fr;
	-ms-grid-rows: auto auto;
	grid-template-rows: auto auto;
}

.spotlight-list-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	min-height: 199px;
	padding-top: 0.5rem;
	float: left;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	border-right: 1px none #dadada;
	border-left: 1px none #dadada;
}

.icon {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 45px;
	height: 45px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #666;
	font-size: 1.75rem;
	font-style: normal;
	font-weight: 600;
}

.icon:hover {
	color: #fff;
}

.text-span {
	background-color: #ff0;
}

.note {
	display: block;
	margin: 5rem auto 1rem;
	text-align: center;
}

.note.note-1 {
	margin-top: 0rem;
}

.note-paragraph {
	display: block;
	width: 80%;
	margin-right: auto;
	margin-left: auto;
	color: #000;
	font-size: 1.25rem;
	line-height: 1.75rem;
}

.property-slider-outer-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 1700px;
	margin: 2rem auto;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.cb-product-slide-2 {
	background-image: url('../images/tv_2_1903x1003.png');
	background-position: 50% 50%;
	background-size: 951px;
}

.add-to-cart-icon {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 42px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: flex-end;
	-ms-flex-item-align: end;
	align-self: flex-end;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	background-color: #ffde00;
	background-image: url('../images/basket-dk.svg');
	background-position: 50% 50%;
	background-size: 25px 25px;
	background-repeat: no-repeat;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
}

.add-to-cart-icon:hover {
	width: 100%;
	background-color: #e5e5e5;
}

.add-to-cart-icon.out-of-stock {
	background-color: #8b8b8b;
	opacity: 0.35;
}

.cart-icon-link-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.present-div {
	position: absolute;
	left: 0%;
	top: 195px;
	right: 0%;
	bottom: auto;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 55px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	opacity: 1;
}

.ribbon-title {
	font-size: 2rem;
	line-height: 2rem;
	font-weight: 500;
	text-align: center;
}

.ribbon-banner {
	min-height: 200px;
	padding-top: 2rem;
	padding-bottom: 2rem;
	background-color: #eee;
	background-image: linear-gradient(149deg, rgba(255, 222, 0, 0), #fffac7), url('../images/yellow-abstract_1903x1346.png');
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.banner-link-title {
	margin-top: 3px;
	margin-bottom: 3px;
	padding: 0.25rem 0.75rem 0.25rem 1.25rem;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	background-color: rgba(53, 56, 64, 0.85);
	color: #fff;
	font-size: 3rem;
	line-height: 3.25rem;
	font-weight: 300;
	text-decoration: none;
	text-transform: uppercase;
}

.banner-wrapper {
	display: none;
	width: 85%;
	min-width: 991px;
	margin-right: auto;
	margin-left: auto;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.text-block {
	font-weight: 500;
}

.banner-arrow {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	height: 45px;
	min-width: 155px;
	margin-top: 3px;
	padding-left: 1.25rem;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border: 2px none #fff;
	background-color: #e4c700;
	background-image: url('../images/arrow-medium_00283b.svg');
	background-position: 91% 50%;
	background-size: auto 13px;
	background-repeat: no-repeat;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	text-decoration: none;
}

.banner-arrow:hover {
	background-color: #fff;
}

.div-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.banner-link-subtitle {
	margin-top: 3px;
	margin-bottom: 3px;
	padding: 0.25rem 0.75rem 0.25rem 1.25rem;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	background-color: rgba(53, 56, 64, 0.85);
	color: #fff;
	font-size: 3rem;
	line-height: 3.25rem;
	font-weight: 700;
	text-decoration: none;
	text-transform: uppercase;
}

.learn-more-text {
	color: #171717;
	font-weight: 500;
	text-decoration: none;
	text-transform: uppercase;
}

.shop-categories-section-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 85%;
	min-width: 991px;
	margin-right: auto;
	margin-left: auto;
	padding-top: 2rem;
	padding-bottom: 2rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.four-link-div-wrapper-copy {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.shop-categories-title {
	margin-bottom: 1.5rem;
	font-size: 2rem;
	line-height: 2rem;
	font-weight: 500;
	text-align: center;
	text-transform: uppercase;
}

.category-title {
	width: 167px;
	padding-right: 0.6rem;
	padding-left: 0.6rem;
	border-left: 4px solid #ffcb05;
	background-image: -webkit-gradient(linear, right top, left top, from(hsla(0, 0%, 100%, 0.5)), color-stop(60%, hsla(0, 0%, 100%, 0.85)));
	background-image: linear-gradient(270deg, hsla(0, 0%, 100%, 0.5), hsla(0, 0%, 100%, 0.85) 60%);
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #000;
	font-size: 0.85rem;
	line-height: 3.5rem;
	text-decoration: none;
}

.shop-categories-section .category-title {
	min-height: 57px;
	display: flex;
	align-items: center;
	line-height: 1.2rem;
}

.shop-categories-section .category-title span {
	width: 145px;
	overflow-wrap: break-word;
}

.category-title:hover {
	width: 100%;
	border-left-color: #ffde00;
	color: #000;
}

.category-title._1 {
	background-color: transparent;
	background-image: -webkit-gradient(linear, right top, left top, from(hsla(0, 0%, 100%, 0.15)), to(#fff));
	background-image: linear-gradient(270deg, hsla(0, 0%, 100%, 0.15), #fff);
	font-size: 0.85rem;
}

.category-title._1:hover {
	width: 100%;
}

.category-title.level-1 {
	width: 172px;
	background-color: transparent;
	background-image: -webkit-gradient(linear, right top, left top, from(hsla(0, 0%, 100%, 0.15)), to(#ffcb05));
	background-image: linear-gradient(270deg, hsla(0, 0%, 100%, 0.15), #ffcb05);
	font-size: 0.85rem;
}

.category-title.level-1:hover {
	width: 100%;
}

.shop-cat-link-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 25%;
	height: 250px;
	margin-right: 0rem;
	margin-left: 0rem;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	background-image: url('../images/product-1_450x300.png');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	text-decoration: none;
}

.shop-cat-link-div:hover {
	opacity: 0.9;
}

.shop-cat-link-div.large {
	width: 50%;
}

.shop-cat-link-div._2 {
	background-image: url('../images/product-v2_450x300.png');
	background-size: cover;
}

.shop-cat-link-div._3 {
	background-image: url('../images/product-3_450x300.png');
	background-size: cover;
}

.shop-cat-link-div._4 {
	background-image: url('../images/product-4_450x300.png');
	background-size: cover;
}

.shop-cat-link-div._5 {
	background-image: url('../images/product-5_450x300.png');
	background-size: cover;
}

.shop-cat-link-div._6 {
	background-image: url('../images/product-6_450x300.png');
	background-size: cover;
}

.shop-cat-link-div._7 {
	background-image: url('../images/product-7_450x300.png');
	background-size: cover;
}

.shop-cat-link-div._8 {
	background-image: url('../images/product-8_450x300.png');
	background-size: cover;
}

.shop-cat-link-div.level-1 {
	width: 100%;
}

.shop-cat-link-div.level-2 {
	width: 100%;
}

.quicklinks-repeat-div {
	display: none;
	padding-top: 0rem;
	padding-bottom: 2rem;
}

.newsletter-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	min-height: 215px;
	padding-top: 2rem;
	padding-bottom: 2rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #f1f1f1;
}

.social-media-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	min-height: 215px;
	padding-top: 2rem;
	padding-bottom: 2rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-top: 4px solid #e4c700;
	border-bottom: 4px solid #e4c700;
	background-color: #ffcb05;
}

.footer-links-div {
	padding: 2rem;
	background-color: #333;
}

.signoff-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 1.5rem;
	padding-bottom: 2.5rem;
	padding-left: 2rem;
	padding-right: 2rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: #000;
}

.newsletter-title {
	margin-bottom: 0.75rem;
	font-size: 2rem;
	line-height: 2rem;
	font-weight: 500;
	text-align: center;
	text-transform: uppercase;
}

.newsletter-sub-title {
	margin-bottom: 1.5rem;
	font-size: 1rem;
	line-height: 1.6rem;
	font-weight: 400;
	text-align: center;
	text-transform: none;
}

.newsletter-form {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 500px;
	max-width: 500px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.newsletter-form-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 0px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.newsletter-form-block .newsletter-text-field {
	width: auto;
	flex-grow: 1;
}

.newsletter-text-field {
	height: 100%;
	margin-bottom: 0px;
	color: #333;
}

.newsletter-text-field::-webkit-input-placeholder {
	color: #bebebe;
}

.newsletter-text-field:-ms-input-placeholder {
	color: #bebebe;
}

.newsletter-text-field::-ms-input-placeholder {
	color: #bebebe;
}

.newsletter-text-field::placeholder {
	color: #bebebe;
}

.newsletter-submit-button {
	min-width: 121px;
	background-color: #ffcb05;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #000;
	font-weight: 500;
	text-align: center;
	text-transform: uppercase;
}

.newsletter-submit-button:hover {
	background-color: hsla(0, 0%, 75%, 0.75);
}

.newsletter-submit-button.search-filter-button {
	margin-right: 5px;
	padding: 2px 0px;
	font-size: 0.75rem;
}

.social-media-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.social-media-wrapper.footer-socmed-wrapper {
	float: right;
}

.social-media-wrapper.footer-socmed-wrapper.social-2 {
	float: none;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.top-social {
	display: flex;
}

.bottom-social {
	display: flex;
}

.social-media-link-block-2 {
	width: 2.25rem;
	height: 2.25rem;
	margin-right: 0.5rem;
	margin-left: 0.5rem;
}

.social-media-link-block-2.footer-socmed-link-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 0.75rem;
	margin-left: 0.75rem;
	padding: 2px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border: 2px solid transparent;
	border-radius: 50%;
	background-color: #333;
	background-image: none;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	cursor: pointer;
}

.social-media-link-block-2.footer-socmed-link-block:hover {
	padding: 2px;
	border: 2px solid #fff;
	background-color: #333;
	-webkit-transform: scale(1.1);
	-ms-transform: scale(1.1);
	transform: scale(1.1);
}

.footer-social-icon-image {
	display: block;
	width: 29px;
	margin-right: auto;
	margin-left: auto;
}

.footer-social-icon-image.issuu {
	width: 18px;
	height: 18px;
}

.social-media-title {
	margin-bottom: 1rem;
	color: #000;
	font-size: 2rem;
	line-height: 2rem;
	font-weight: 500;
	text-align: center;
	text-transform: uppercase;
}

.link-list-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 85%;
	min-width: 925px;
	margin-right: auto;
	margin-left: auto;
	padding-right: 0.25rem;
	padding-left: 0.25rem;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.link-list-title {
	margin-bottom: 0.5rem;
	color: #fff;
	font-weight: 500;
}

.footer-list-link {
	margin-bottom: 0.35rem;
	padding-right: 0.25rem;
	padding-bottom: 0rem;
	padding-left: 0.35rem;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	border-bottom: 1px none hsla(0, 0%, 55%, 0.25);
	border-left: 3px solid transparent;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #8b8b8b;
	line-height: 1.3rem;
	text-decoration: none;
}

.footer-list-link:hover {
	border-bottom-color: transparent;
	border-left-color: transparent;
	color: #ffcb05;
}

.list-link-div {
	width: 200px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.list-link-div.div-2 {
	width: 265px;
}

.list-link-div.div-3 {
	width: 235px;
}

.list-link-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-left: -0.5rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.grip-logo-image {
	width: 9rem;
	min-width: 150px;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
}

.grip-logo-link {
	float: right;
	opacity: 0.5;
	-webkit-transition: background-color 200ms ease, color 200ms ease, border-color 200ms ease, opacity 200ms ease;
	transition: background-color 200ms ease, color 200ms ease, border-color 200ms ease, opacity 200ms ease;
}

.grip-logo-link:hover {
	opacity: 1;
}

.footer-signoff-link {
	-webkit-transition: color 300ms ease;
	transition: color 300ms ease;
	color: #fff;
	font-size: 0.8rem;
	letter-spacing: 1px;
	text-decoration: none;
}

.footer-signoff-link:hover {
	color: #bebebe;
}

.footer-signoff-links {
	display: inline-block;
	margin-right: 1rem;
	border-bottom: 2px solid hsla(243.75000000000006, 9.41%, 33.33%, 0.5);
	line-height: 1.25rem;
}

.footer-signoff-links:hover {
	border-bottom-color: transparent;
}

.footer-copyright {
	display: inline-block;
	margin-right: 1.5rem;
	margin-bottom: 0rem;
	color: #8b8b8b;
	font-size: 0.9rem;
}

.column-3 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 0px;
	padding-left: 0px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.column-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 0px;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.acrobat-reader {
	max-width: 140px;
	opacity: 0.75;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
}

.acrobat-reader:hover {
	opacity: 1;
}

.footer-signoff-link-wrap {
	display: inline-block;
}

.acrobat-reader-link {
	width: 50%;
	margin-right: 0.25rem;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
}

.sign-off-div-wrap {
	width: 85%;
	min-width: 925px;
	margin-right: auto;
	margin-left: auto;
	padding-right: 0.25rem;
	padding-left: 0.25rem;
}

.bold-text {
	text-align: left;
}

.in-stock {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
	padding-top: 0rem;
	padding-right: 0.5rem;
	padding-bottom: 0rem;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	color: rgba(96, 96, 96, 0.5);
	font-size: 0.75rem;
}

.out-of-stock {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
	padding-top: 0rem;
	padding-right: 0.5rem;
	padding-bottom: 0rem;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	color: rgba(96, 96, 96, 0.5);
	font-size: 0.75rem;
}

.yellow-circle-div {
	position: relative;
	display: none;
	width: 1.25rem;
	height: 1.25rem;
	margin-top: -2.25rem;
	margin-left: 3rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 1rem;
	background-color: #e4c700;
	color: #fff;
	font-size: 0.8rem;
	font-weight: 700;
}

.wishlist-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.search-link-icon {
	width: 40px;
	height: 40px;
	min-height: 40px;
	min-width: 40px;
	margin-left: 0.25rem;
	background-image: url('../images/homepage-icons-search-27.svg');
	background-position: 50% 50%;
	background-size: 25px 25px;
	background-repeat: no-repeat;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
}

.search-link-icon:hover {
	background-color: transparent;
	background-image: url('../images/search-icons-02.svg');
}

.top-half-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	max-width: 1600px;
	padding: 1rem 0px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.search-column {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 58%;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	align-self: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
}

.brand-column {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 34%;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

.icon-column {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 95%;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	align-self: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
}

.subcateogry-div {
	display: inline-block;
	width: 15%;
	padding-top: 0.2rem;
	padding-bottom: 1rem;
	float: left;
	padding-right: 5px;
	padding-left: 5px;
}

.phone-icon {
	min-height: 18px;
	min-width: 18px;
}

.cb-left-arrow {
	border-left: 5px solid transparent;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
}

.cb-left-arrow:hover {
	border-left-color: #e4c700;
	background-color: rgba(0, 0, 0, 0.7);
}

.cb-rightt-arrow {
	border-right: 5px solid transparent;
	border-left: 5px none transparent;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
}

.cb-rightt-arrow:hover {
	border-right-color: #e4c700;
	border-left-color: #e4c700;
	background-color: rgba(0, 0, 0, 0.7);
}

#product-installation-fees-info {
	padding: 1rem 0 0.25rem;
	color: #666;
	line-height: 0.95rem;
	font-size: 0.85rem;
	font-weight: 700;
}

.present-text {
	padding-right: 0.5rem;
	color: #666;
	font-size: 0.7rem;
	line-height: 0.95rem;
	font-weight: 500;
}

.present-icon {
	min-height: 35px;
	min-width: 35px;
	background-color: #ffde00;
	background-image: url('../images/present-icon_black.svg');
	background-position: 50% 50%;
	background-size: 24px 24px;
	background-repeat: no-repeat;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
}

.present-icon:hover {
	background-color: transparent;
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.present-icon-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	min-height: 42px;
	min-width: 50px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: hsla(0, 0%, 75%, 0.35);
}

.present-icon-wrapper.no-present {
	max-height: 50px;
	min-width: 100%;
}

.present-text-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	min-height: 45px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	opacity: 1;
}

.present-text-wrapper.no-present {
	min-width: 100%;
}

.search-link-icon-mobile {
	display: none;
	width: 40px;
	height: 40px;
	min-height: 40px;
	min-width: 40px;
	background-image: url('../images/search-icons-02.svg');
	background-position: 50% 50%;
	background-size: 25px 25px;
	background-repeat: no-repeat;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
}

.search-link-icon-mobile:hover {
	background-color: #f1f1f1;
	background-image: url('../images/search-icons-01.svg');
}

.about-us-content-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 66.66%;
	min-width: 725px;
	margin-right: auto;
	margin-left: auto;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.spotlight-details-wrapper {
	width: 100%;
	padding: 1rem 15px;
	background-color: #fff;
}

.multi-slider-container {
	display: block;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	color: #091d49;
}

.multi-slider-container.multi-slider {
	display: block;
	max-width: 1700px;
}

.property-spotlight-wrapper-2 {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 100%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	border-style: solid none none;
	border-width: 1px;
	border-color: #dadada;
}

.ribbon-slider-new {
	display: block;
	margin-right: auto;
	margin-left: auto;
	background-color: #fff;
}

.listing-details-column-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding: 0.25rem 0.5rem 0.5rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-top: 1px solid rgba(96, 96, 96, 0.3);
	border-right: 1px solid rgba(96, 96, 96, 0.3);
	border-bottom: 1px solid rgba(96, 96, 96, 0.3);
}

.listing-details-column-2._3 {
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	border-right-style: none;
}

.listing-details-column-2.first {
	width: 100%;
}

.prop-spotlight-details-wrapper-2 {
	width: 100%;
	padding: 1rem 15px;
	background-color: #fff;
}

.listing-two-column-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
	max-width: 100%;
	min-width: 50%;
	float: left;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
}

.duo-set-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.listing-details-heading-2 {
	color: #606060;
	font-weight: 700;
}

.slide-4 {
	width: 100%;
	padding: 2rem 6rem;
	background-color: transparent;
	background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(55, 28%, 92%, 0.7)), to(hsla(55, 28%, 92%, 0.7)));
	background-image: linear-gradient(180deg, hsla(55, 28%, 92%, 0.7), hsla(55, 28%, 92%, 0.7));
}

.slide-4.features-2 {
	padding-top: 0rem;
	background-color: hsla(55, 28%, 92%, 0.7);
	background-image: none;
}

.slide-4.features-1 {
	padding-top: 0rem;
	background-image: none;
}

.status-bar-div-2 {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 32px;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	background-image: -webkit-gradient(linear, left top, right top, from(transparent), to(hsla(0, 0%, 100%, 0.55)));
	background-image: linear-gradient(90deg, transparent, hsla(0, 0%, 100%, 0.55));
}

.property-slider-arrow-link-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 62px;
	height: 62px;
	margin: auto 20px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 50px;
	background-color: rgba(96, 96, 96, 0.5);
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	font-size: 1.25rem;
}

.property-slider-arrow-link-2:hover {
	background-color: #bd571a;
}

.spotlight-image-link {
	width: 100%;
	height: 300px;
	margin-bottom: 0rem;
	background-image: url('../images/home_content_01_500x500.jpg');
	background-position: 0px 0px;
	background-size: cover;
}

.spotlight-image-link:hover {
	border-bottom-color: #6dcdf4;
}

.spotlight-image-link._2 {
	background-image: url('../images/home_content_02_500x500.jpg');
	background-position: 50% 50%;
	background-size: 100%;
	background-repeat: no-repeat;
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
}

.spotlight-image-link._2:hover {
	background-size: 110%;
	opacity: 0.85;
}

.spotlight-image-link._5 {
	background-image: url('../images/home_content_05_500x500.jpg');
	background-position: 50% 50%;
	background-size: 100%;
	background-repeat: no-repeat;
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
}

.spotlight-image-link._5:hover {
	background-size: 110%;
	opacity: 0.85;
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
}

.spotlight-image-link._8 {
	background-image: url('../images/home_content_08_500x500.jpg');
	background-position: 50% 50%;
	background-size: 100%;
	background-repeat: no-repeat;
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
}

.spotlight-image-link._8:hover {
	background-size: 110%;
	opacity: 0.85;
}

.spotlight-image-link._3 {
	background-image: url('../images/home_content_03_500x500.jpg');
	background-position: 50% 50%;
	background-size: 100%;
	background-repeat: no-repeat;
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
}

.spotlight-image-link._3:hover {
	background-image: url('../images/home_content_03_500x500.jpg');
	background-position: 50% 50%;
	background-size: 110%;
	opacity: 0.85;
}

.spotlight-image-link._7 {
	background-image: url('../images/home_content_07_500x500.jpg');
	background-position: 50% 50%;
	background-size: 100%;
	background-repeat: no-repeat;
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
}

.spotlight-image-link._7:hover {
	background-image: url('../images/home_content_03_500x500.jpg');
	background-position: 50% 50%;
	background-size: 110%;
	opacity: 0.85;
}

.spotlight-image-link._6 {
	background-image: url('../images/home_content_06_500x500.jpg');
	background-position: 50% 50%;
	background-size: 100%;
	background-repeat: no-repeat;
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
}

.spotlight-image-link._6:hover {
	background-size: 110%;
	opacity: 0.85;
}

.spotlight-image-link._1 {
	background-position: 50% 50%;
	background-size: 100%;
	background-repeat: no-repeat;
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
}

.spotlight-image-link._1:hover {
	background-size: 110%;
	opacity: 0.85;
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
}

.spotlight-image-link._4 {
	background-image: url('../images/home_content_04_500x500.jpg');
	background-position: 50% 50%;
	background-size: 100%;
	background-repeat: no-repeat;
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
}

.spotlight-image-link._4:hover {
	background-size: 110%;
	opacity: 0.85;
}

.property-spotlight-slide-2 {
	width: 24%;
	height: auto;
	margin-right: 0.5%;
	margin-left: 0.5%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	background-color: transparent;
	border: 1px solid #dadada;
	margin-bottom: 10px;
}

#products_module.responsive .item-wrapper .property-spotlight-slide-2 {
	margin-bottom: 10px;
}

.listing-details-2 {
	width: 100%;
	color: #606060;
	text-align: center;
}

.multi-slider {
	height: 100%;
	background-color: transparent;
	color: #fff;
}

.property-slider-widget-2 {
	width: 100%;
	height: 100%;
	max-width: 1600px;
	padding-top: 3rem;
	padding-right: 50px;
	padding-left: 50px;
	background-color: transparent;
	color: #fff;
}

.property-slider-widget-2.extra-bottom {
	padding-bottom: 90px;
}

.quad-slide-card-wrapper {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 23%;
	margin-right: 1%;
	margin-left: 1%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	background-color: #fff;
}

.quad-set-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.content-box-heading {
	margin-top: 0px;
	margin-bottom: 1rem;
	padding: 0.75rem 1rem;
	border-bottom: 3px none #e2e2e2;
	border-left: 3px solid #c5c5c5;
	color: #288ab1;
	font-size: 2rem;
	font-weight: 700;
}

.property-spotlight-image-link-2 {
	width: 35%;
	height: 275px;
	margin-bottom: 0rem;
	margin-left: 1px;
	border-bottom: 1px solid #dadada;
	background-image: url('../images/product-1_700x428_1.png');
	background-position: 50% 50%;
	background-size: 275px;
	background-repeat: no-repeat;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
}

.property-spotlight-image-link-2:hover {
	opacity: 0.85;
}

.property-spotlight-image-link-2._4 {
	border-bottom-style: solid;
	background-image: url('../images/product-4_700x448.png');
	background-position: 50% 50%;
	background-size: 275px;
	background-repeat: no-repeat;
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
}

.property-spotlight-image-link-2._4:hover {
	opacity: 0.85;
}

.property-spotlight-image-link-2._2 {
	height: 275px;
	margin-bottom: 0rem;
	border-right-style: solid;
	border-bottom-style: solid;
	background-image: url('../images/product-2_700x448.png');
	background-position: 50% 50%;
	background-size: 275px;
	background-repeat: no-repeat;
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
}

.property-spotlight-image-link-2._2:hover {
	opacity: 0.85;
}

.property-spotlight-image-link-2._3 {
	margin-bottom: 0rem;
	border-bottom-style: solid;
	background-image: url('../images/product-3_700x448.png');
	background-position: 50% 50%;
	background-size: 275px;
	background-repeat: no-repeat;
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
}

.property-spotlight-image-link-2._3:hover {
	border-bottom-color: #dadada;
	opacity: 0.85;
}

.property-duo-mask {
	width: 100%;
	margin-right: 70px;
	margin-left: 70px;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
}

.property-spotlight-quad-slide {
	width: 100%;
	margin-right: 0%;
	margin-left: 0%;
	padding-top: 0.75rem;
	padding-right: 1rem;
	padding-left: 1rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	background-color: transparent;
	background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(55, 28%, 92%, 0.7)), to(hsla(55, 28%, 92%, 0.7)));
	background-image: linear-gradient(180deg, hsla(55, 28%, 92%, 0.7), hsla(55, 28%, 92%, 0.7));
}

.duo-slider-arrow-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 62px;
	height: 62px;
	margin: auto 20px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 50px;
	background-color: rgba(96, 96, 96, 0.5);
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	font-size: 1.25rem;
}

.duo-slider-arrow-link:hover {
	background-color: #bd571a;
}

.listing-column-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}

.listing-column-wrapper.main {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.property-spotlight-mask-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	max-width: 100%;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
}

.spotlight-list-text-2 {
	margin-bottom: 5px;
	padding-right: 5px;
	padding-left: 0px;
	color: #606060;
	font-size: 1.05rem;
	text-align: left;
}

.arrow {
	right: 2px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 70px;
	height: 70px;
	margin: 40px 20px 22rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border: 6px solid #fff;
	border-radius: 50px;
	background-image: none;
	font-size: 1.25rem;
}

.arrow:hover {
	background-image: -webkit-gradient(linear, left top, left bottom, from(#97e1ff), to(#97e1ff));
	background-image: linear-gradient(180deg, #97e1ff, #97e1ff);
}

.arrow.multi-slider {
	top: 0px;
	right: 5px;
	width: 62px;
	height: 62px;
	margin-top: auto;
	margin-bottom: auto;
	border-style: none;
	background-color: rgba(96, 96, 96, 0.5);
	background-image: none;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	color: #fff;
}

.arrow.multi-slider:hover {
	background-color: #bd571a;
	background-image: none;
}

.arrow.left.multi-slider {
	left: 5px;
	right: 0px;
	height: 62px;
	margin-top: auto;
	margin-bottom: auto;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
}

.arrow.left.multi-slider:hover {
	background-color: #bd571a;
	background-image: none;
	color: #fff;
}

.property-quad-mask {
	width: 100%;
	margin-right: 70px;
	margin-left: 70px;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
}

.quad-slider-arrow-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 62px;
	height: 62px;
	margin: auto 20px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 50px;
	background-color: rgba(96, 96, 96, 0.5);
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	font-size: 1.25rem;
}

.quad-slider-arrow-link:hover {
	background-color: #bd571a;
}

.text-link {
	border-bottom: 1px solid #ffc447;
	color: #666;
	font-weight: 500;
	text-decoration: none;
}

.text-link:hover {
	border-bottom-color: transparent;
	color: #333;
}

.prop-spotlight-details-wrap-stretch {
	width: 100%;
	padding: 1rem 15px;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	background-color: #fff;
}

.paragraph-text {
	width: 50%;
	margin-top: 20px;
	padding-right: 0.75rem;
	float: left;
	color: #4b4a4a;
	font-size: 1.1rem;
	line-height: 1.9rem;
	font-weight: 400;
}

.paragraph-text.about-us {
	display: block;
	width: 100%;
	margin: 0.5rem auto 0rem;
	padding-right: 0rem;
	float: none;
	color: #606060;
	font-size: 1.5rem;
	line-height: 2.25rem;
	font-weight: 300;
	text-align: center;
}

.about-us-div {
	display: block;
	width: 100%;
	padding-top: 2rem;
	padding-bottom: 2rem;
	border-bottom: 2.5rem solid #e1e0d8;
	background-color: hsla(0, 0%, 100%, 0.7);
	background-image: none;
}

.about-us-div._2 {
	padding: 2rem 2rem 2.5rem;
	border-bottom-style: none;
	background-color: transparent;
	background-image: none;
	text-align: left;
}

.about-us-div._1 {
	display: none;
}

.listing-status-text-2 {
	font-weight: 700;
	text-transform: uppercase;
}

.spotlight-container {
	width: 33.33%;
	padding-right: 10px;
	padding-left: 10px;
	float: left;
}

.spotlight-container.fourth {
	width: 50%;
	min-width: 360px;
	padding: 10px 1rem;
}

.spotlight-container.second {
	width: 50%;
	max-width: 100%;
	min-width: 360px;
	padding: 10px 1rem;
}

.spotlight-container.first {
	width: 50%;
	max-width: 100%;
	min-width: 360px;
	padding: 10px 1rem;
	float: left;
}

.spotlight-container.third {
	width: 50%;
	min-width: 360px;
	padding: 10px 1rem;
	color: #606060;
}

.listing-detail-column-wrapper-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	background-color: transparent;
}

.listing-detail-column-wrapper-2.trio {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 0rem;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	grid-auto-columns: 1fr;
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	-ms-grid-columns: 1fr 1fr;
	grid-template-columns: 1fr 1fr;
	-ms-grid-rows: auto auto;
	grid-template-rows: auto auto;
}

.listing-detail-column-wrapper-2.trio-product-details {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 0rem;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	grid-auto-columns: 1fr;
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	-ms-grid-columns: 1fr 1fr;
	grid-template-columns: 1fr 1fr;
	-ms-grid-rows: auto auto;
	grid-template-rows: auto auto;
}

.duo-slide-card-wrapper {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 48%;
	margin-right: 1%;
	margin-left: 1%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	background-color: #fff;
}

.spotlight-list-wrapper-2 {
	width: 100%;
	float: left;
}

.property-slider-outer-wrap-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 3rem;
	padding-bottom: 3rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #fff;
}

.icon-2 {
	color: #fff;
	font-size: 2.25rem;
	font-style: normal;
	font-weight: 600;
}

.property-spotlight-duo-slide {
	width: 50%;
	margin-right: 0%;
	margin-left: 0%;
	padding-top: 0.75rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	background-color: transparent;
	background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(55, 28%, 92%, 0.7)), to(hsla(55, 28%, 92%, 0.7)));
	background-image: linear-gradient(180deg, hsla(55, 28%, 92%, 0.7), hsla(55, 28%, 92%, 0.7));
}

.new-products-card-slider {
	display: block;
	margin-right: auto;
	margin-left: auto;
	background-color: #fff;
}

.slider-link-block {
	display: block;
	width: 100%;
	height: 100%;
	padding-top: 3.5rem;
}

.coming-soon-card-slider {
	display: block;
	margin-right: auto;
	margin-left: auto;
	background-color: #fff;
}

.top-selling-card-slider {
	display: block;
	margin-right: auto;
	margin-left: auto;
	background-color: #fff;
}

.recently-viewed-card-slider {
	display: block;
	margin-right: auto;
	margin-left: auto;
	background-color: #fff;
}

.black-friday-card-slider {
	display: block;
	margin-right: auto;
	margin-left: auto;
	background-color: #fff;
}

.icon-search-2-column-wrap {
	display: none;
	width: 58%;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.icon-title {
	padding-top: 0.25rem;
	padding-right: 0.1rem;
	padding-left: 0.1rem;
	color: #8b8b8b;
	font-size: 0.65rem;
	line-height: 0.75rem;
	letter-spacing: 0rem;
	text-decoration: none;
	text-transform: uppercase;
}

.main-slider-arrow-icon-2 {
	font-size: 1.75rem;
}

.sign-in-title {
	position: relative;
	display: block;
	height: 28px;
	padding-top: 0.25rem;
	padding-right: 0.1rem;
	padding-left: 0.1rem;
	opacity: 0;
	color: #8b8b8b;
	font-size: 0.65rem;
	line-height: 0.75rem;
	letter-spacing: 0rem;
	text-decoration: none;
	text-transform: uppercase;
}

.wishlist-title {
	display: block;
	height: 28px;
	margin-top: 1.255rem;
	padding-top: 0.25rem;
	padding-right: 0.1rem;
	padding-left: 0.1rem;
	opacity: 0;
	color: #8b8b8b;
	font-size: 0.65rem;
	line-height: 0.75rem;
	letter-spacing: 0rem;
	text-decoration: none;
	text-transform: uppercase;
}

.basket-title {
	display: block;
	height: 28px;
	margin-top: 1.255rem;
	padding-top: 0.25rem;
	padding-right: 0.1rem;
	padding-left: 0.1rem;
	opacity: 0;
	color: #8b8b8b;
	font-size: 0.65rem;
	line-height: 0.75rem;
	letter-spacing: 0rem;
	text-decoration: none;
	text-transform: uppercase;
}

.stores-title {
	display: block;
	height: 28px;
	padding-top: 0.25rem;
	padding-right: 0.1rem;
	padding-left: 0.1rem;
	opacity: 0;
	color: #8b8b8b;
	font-size: 0.65rem;
	line-height: 0.75rem;
	letter-spacing: 0rem;
	text-decoration: none;
	text-transform: uppercase;
}

.support-title {
	display: block;
	height: 28px;
	padding-top: 0.25rem;
	padding-right: 0.1rem;
	padding-left: 0.1rem;
	opacity: 0;
	color: #8b8b8b;
	font-size: 0.65rem;
	line-height: 0.75rem;
	letter-spacing: 0rem;
	text-decoration: none;
	text-transform: uppercase;
}

.sign-in-icon {
	max-height: 22px;
	max-width: 22px;
	min-height: 20px;
	min-width: 20px;
	-webkit-transform: translate(0px, 0px);
	-ms-transform: translate(0px, 0px);
	transform: translate(0px, 0px);
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
}

.sign-in-icon:hover {
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.sign-in-icon.account-2 {
	position: relative;
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
}

.basket-icon-new {
	max-height: 22px;
	max-width: 22%;
	min-height: 22px;
	min-width: 22px;
	-webkit-transform: translate(0px, 0px);
	-ms-transform: translate(0px, 0px);
	transform: translate(0px, 0px);
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
}

.basket-icon-new:hover {
	-webkit-transform: translate(0px, -5px);
	-ms-transform: translate(0px, -5px);
	transform: translate(0px, -5px);
}

.basket-icon-new.wishlist {
	max-width: 22px;
	min-height: 20px;
	min-width: 20px;
}

.basket-icon-new.wishlist:hover {
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.basket-icon-new.basket {
	max-width: 22px;
	min-height: 20px;
	min-width: 20px;
}

.basket-icon-new.basket:hover {
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.basket-icon {
	max-height: 22px;
	max-width: 22px;
	min-height: 20px;
	min-width: 20px;
	margin-bottom: 0.5rem;
	-webkit-transform: translate(0px, 0px);
	-ms-transform: translate(0px, 0px);
	transform: translate(0px, 0px);
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
}

.basket-icon:hover {
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.stores-icon {
	max-height: 22px;
	max-width: 22px;
	min-height: 20px;
	min-width: 20px;
	-webkit-transform: translate(0px, 0px);
	-ms-transform: translate(0px, 0px);
	transform: translate(0px, 0px);
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
}

.stores-icon:hover {
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.support-icon {
	max-height: 22px;
	max-width: 22px;
	min-height: 20px;
	min-width: 20px;
	-webkit-transform: translate(0px, 0px);
	-ms-transform: translate(0px, 0px);
	transform: translate(0px, 0px);
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
}

.support-icon:hover {
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.div-block-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 70%;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.top-icon-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 0.5rem;
	margin-left: 0.5rem;
	padding-right: 0.15rem;
	padding-left: 0.15rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.top-icon-wrapper.basket-2 {
	display: block;
}

.top-icon-wrapper.support-hidden {
	display: none;
}

.top-icon-wrapper.wishlist-2 {
	display: block;
}

.top-icon-wrapper.cart-mobile {
	display: block;
}

.top-icon-wrapper.basket-2 {
	display: block;
}

.basket-div {
	display: block;
	padding-top: 1.2rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.web-development-by {
	position: absolute;
	left: auto;
	top: 0%;
	right: 0%;
	bottom: auto;
	font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
	color: #fff;
	font-size: 0.65rem;
	line-height: 0.75rem;
	letter-spacing: 0.045rem;
}

.main-slider-arrow-icon {
	font-size: 1.75rem;
}

.icon-3 {
	display: none;
}

.text-block-2 {
	display: none;
}

.account-dropdown {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 55px;
	padding: 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.account-dropdown-list {
	display: none;
	margin-top: -0.5rem;
}

.account-dropdown-list.w--open {
	margin-top: 0rem;
	padding-top: 0.45rem;
	background-color: transparent;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
}

.account-link {
	padding: 0.25rem 0.75rem;
	border-style: solid;
	border-width: 1px;
	border-color: hsla(0, 0%, 75%, 0.5);
	background-color: #f1f1f1;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	font-size: 0.8rem;
	line-height: 1.05rem;
}

.account-link:hover {
	background-color: #fff;
}

.account-link.top {
	padding: 0.25rem 0.75rem;
	border-bottom-style: none;
	line-height: 1.15rem;
}

.dropdown {
	padding-top: 5px;
	padding-bottom: 5px;
}

.link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 0.5rem;
	padding: 0.5rem 0.325rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	background-color: #ffde00;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #000;
	font-size: 0.75rem;
	line-height: 0.85rem;
	text-align: center;
	text-decoration: underline;
}

.link:hover {
	border-bottom-color: #fff;
	background-color: transparent;
	text-decoration: none;
}

.sale-banner-div {
	position: absolute;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: auto;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 50%;
	max-height: 90px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border: 1px none #dadada;
	background-color: transparent;
	background-image: url('../images/sale-banner-25.svg');
	background-position: 0px 0px;
	background-size: 200px;
	background-repeat: no-repeat;
}

.sale-banner-text {
	border-bottom: 2px solid #e4c700;
	color: #fff;
	font-size: 1.25rem;
	font-weight: 700;
}

.arrow-link {
	display: block;
	width: 18px;
	height: 18px;
	min-width: 18px;
	margin-right: 0.5rem;
	padding: 0.5rem 0.325rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	background-color: transparent;
	background-image: url('../images/arrow-grey-icons-06.svg');
	background-position: 50% 50%;
	background-size: 12px 12px;
	background-repeat: no-repeat;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #000;
	font-size: 0.75rem;
	line-height: 0.85rem;
	text-align: center;
	text-decoration: underline;
}

.arrow-link:hover {
	border-bottom-color: #fff;
	background-color: #fff;
	text-decoration: none;
}

.wishlist-save-icon {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 42px;
	height: 45px;
	max-height: 45px;
	max-width: 42px;
	min-height: 42px;
	min-width: 42px;
	margin-right: 0rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #ddd;
	background-image: url('../images/homepage-icons_heart_white-37.svg');
	background-position: 50% 50%;
	background-size: 22px 22px;
	background-repeat: no-repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

.wishlist-save-icon:hover {
	background-image: url('../images/homepage-icons_heart_yellow-46.svg');
}

.present-icon-2 {
	min-height: 35px;
	min-width: 35px;
	margin-bottom: 4px;
	background-color: transparent;
	background-image: url('../images/homepage-icons-present-red-NEW-29.svg');
	background-position: 50% 50%;
	background-size: 24px 24px;
	background-repeat: no-repeat;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
}

.present-icon-2:hover {
	background-color: transparent;
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.present-icon-2.product-comparison {
	background-position: 0 50%;
}

.product-comparison-product .product-code {
	margin-bottom: 10px;
}

.property-slider-arrow-link-3 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50px;
	height: 50px;
	margin: auto 0px auto 4px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 50px;
	background-color: rgba(97, 97, 97, 0);
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	color: #666;
	font-size: 1.25rem;
}

.property-slider-arrow-link-3:hover {
	background-color: #ffcb05;
}

.property-slider-arrow-link-3.property-arrow-right {
	margin-right: 4px;
}

.sale-banner-text-2 {
	border-bottom: 2px solid #e4c700;
	color: #fff;
	font-size: 1.25rem;
	font-weight: 700;
}

.cart-icon-link-wrapper-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 60%;
	height: 42px;
	margin-bottom: 15px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	background-color: #fff;
}

.cart-icon-link-wrapper-2.grey {
	background-color: #fff;
}

.add-to-cart-icon-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 42px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: flex-end;
	-ms-flex-item-align: end;
	align-self: flex-end;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	background-color: #ffcb05;
	background-image: url('../images/homepage-icons_triangle-LT-GREY-36.svg'), url('../images/cart-icons-15.svg');
	background-position: 0% 50%, 50% 50%;
	background-size: contain, 25px 25px;
	background-repeat: no-repeat, no-repeat;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
}

.add-to-cart-icon-2:hover {
	width: 100%;
	background-color: #ddd;
}

.add-to-cart-icon-2.out-of-stock {
	background-color: #dedede;
	background-image: none;
	background-position: 0px 0px;
	background-size: auto;
	background-repeat: repeat;
	opacity: 1;
}

.add-to-cart-icon-2.with-text {
	height: 45px;
	background-image: url('../images/homepage-icons_triangle-LT-GREY-36.svg'), url('../images/cart-icons-15.svg');
	background-position: 0% 50%, 84% 50%;
	background-size: contain, 25px 25px;
	background-repeat: no-repeat, no-repeat;
	text-decoration: none;
}

.add-to-cart-icon-2.with-text:hover {
	background-color: hsla(47.51999999999999, 100%, 44.33%, 1);
	box-shadow: none;
}

.wishlist-save-image {
	width: 100%;
	padding: 0.65rem;
}

.banner-arrow-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	height: 45px;
	min-width: 155px;
	margin-top: 3px;
	padding-left: 1.25rem;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border: 2px none #fff;
	background-color: #e4c700;
	background-image: url('../images/arrow-medium_00283b.svg');
	background-position: 91% 50%;
	background-size: auto 13px;
	background-repeat: no-repeat;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	text-decoration: none;
}

.banner-arrow-2:hover {
	background-color: #fff;
}

.top-nav-div {
	position: relative;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: auto;
	z-index: 1000;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 32px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: #f1f1f1;
}

.top-nav-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	max-width: 1600px;
	padding-right: 0.5rem;
	padding-left: 1rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.top-nav-link {
	display: block;
	margin-bottom: 1px;
	padding: 0.5rem 0.75rem 0.5rem 2rem;
	background-image: url('../images/stores-dk.svg');
	background-position: 0% 50%;
	background-size: 18px 18px;
	background-repeat: no-repeat;
	color: #333;
	font-size: 0.75rem;
	line-height: 0.75rem;
	font-weight: 700;
	letter-spacing: 0.01px;
	text-decoration: none;
	text-transform: uppercase;
}

.top-nav-link.stores {
	padding-left: 1.65rem;
	background-image: url('../images/homepage-icons-store.svg');
	font-size: 0.7rem;
}

.top-nav-link.support {
	padding-left: 1.65rem;
	background-image: url('../images/homepage-icons_gear-41.svg');
	font-size: 0.7rem;
}

.top-nav-link.phone {
	padding-left: 1.65rem;
	background-image: url('../images/homepage-icons-phone.svg');
	font-size: 0.7rem;
}

.top-nav-link.free-pickup-and-delivery {
	padding-left: 1.65rem;
	background-image: url('../images/homepage-icons-truckgrey-31.svg');
	font-size: 0.7rem;
}

.top-nav-link.credit-card {
	padding-left: 1.65rem;
	background-image: url('../images/homepage-icons-creditcard-grey-32.svg');
	font-size: 0.7rem;
}

.top-nav-link.register {
	padding-right: 0.35rem;
	padding-left: 0rem;
	background-image: none;
	font-size: 0.7rem;
}

.top-nav-link.log-in-option {
	padding-left: 0rem;
	background-image: none;
}

.top-nav-link.log-in-option {
	padding-left: 0.35rem;
	background-image: none;
	font-size: 0.7rem;
}

.top-nav-link.language {
	display: none;
	padding-right: 0.5rem;
	padding-left: 0.5rem;
	background-image: none;
	font-size: 0.7rem;
}

.basket-amounts-div {
	position: relative;
	left: -1.25rem;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 0.35rem 0.25rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	color: #333;
	font-size: 0.8rem;
	line-height: 0.8rem;
	font-weight: 700;
}

.wishlist-dropdown {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 0px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.call-out-icon-text-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.icon-title-new {
	color: #333;
	font-size: 0.75rem;
	font-weight: 500;
	text-align: center;
	letter-spacing: 0.01rem;
	text-transform: uppercase;
}

.icon-title-new.account {
	margin-top: 0.6rem;
}

.mega-div-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	max-height: 550px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	align-content: baseline;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	padding-bottom: 5px;
	padding-top: 18px;
	padding-left: 20px;
	padding-right: 20px;
}

.dropdown-link-2 {
	display: block;
	width: 100%;
	padding: 0.5rem 0.5rem 0.5rem 0.75rem;
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #666;
	font-size: 0.8rem;
	line-height: 1.3rem;
	font-weight: 400;
	text-decoration: none;
	text-transform: none;
}

.dropdown-link-2:hover {
	background-color: hsla(0, 0%, 100%, 0.7);
	color: #000;
}

.dropdown-link-2.w--current {
	background-color: hsla(0, 0%, 100%, 0.7);
	box-shadow: inset 4px 0 0 0 #e77c25;
	color: #1f304f;
}

.in-stock-product-code-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 93%;
	margin-top: 0.5rem;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	align-self: center;
	border-top: 1px solid #dadada;
	border-bottom: 1px solid #dadada;
}

.product-code {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding-top: 0rem;
	padding-bottom: 0rem;
	padding-left: 0.5rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	color: rgba(96, 96, 96, 0.5);
	font-size: 0.75rem;
}

.listing-details-heading-3 {
	color: #e24603;
	font-size: 0.75rem;
	font-weight: 700;
	letter-spacing: -0.01rem;
}

.listing-details-heading-3.original-price {
	color: rgba(96, 96, 96, 0.5);
	font-size: 0.75rem;
	text-decoration: line-through;
}

.listing-details-heading-3.now-price {
	color: rgba(96, 96, 96, 0.5);
	font-size: 0.75rem;
	text-decoration: none;
}

.new-present-div {
	position: absolute;
	top: 230px;
	width: 35%;
}

.bold-text-2 {
	font-size: 1rem;
}

.register-now-link {
	position: relative;
	margin-top: 0.25rem;
	padding: 0.25rem 0.75rem;
	border-style: solid;
	border-width: 1px;
	border-color: hsla(0, 0%, 75%, 0.5);
	background-color: #f1f1f1;
	opacity: 0;
	color: #333;
	font-size: 0.8rem;
	line-height: 1.15rem;
	text-decoration: none;
}

.register-now-link:hover {
	background-color: #fff;
}

.hidden-register-button-div {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.login-and-register-div {
	display: none;
	padding-left: 1.65rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border: 1px none #000;
	background-image: url('../images/homepage-icons-register-33.svg');
	background-position: 0% 42%;
	background-size: 16px 16px;
	background-repeat: no-repeat;
}

.language-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.product-title {
	font-weight: 500;
}

.present-icon-wrapper-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	min-height: 55px;
	min-width: 50px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: rgba(228, 199, 0, 0.9);
}

.present-icon-3 {
	min-height: 35px;
	min-width: 35px;
	background-color: #ffde00;
	background-image: url('../images/present-icon_black.svg');
	background-position: 50% 50%;
	background-size: 24px 24px;
	background-repeat: no-repeat;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
}

.present-icon-3:hover {
	background-color: transparent;
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.present-text-wrapper-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	min-height: 55px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: rgba(228, 199, 0, 0.9);
	opacity: 1;
}

.present-text-2 {
	padding-right: 0.5rem;
	color: #000;
	font-size: 0.75rem;
	line-height: 0.95rem;
	font-weight: 500;
}

.link-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 0.5rem;
	padding: 0.5rem 0.325rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	background-color: #ffde00;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #000;
	font-size: 0.75rem;
	line-height: 0.85rem;
	text-align: center;
	text-decoration: underline;
}

.link-2:hover {
	border-bottom-color: #fff;
	background-color: transparent;
	text-decoration: none;
}

.stephanis-card-dropdown {
	padding: 0px;
}

.stephanis-drop {
	line-height: 0.8rem;
}

.stephanis-card-drop-icon {
	display: none;
}

.register-drop-down-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 0.15rem 0.35rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border: 1px solid #dadada;
	background-color: #fff;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-size: 0.65rem;
	line-height: 1.1rem;
	text-transform: uppercase;
}

.register-drop-down-link:hover {
	background-color: #f1f1f1;
}

.add-to-cart-text {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	max-width: 92px;
	padding-right: 0.5rem;
	padding-left: 0.5rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #333;
	font-size: 0.75rem;
	line-height: 1rem;
	font-weight: 700;
	text-decoration: none;
}

.add-to-cart-text:hover {
	background-image: none;
}

.add-to-cart-text.add-to-cart {
	margin-right: 2.75rem;
	color: #333;
	font-weight: 700;
	text-align: center;
	text-transform: uppercase;
}

.add-to-cart-text.pre-order {
	max-width: 101px;
	color: #333;
	text-align: center;
	text-transform: uppercase;
}

.add-to-cart-text.pre-order-date {
	max-width: 101px;
	color: #333;
	font-weight: 400;
	text-align: center;
	text-transform: none;
}

.dropdown-2 {
	max-height: 32px;
}

.dropdown-toggle {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-height: 32px;
	padding: 0px 2rem 0px 0px;
}

.language-text {
	display: none;
	font-size: 0.7rem;
	line-height: 0.75rem;
	font-weight: 700;
	text-transform: uppercase;
}

.language-arrow-icon {
	margin-right: 0.75rem;
}

.language-dropdown-link {
	padding: 0.25rem 0.75rem 0.25rem 2.5rem;
	border: 1px solid #f1f1f1;
	background-color: #f1f1f1;
	background-image: url('../images/flags-homepage-icons-44.svg');
	background-position: 8px 50%;
	background-size: 24px 24px;
	background-repeat: no-repeat;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	font-size: 0.7rem;
	font-weight: 500;
}

.language-dropdown-link:hover {
	background-color: #fff;
}

.language-dropdown-link._1 {
	background-image: url('../images/flags-homepage-icons-43.svg');
}

.language-dropdown-link._2 {
	background-image: url('../images/flags-homepage-icons-45.svg');
}

.language-dropdown-list {
	z-index: 1000;
}

.language-dropdown-list.w--open {
	right: 0rem;
	padding-top: 0.6rem;
	background-color: transparent;
}

.mobile-icon-wrap {
	display: none;
}

.language-div-mobile {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.flag-icon {
	width: 24px;
	margin-right: 0.2rem;
}

.out-of-stock-text {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	padding-right: 1.5rem;
	padding-left: 0rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #b5b5b5;
	font-size: 0.75rem;
	line-height: 1rem;
	font-weight: 700;
	text-decoration: none;
	text-transform: uppercase;
}

.out-of-stock-text.add-to-cart {
	padding-left: 2.5rem;
	background-position: 10px 50%;
	color: #333;
	font-weight: 700;
}

.pre-order-text {
	width: 100%;
	min-width: 65px;
}

.pre-order-text-div {
	padding-right: 2.4rem;
}

.left-nav-list-content-wrap {
	display: block;
	overflow: hidden;
	height: auto;
	margin-top: -1.5rem;
	margin-right: 0.5em;
	padding-bottom: 1.5em;
	border-right: 1px solid rgba(0, 0, 0, 0.15);
}

.section-wrap.inside-content-section {
	padding-top: 6rem;
	background-color: #f1f1f1;
}

.section-wrap.inside-content-section {
	padding-top: 6rem;
	background-color: #f1f1f1;
}

.section-wrap.inside-content-section.level-1 {
	padding-top: 6rem;
}

.left-nav-list-link {
	display: block;
	padding: 0.75em 1.5rem 0.75em 0.9rem;
	border-bottom: 1px solid rgba(0, 99, 183, 0.08);
	background-image: url('../images/nav-arrow_white-01.svg');
	background-position: 0px 50%;
	background-size: 11px 11px;
	background-repeat: no-repeat;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #333;
	font-weight: 400;
	text-decoration: none;
}

.left-nav-list-link:hover {
	background-image: url('../images/nav-arrow_blue-01.svg');
	background-position: 0px 50%;
	background-size: 11px 11px;
	background-repeat: no-repeat;
	color: #666;
}

.left-nav-list-link.w--current {
	background-image: url('../images/nav-arrow_blue-01.svg');
	background-size: 11px 11px;
	color: #0054a6;
}

.left-nav-list-link.section-title {
	border-bottom-style: none;
	background-color: #ffcb05;
	background-image: none;
	color: #333;
	font-size: 1rem;
	line-height: 1em;
	text-decoration: none;
	text-transform: uppercase;
}

.main-content-column.inside-right {
	padding-right: 0px;
	padding-left: 0px;
}

.inside-content-beckground {
	display: block;
	max-width: 1600px;
	margin-top: 3rem;
	margin-right: auto;
	margin-left: auto;
	padding-top: 3rem;
	padding-bottom: 4rem;
	border-top: 4px solid hsla(194.1322314049587, 0%, 72%, 0.5);
	background-color: #fff;
}

.inside-content-beckground.level-1 {
	padding-top: 1rem;
	border-right: 1px solid #d9d9d9;
}

.left-nav-nested-list-item {
	margin-bottom: 1px;
}

.div-block-3 {
	display: none;
}

.image-2 {
	max-height: 250px;
	min-width: 50%;
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.breadcrumb-current-page {
	line-height: 0.6875rem;
}

.mobile-menu-styles-block {
	position: relative;
	overflow: hidden;
	background-color: #28313b;
}

.left-nav-list-item {
	margin-bottom: 1px;
}

.breadcrumb-list {
	margin: 0em 0.125rem 1em;
	padding-left: 0px;
	font-size: 0.6875rem;
	line-height: 1em;
	text-transform: uppercase;
}

.main-content-wrapper {
	display: block;
	width: 100%;
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
}

.main-content-wrapper.inside-left {
	max-width: 1550px;
	padding-right: 2rem;
	padding-left: 2rem;
}

.ordered-list.roman-order {
	list-style-type: lower-roman;
}

.ordered-list.alpha-order {
	list-style-type: lower-alpha;
}

.block-quote {
	border-left-color: #ffcb05;
	background-color: #f8f8f8;
	color: #333;
	font-size: 1.15rem;
	line-height: 1.75rem;
	font-weight: 400;
}

.left-nav-nested-list {
	margin-top: 0em;
	margin-bottom: 0em;
	padding-top: 0em;
	padding-bottom: 0em;
	border-top: 1px none rgba(0, 0, 0, 0.15);
}

.left-nav-nested-list-link {
	display: block;
	padding: 0.75em 1.5rem 0.75em 1.75rem;
	border-bottom: 1px solid rgba(0, 99, 183, 0.08);
	background-image: url('../images/nav-arrow_white-01.svg');
	background-position: 12px 50%;
	background-size: 11px 11px;
	background-repeat: no-repeat;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #1b2f33;
	font-size: 0.8125em;
	line-height: 1.375em;
	font-weight: 400;
	text-decoration: none;
}

.left-nav-nested-list-link:hover {
	background-image: url('../images/nav-arrow_blue-01.svg');
	background-position: 12px 50%;
	background-size: 11px 11px;
	color: #666;
}

.left-nav-nested-list-link.w--current {
	background-color: #fff;
	background-image: url('../images/nav-arrow_blue-01.svg');
	background-size: 11px 11px;
	color: #0054a6;
}

.left-nav-nested-list-link.great-grandchild {
	padding-left: 2.5rem;
	background-position: 24px 50%;
}

.left-nav-nested-list-link.great-grandchild:hover {
	color: #666;
}

.left-nav-list {
	margin-top: 0em;
	margin-bottom: 1.5em;
	padding-left: 0px;
}

.breadcrumb-list-item {
	margin-top: 0.25rem;
	margin-right: 0.5rem;
	margin-bottom: 0.25rem;
	float: left;
}

.intro-text-style {
	font-size: 1.1875em;
	line-height: 1.5em;
}

.nav-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 44px;
	max-width: 100px;
	margin-right: 0px;
	padding: 0.8rem 3.5rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 1;
	-webkit-flex: 1 0 auto;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	background-color: transparent;
	color: #525f94;
	font-size: 0.85rem;
	line-height: 1.375em;
	font-weight: 600;
	text-align: center;
}

.nav-link:hover {
	background-color: rgba(32, 108, 119, 0.25);
}

.nav-link.w--current {
	padding: 0.75rem 1.15rem 0.6rem;
	border-bottom: 2px solid #3aa1af;
	background-color: #f0f5f6;
	box-shadow: inset 1px 0 0 0 #fff, inset -1px 0 0 0 #fff;
	color: #00214b;
	font-size: 0.85rem;
	font-weight: 600;
}

.nav-link.w--current:hover {
	border-bottom-color: #3aa1af;
	background-color: #ebf2f3;
	box-shadow: none;
}

.breadcrumb-divider {
	margin-left: 0.5rem;
	float: right;
}

.two-part-button-wrap {
	position: relative;
	display: block;
	max-width: 768px;
	margin-right: auto;
	margin-left: auto;
}

.button {
	display: inline-block;
	margin-top: 0.5rem;
	margin-right: 0.5rem;
	margin-bottom: 0.5rem;
	padding: 0.5rem 1.5rem;
	border: 1px solid #ffcb05;
	background-color: #ffcb05;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	color: #333;
	font-size: 0.85rem;
	font-weight: 600;
	text-align: center;
	letter-spacing: 0.05rem;
	text-decoration: none;
	text-transform: uppercase;
}

.button:hover {
	border-color: #666;
	background-color: #666;
	color: #fff;
}

.button.tertiary {
	padding-right: 1rem;
	padding-left: 1rem;
	border-width: 2px;
	border-color: #ffcb05;
	background-color: #fff;
	color: #333;
	font-size: 0.85rem;
	line-height: 1.5em;
}

.button.tertiary:hover {
	border-color: hsla(194.1322314049587, 0%, 72%, 0.5);
	background-color: #fff;
	color: #666;
}

.button.secondary {
	border-color: #b5b5b5;
	background-color: #b5b5b5;
}

.button.secondary:hover {
	border-color: #ffcb05;
	background-color: #ffcb05;
	color: #333;
}

.inside-page-header-content-wrap {
	margin-bottom: 1.5rem;
	padding-bottom: 0.5em;
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.link-3 {
	border-bottom: 1px solid rgba(0, 185, 242, 0.25);
	color: #0054a6;
	font-size: 1rem;
	text-decoration: none;
}

.link-3:hover {
	border-bottom-style: solid;
	border-bottom-color: transparent;
	color: #00b9f2;
}

.link-3.w--current {
	color: #28313b;
	font-weight: 700;
}

.link-3.h4-link {
	border-bottom-width: 2px;
	border-bottom-color: hsla(194.1322314049587, 0%, 72%, 0.5);
	color: #666;
	font-size: 1.25rem;
	line-height: 1.775rem;
}

.link-3.h4-link:hover {
	color: #333;
}

.link-3.h6-link {
	border-bottom-width: 2px;
	border-bottom-color: hsla(194.1322314049587, 0%, 72%, 0.5);
	color: #666;
	font-size: 0.8125rem;
	line-height: 1.25rem;
}

.link-3.h6-link:hover {
	color: #333;
}

.link-3.h5-link {
	border-bottom-width: 2px;
	border-bottom-color: hsla(194.1322314049587, 0%, 72%, 0.5);
	color: #666;
}

.link-3.h5-link:hover {
	color: #333;
}

.link-3.breadcrumb-link {
	border-bottom-color: hsla(194.1322314049587, 0%, 72%, 0.5);
	color: #8b8b8b;
	font-size: 0.6875rem;
	line-height: 0.6874rem;
}

.link-3.breadcrumb-link:hover {
	border-bottom-color: transparent;
}

.link-3.h3-link {
	border-bottom-width: 2px;
	border-bottom-color: hsla(194.1322314049587, 0%, 72%, 0.5);
	color: #666;
	font-size: 1.5rem;
	line-height: 1.975rem;
}

.link-3.h3-link:hover {
	color: #333;
}

.link-3.h1-link {
	border-bottom-width: 2px;
	border-bottom-color: hsla(194.1322314049587, 0%, 72%, 0.5);
	color: #666;
	font-size: 2.25rem;
	line-height: 2.9rem;
	text-decoration: none;
}

.link-3.h1-link:hover {
	color: #333;
}

.link-3.h2-link {
	border-bottom-width: 2px;
	border-bottom-color: hsla(194.1322314049587, 0%, 72%, 0.5);
	color: #666;
	font-size: 1.875rem;
	line-height: 2.175rem;
	text-decoration: none;
}

.link-3.h2-link:hover {
	color: #333;
}

.left-nav-column-div {
	padding-left: 0px;
}

.heading {
	color: #666;
}

.sidebar-section-title {
	margin-top: 0rem;
	margin-bottom: 0.75rem;
	font-size: 1.25rem;
	line-height: 1.775rem;
	text-transform: none;
}

.main-content-container {
	display: block;
	max-width: 1550px;
	margin-right: auto;
	margin-left: auto;
}

.main-content-container.left-right {
	max-width: 1550px;
	padding-right: 2rem;
	padding-left: 2rem;
}

.left-nav-list-link-2 {
	display: block;
	padding: 0.75em 1.5rem 0.75em 0.9rem;
	border-bottom: 1px solid rgba(0, 99, 183, 0.08);
	background-image: url('../images/nav-arrow_white-01.svg');
	background-position: 0px 50%;
	background-size: 11px 11px;
	background-repeat: no-repeat;
	color: #1b2f33;
	font-weight: 600;
}

.left-nav-list-link-2:hover {
	background-image: url('../images/nav-arrow_blue-01.svg');
	background-position: 0px 50%;
	background-size: 11px 11px;
	background-repeat: no-repeat;
	color: #00b9f2;
}

.left-nav-list-link-2.w--current {
	background-image: url('../images/nav-arrow_blue-01.svg');
	background-size: 11px 11px;
	color: #0054a6;
}

.left-nav-list-link-2.section-title {
	border-bottom-style: none;
	background-color: #0063b7;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
	color: #fff;
	font-size: 1.25rem;
	line-height: 1em;
	text-transform: uppercase;
}

.sidebar-list-item {
	margin-bottom: 0.75em;
	padding-left: 0px;
}

.inside-content-beckground-2 {
	display: block;
	max-width: 1300px;
	margin-right: auto;
	margin-left: auto;
	padding-top: 3rem;
	padding-bottom: 4rem;
	border-top: 4px solid #00b9f2;
	background-color: #fff;
}

.block-quote-2 {
	border-left-color: #00b9f2;
	background-color: #f8f8f8;
	color: #0054a6;
	font-size: 1.15rem;
	line-height: 1.85rem;
	font-weight: 600;
}

.left-nav-nested-list-link-2 {
	display: block;
	padding: 0.75em 1.5rem 0.75em 1.75rem;
	border-bottom: 1px solid rgba(0, 99, 183, 0.08);
	background-image: url('../images/nav-arrow_white-01.svg');
	background-position: 12px 50%;
	background-size: 11px 11px;
	background-repeat: no-repeat;
	color: #1b2f33;
	font-size: 0.8125em;
	line-height: 1.375em;
	font-weight: 600;
}

.left-nav-nested-list-link-2:hover {
	background-image: url('../images/nav-arrow_blue-01.svg');
	background-position: 12px 50%;
	background-size: 11px 11px;
	color: #00b9f2;
}

.left-nav-nested-list-link-2.w--current {
	background-color: #fff;
	background-image: url('../images/nav-arrow_blue-01.svg');
	background-size: 11px 11px;
	color: #0054a6;
}

.left-nav-nested-list-link-2.great-grandchild {
	padding-left: 2.5rem;
	background-position: 24px 50%;
}

.left-nav-nested-list-link-2.great-grandchild:hover {
	color: #00b9f2;
}

.sidebar-section-wrap {
	position: relative;
	margin-bottom: 2.441em;
	margin-left: 1em;
	border-top: 1px none rgba(0, 0, 0, 0.15);
	font-size: 0.875rem;
	line-height: 1.5em;
}

.sidebar-list {
	padding-left: 0em;
}

.button-2 {
	display: inline-block;
	margin-top: 0.5rem;
	margin-right: 0.5rem;
	margin-bottom: 0.5rem;
	padding: 0.5rem 1.5rem;
	border: 1px solid #00b9f2;
	background-color: #00b9f2;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	color: #fff;
	font-size: 0.85rem;
	font-weight: 600;
	text-align: center;
	letter-spacing: 0.05rem;
	text-decoration: none;
	text-transform: uppercase;
}

.button-2:hover {
	border-color: rgba(0, 84, 166, 0.15);
	background-color: #fff;
	color: #0054a6;
}

.button-2.tertiary {
	padding-right: 1rem;
	padding-left: 1rem;
	border-width: 2px;
	border-color: rgba(0, 84, 166, 0.15);
	background-color: #fff;
	color: #00b9f2;
	font-size: 0.85rem;
	line-height: 1.5em;
}

.button-2.tertiary:hover {
	border-color: transparent;
	background-color: #004180;
	color: #b2edff;
}

.button-2.secondary {
	border-color: #0054a6;
	background-color: #0054a6;
}

.button-2.secondary:hover {
	border-color: rgba(0, 84, 166, 0.15);
	background-color: #fff;
}

.link-4 {
	border-bottom: 1px solid rgba(0, 185, 242, 0.25);
	color: #0054a6;
	font-size: 1rem;
	text-decoration: none;
}

.link-4:hover {
	border-bottom-style: solid;
	border-bottom-color: transparent;
	color: #00b9f2;
}

.link-4.w--current {
	color: #28313b;
	font-weight: 700;
}

.link-4.h4-link {
	border-bottom-width: 2px;
	border-bottom-color: rgba(0, 185, 242, 0.15);
	color: #00b9f2;
	font-size: 1.25rem;
	line-height: 1.775rem;
}

.link-4.h4-link:hover {
	color: #0054a6;
}

.link-4.h6-link {
	border-bottom-width: 2px;
	border-bottom-color: rgba(0, 185, 242, 0.15);
	color: #00b9f2;
	font-size: 0.8125rem;
	line-height: 1.25rem;
}

.link-4.h6-link:hover {
	color: #0054a6;
}

.link-4.h5-link {
	border-bottom-width: 2px;
	border-bottom-color: rgba(0, 185, 242, 0.15);
	color: #00b9f2;
}

.link-4.h5-link:hover {
	color: #0054a6;
}

.link-4.breadcrumb-link {
	color: #799165;
	font-size: 0.6875rem;
	line-height: 0.6874rem;
}

.link-4.h3-link {
	border-bottom-width: 2px;
	border-bottom-color: rgba(0, 185, 242, 0.15);
	color: #00b9f2;
	font-size: 1.5rem;
	line-height: 1.975rem;
}

.link-4.h3-link:hover {
	color: #0054a6;
}

.link-4.h1-link {
	border-bottom-width: 2px;
	border-bottom-color: rgba(0, 185, 242, 0.15);
	color: #00b9f2;
	font-size: 2.25rem;
	line-height: 2.9rem;
}

.link-4.h1-link:hover {
	border-bottom-color: rgba(140, 205, 214, 0.5);
	color: #0054a6;
}

.link-4.h2-link {
	border-bottom-width: 2px;
	border-bottom-color: rgba(0, 185, 242, 0.15);
	color: #00b9f2;
	font-size: 1.875rem;
	line-height: 2.175rem;
}

.link-4.h2-link:hover {
	color: #0054a6;
}

.container-3 {
	display: block;
	max-width: 1100px;
	margin-right: auto;
	margin-left: auto;
}

.container-3.inside-right {
	max-width: 1550px;
	padding-right: 2rem;
	padding-left: 2rem;
}

.link-5 {
	border-bottom: 1px solid rgba(0, 185, 242, 0.25);
	color: #0054a6;
	font-size: 1rem;
}

.link-5:hover {
	border-bottom-style: solid;
	border-bottom-color: transparent;
	color: #00b9f2;
}

.link-5.w--current {
	color: #28313b;
	font-weight: 700;
}

.link-5.h4-link {
	border-bottom-width: 2px;
	border-bottom-color: rgba(0, 185, 242, 0.15);
	color: #00b9f2;
	font-size: 1.25rem;
	line-height: 1.775rem;
}

.link-5.h4-link:hover {
	color: #0054a6;
}

.link-5.h6-link {
	border-bottom-width: 2px;
	border-bottom-color: rgba(0, 185, 242, 0.15);
	color: #00b9f2;
	font-size: 0.8125rem;
	line-height: 1.25rem;
}

.link-5.h6-link:hover {
	color: #0054a6;
}

.link-5.h5-link {
	border-bottom-width: 2px;
	border-bottom-color: rgba(0, 185, 242, 0.15);
	color: #00b9f2;
}

.link-5.h5-link:hover {
	color: #0054a6;
}

.link-5.breadcrumb-link {
	color: #799165;
	font-size: 0.6875rem;
	line-height: 0.6874rem;
}

.link-5.h3-link {
	border-bottom-width: 2px;
	border-bottom-color: rgba(0, 185, 242, 0.15);
	color: #00b9f2;
	font-size: 1.5rem;
	line-height: 1.975rem;
}

.link-5.h3-link:hover {
	color: #0054a6;
}

.link-5.h1-link {
	border-bottom-width: 2px;
	border-bottom-color: rgba(0, 185, 242, 0.15);
	color: #00b9f2;
	font-size: 2.25rem;
	line-height: 2.9rem;
}

.link-5.h1-link:hover {
	border-bottom-color: rgba(140, 205, 214, 0.5);
	color: #0054a6;
}

.link-5.h2-link {
	border-bottom-width: 2px;
	border-bottom-color: rgba(0, 185, 242, 0.15);
	color: #00b9f2;
	font-size: 1.875rem;
	line-height: 2.175rem;
}

.link-5.h2-link:hover {
	color: #0054a6;
}

.inside-page-content-wrap-no-sidebars {
	margin-right: 3%;
	margin-left: 3%;
}

.form-wrap {
	margin-bottom: 0px;
}

.form-field {
	position: relative;
	width: auto;
	margin-bottom: 0.75rem;
	padding: 4px 10px;
	font-size: 0.875rem;
}

.form-success {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	background-color: #079429;
	color: #fff;
}

.form-error {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	background-color: #c23737;
	color: #fff;
}

.level-1-title-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-bottom: 0rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.category-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 1rem;
	padding-bottom: 3.5rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	border-bottom: 1px solid hsla(194.1322314049587, 0%, 72%, 0.5);
}

.tag-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 0.6rem;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	border-right: 1px solid hsla(194.1322314049587, 0%, 72%, 0.5);
	border-bottom: 1px solid hsla(194.1322314049587, 0%, 72%, 0.5);
	border-left: 1px solid hsla(194.1322314049587, 0%, 72%, 0.5);
}

.category-div-wrapper {
	width: 23%;
	margin-right: 1%;
	margin-bottom: 1rem;
	margin-left: 1%;
}

.tag-link {
	padding-right: 0.5rem;
	padding-bottom: 0.25rem;
	color: #333;
	font-size: 0.85rem;
	line-height: 1rem;
}

.tag-link:hover {
	color: #666;
	text-decoration: none;
}

.search-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.area-min-input {
	width: 45%;
	height: 100%;
	min-width: 95px;
	margin-bottom: 0px;
	padding-top: 6px;
	padding-bottom: 6px;
	border-radius: 0.5rem;
	font-size: 0.825rem;
	line-height: 1.25rem;
}

.property-type-list-div-10 {
	width: 100%;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	background-color: #fff;
	background-image: -webkit-gradient(linear, right top, left top, from(hsla(0, 0%, 85%, 0.08)), to(#fff));
	background-image: linear-gradient(270deg, hsla(0, 0%, 85%, 0.08), #fff);
}

.property-type-list-div-10.area {
	padding: 0.75rem 1rem 0.5rem;
}

.property-type-list-div-10.brand-subtype {
	overflow: auto;
	height: 240px;
	margin-bottom: 0.5rem;
	background-image: none;
}

.property-type-list-div-10.price-range {
	padding: 0.75rem 1rem 0.5rem;
}

.property-type-list-div-10.property-id {
	padding: 0.75rem 1rem;
}

.property-type-list-div-10.technology {
	padding-left: 1rem;
}

.property-type-list-div-10.pool {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.property-type-list-div-10.size {
	padding-left: 1rem;
}

.property-type-list-div-10.color {
	padding-left: 1rem;
}

.property-type-list-div-10.size-subtype {
	overflow: auto;
	height: 240px;
	margin-bottom: 0.5rem;
	background-image: none;
}

.vertical-search-form {
	width: 25%;
	margin-bottom: 0px;
}

.area-maximum-input {
	width: 45%;
	height: 100%;
	min-width: 95px;
	margin-bottom: 0px;
	padding-top: 6px;
	padding-bottom: 6px;
	border-radius: 0.5rem;
}

.money-slider-symbol {
	display: none;
	font-size: 0.6rem;
}

.vertical-search-checkbox {
	margin-top: 0px;
	margin-left: 0px;
	background-color: #fff;
}

.clear-all-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding: 0.5rem 1rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border: 2px solid #00a583;
	background-color: transparent;
	color: #009770;
	font-weight: 600;
}

.clear-all-link:hover {
	border-color: #666;
	background-color: #666;
	color: #fff;
}

.single-field {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 0px;
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	padding-left: 1rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.single-field.third {
	padding-top: 0.1rem;
	padding-bottom: 0.1rem;
	font-size: 0.825rem;
	line-height: 1.25rem;
}

.single-field.property {
	padding-top: 0.1rem;
	padding-bottom: 0.1rem;
	font-size: 0.825rem;
	line-height: 1.25rem;
}

.single-field.name {
	padding-top: 0.15rem;
	padding-bottom: 0.15rem;
	font-size: 0.825rem;
	line-height: 1.25rem;
}

.single-field.half {
	padding-top: 0.1rem;
	padding-bottom: 0.1rem;
	padding-left: 0rem;
	font-size: 0.825rem;
	line-height: 1.25rem;
}

.vertical-search-form-div {
	width: 100%;
}

.dash-text-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 32px;
	padding-right: 0.25rem;
	padding-left: 0.25rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	font-size: 1.5rem;
}

.div-line-sections {
	width: 20%;
	height: 10px;
	border-right: 1px solid rgba(96, 96, 96, 0.45);
	border-left: 1px solid rgba(96, 96, 96, 0.45);
}

.div-line-sections.last {
	border-right-style: none;
}

.div-line-sections.first {
	border-left-style: none;
}

.div-line-sections.first.colored {
	height: 10px;
	background-color: #e2d287;
}

.div-line-sections.first.colored.inside {
	background-color: rgba(0, 168, 228, 0.5);
}

.div-line-sections.colored {
	height: 10px;
	background-color: #e2d287;
}

.div-line-sections.colored.inside {
	background-color: rgba(0, 168, 228, 0.5);
}

.single-text-field {
	width: 100%;
	height: 100%;
	margin-bottom: 0px;
	padding-top: 6px;
	padding-bottom: 6px;
	border-radius: 0.5rem;
	font-size: 0.825rem;
	line-height: 1.25rem;
}

.euro-min-and-max-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.euro-min-and-max-div.inside.vertical-search {
	padding-top: 0.25rem;
}

.area-symbol-heading {
	margin-bottom: 0.25rem;
	-webkit-align-self: flex-start;
	-ms-flex-item-align: start;
	align-self: flex-start;
	color: #0c366c;
	font-size: 0.8rem;
	line-height: 1rem;
	font-weight: 400;
}

.euro-symbol-heading {
	margin-bottom: 0.25rem;
	-webkit-align-self: flex-start;
	-ms-flex-item-align: start;
	align-self: flex-start;
	color: #0c366c;
	font-size: 0.8rem;
	line-height: 1rem;
	font-weight: 400;
}

.clear-all-div {
	display: none;
	width: 100%;
	padding-right: 0rem;
	padding-bottom: 0.5rem;
	padding-left: 0rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.radio-search-button {
	margin-top: 0px;
	margin-left: 0px;
}

.min-div {
	width: 16px;
	height: 16px;
	border-radius: 0.25rem;
	background-color: #747474;
}

.min-div._2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 25px;
	height: 25px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	border-radius: 2.25rem;
	background-color: #325b8f;
}

.min-div._2.inside {
	background-color: #0057a5;
}

.line-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding-top: 0.25rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	color: #fff;
	font-size: 0.5rem;
	line-height: 1rem;
	text-align: center;
}

.search-filter-title {
	width: 100%;
	height: 51px;
	padding: 0.35rem 1rem 0.35rem 0rem;
	color: #666;
	font-size: 1.46rem;
	line-height: 2.5rem;
	font-weight: 600;
}

.min-and-max-vertical-search-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-bottom: 0.35rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.price-min-input {
	width: 45%;
	height: 100%;
	min-width: 95px;
	margin-bottom: 0px;
	padding-top: 6px;
	padding-bottom: 6px;
	border-radius: 0.5rem;
	font-size: 0.825rem;
	line-height: 1.25rem;
}

.max-div {
	width: 16px;
	height: 16px;
	border-radius: 0.25rem;
	background-color: #747474;
}

.max-div._2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 25px;
	height: 25px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	border-radius: 2.25rem;
	background-color: #325b8f;
}

.max-div._2.inside {
	background-color: #0057a5;
}

.search-heading-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 0rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.slider-end-line {
	width: 2px;
	height: 45%;
	margin-right: 2px;
	margin-left: 2px;
	background-color: hsla(0, 0%, 100%, 0.7);
}

.single-radio-field-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
	padding-left: 1rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	font-size: 0.825rem;
	line-height: 1.25rem;
}

.search-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding: 0.5rem 1rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border: 2px solid #009770;
	background-color: #009770;
	color: #fff;
	font-weight: 600;
}

.search-link:hover {
	border-color: #666;
	background-color: #666;
	background-image: none;
	color: #fff;
}

.property-type-field-label {
	margin-bottom: 0px;
	padding-right: 0.5rem;
	padding-left: 0.5rem;
}

.radio-field-label {
	margin-bottom: 0px;
	padding-right: 0.5rem;
	padding-left: 0.5rem;
}

.line-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 10px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: rgba(96, 96, 96, 0.2);
}

.label-section-title {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-bottom: 0px;
	padding: 0.45rem 1rem;
	border-left: 4px solid #ffcb05;
	background-color: hsla(0, 0%, 85%, 0.49);
	background-image: url('../images/search-icons-minus-grey.svg');
	background-position: 95% 50%;
	background-size: 15px 15px;
	background-repeat: no-repeat;
	box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.05), inset 0 1px 0 0 rgba(0, 0, 0, 0.05);
	-webkit-transition: all 350ms ease;
	transition: all 350ms ease;
	color: #333;
	font-weight: 400;
	text-decoration: none;
}

.label-section-title:hover {
	display: block;
	background-color: #ffcb05;
	background-image: url('../images/search-icons-minus.svg');
	background-position: 95% 50%;
	background-size: 15px;
	background-repeat: no-repeat;
}

.label-section-title:active {
	background-image: url('../images/search-icons-plus-grey.svg');
	background-position: 95% 50%;
	background-size: 15px 15px;
	background-repeat: no-repeat;
}

.label-section-title:focus {
	background-image: url('../images/search-icons-plus-grey.svg');
	background-position: 95% 50%;
	background-size: 15px 15px;
	background-repeat: no-repeat;
	background-attachment: scroll;
}

.label-section-title:visited {
	background-image: url('../images/search-icons-minus-grey.svg');
	background-position: 95% 50%;
	background-size: 15px 15px;
	background-repeat: no-repeat;
}

.price-max-input {
	width: 45%;
	height: 100%;
	min-width: 95px;
	margin-bottom: 0px;
	padding-top: 6px;
	padding-bottom: 6px;
	border-radius: 0.5rem;
	font-size: 0.825rem;
	line-height: 1.25rem;
}

.search-card-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 75%;
	padding-right: 0rem;
	padding-left: 1rem;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.property-spotlight-wrapper-level-3 {
	position: relative;
	z-index: 1;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 100%;
	margin-right: 0rem;
	margin-bottom: 1rem;
	margin-left: 0rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	border-style: solid none none;
	border-width: 1px;
	border-color: #dadada;
}

.top-filtering-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 51px;
	margin-top: 0rem;
	margin-right: 0rem;
	margin-left: 0.35rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.trio-card-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-bottom: 0rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}

.trio-card-div.ae-listview {
	padding-left: 4px;
}

.filter-options-dropdown {
	z-index: 1;
	height: 51px;
}

.dropdown-toggle-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 51px;
	padding-top: 0px;
	padding-bottom: 0px;
	padding-left: 0px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.search-filter-text {
	position: static;
	z-index: 1;
	color: #333;
}

.search-filter-text:hover {
	color: #666;
}

.side-filter-buttons {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.side-filter-buttons.clearall {
	padding-right: 10px;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.filter-products-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-left: 0rem;
	float: none;
	border-bottom: 1px solid #e4c700;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #333;
	text-decoration: none;
}

.filter-products-link:hover {
	border-bottom-color: #bebebe;
}

.property-type-list-div-brand {
	width: 100%;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	background-color: #fff;
	background-image: -webkit-gradient(linear, right top, left top, from(hsla(0, 0%, 85%, 0.08)), to(#fff));
	background-image: linear-gradient(270deg, hsla(0, 0%, 85%, 0.08), #fff);
}

.property-type-list-div-brand.area {
	padding: 0.75rem 1rem 0.5rem;
}

.property-type-list-div-brand.brand-subtype {
	overflow: auto;
	height: 240px;
	margin-bottom: 0.5rem;
	background-image: none;
}

.property-type-list-div-brand.price-range {
	padding: 0.75rem 1rem 0.5rem;
}

.property-type-list-div-brand.property-id {
	padding: 0.75rem 1rem;
}

.property-type-list-div-brand.technology {
	padding-left: 1rem;
}

.property-type-list-div-brand.pool {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.property-type-list-div-brand.size {
	padding-left: 1rem;
}

.property-type-list-div-brand.color {
	padding-left: 1rem;
}

.property-type-list-div-brand.size-subtype {
	overflow: auto;
	height: 240px;
	margin-bottom: 0.5rem;
	background-image: none;
}

.property-type-list-div-size {
	width: 100%;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	background-color: #fff;
	background-image: -webkit-gradient(linear, right top, left top, from(hsla(0, 0%, 85%, 0.08)), to(#fff));
	background-image: linear-gradient(270deg, hsla(0, 0%, 85%, 0.08), #fff);
}

.property-type-list-div-size.area {
	padding: 0.75rem 1rem 0.5rem;
}

.property-type-list-div-size.brand-subtype {
	overflow: auto;
	height: 240px;
	margin-bottom: 0.5rem;
	background-image: none;
}

.property-type-list-div-size.price-range {
	padding: 0.75rem 1rem 0.5rem;
}

.property-type-list-div-size.property-id {
	padding: 0.75rem 1rem;
}

.property-type-list-div-size.technology {
	padding-left: 1rem;
}

.property-type-list-div-size.pool {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.property-type-list-div-size.size {
	padding-left: 1rem;
}

.property-type-list-div-size.color {
	padding-left: 1rem;
}

.property-type-list-div-size.size-subtype {
	overflow: auto;
	height: 180px;
	margin-bottom: 0.5rem;
	background-image: none;
}

.property-type-list-div-size.size-subtype {
	overflow: auto;
	height: 180px;
	margin-bottom: 0.5rem;
	background-image: none;
}

.property-type-list-div-tech {
	width: 100%;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	background-color: #fff;
	background-image: -webkit-gradient(linear, right top, left top, from(hsla(0, 0%, 85%, 0.08)), to(#fff));
	background-image: linear-gradient(270deg, hsla(0, 0%, 85%, 0.08), #fff);
}

.property-type-list-div-tech.area {
	padding: 0.75rem 1rem 0.5rem;
}

.property-type-list-div-tech.brand-subtype {
	overflow: auto;
	height: 240px;
	margin-bottom: 0.5rem;
	background-image: none;
}

.property-type-list-div-tech.price-range {
	padding: 0.75rem 1rem 0.5rem;
}

.property-type-list-div-tech.property-id {
	padding: 0.75rem 1rem;
}

.property-type-list-div-tech.technology {
	padding-left: 1rem;
}

.property-type-list-div-tech.pool {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.property-type-list-div-tech.size {
	padding-left: 1rem;
}

.property-type-list-div-tech.color {
	padding-left: 1rem;
}

.property-type-list-div-tech.size-subtype {
	overflow: auto;
	height: 240px;
	margin-bottom: 0.5rem;
	background-image: none;
}

.property-type-list-div-color {
	width: 100%;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	background-color: #fff;
	background-image: -webkit-gradient(linear, right top, left top, from(hsla(0, 0%, 85%, 0.08)), to(#fff));
	background-image: linear-gradient(270deg, hsla(0, 0%, 85%, 0.08), #fff);
}

.property-type-list-div-color.area {
	padding: 0.75rem 1rem 0.5rem;
}

.property-type-list-div-color.brand-subtype {
	overflow: auto;
	height: 240px;
	margin-bottom: 0.5rem;
	background-image: none;
}

.property-type-list-div-color.price-range {
	padding: 0.75rem 1rem 0.5rem;
}

.property-type-list-div-color.property-id {
	padding: 0.75rem 1rem;
}

.property-type-list-div-color.technology {
	padding-left: 1rem;
}

.property-type-list-div-color.pool {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.property-type-list-div-color.size {
	padding-left: 1rem;
}

.property-type-list-div-color.color {
	padding-left: 1rem;
}

.property-type-list-div-color.size-subtype {
	overflow: auto;
	height: 240px;
	margin-bottom: 0.5rem;
	background-image: none;
}

.icon-4 {
	position: absolute;
	z-index: 1;
}

.hidden-desktop-tablet-nav-link {
	display: none;
}

#products_module.responsive.cms_entity .product-title {
	margin-bottom: 1.5rem;
	padding-top: 0px;
	font-size: 1.8rem;
	line-height: 2.15rem;
	font-weight: 500;
	margin-top: 0px;
	margin-left: -10px;
	padding-bottom: 1.5rem;
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.product-details-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.product-slider-column {
	width: 50%;
	padding-right: 1vw;
}

.product-details-title {
	font-size: 2rem;
	line-height: 2.15rem;
	font-weight: 500;
}

.product-details-column {
	width: 50%;
	padding-right: 0vw;
	padding-left: 1vw;
}

.mini-view-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 1rem;
	padding-bottom: 1rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.mini-view-image {
	width: 200px;
	height: 200px;
	min-width: 25%;
	border-style: solid;
	border-width: 1px;
	border-color: hsla(194.1322314049587, 0%, 72%, 0.5);
	background-image: url('../images/product-1_700x428_1.png');
	background-position: 50% 50%;
	background-size: contain;
	background-repeat: no-repeat;
}

.product-details-slider {
	height: 500px;
}

.product-details-slide-1 {
	background-color: #fff;
	background-image: url('../images/product-1_700x428.png');
	background-position: 50% 50%;
	background-size: contain;
	background-repeat: no-repeat;
}

.product-details-arrow {
	width: 3rem;
	color: #bebebe;
}

.product-deails-slide-nav {
	display: none;
	padding-top: 1rem;
	background-color: hsla(0, 0%, 100%, 0.85);
	font-size: 0.65rem;
}

.product-description-div {
	margin-bottom: 1.5rem;
}

.product-code-product-details {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding-top: 0rem;
	padding-bottom: 0rem;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	color: rgba(96, 96, 96, 0.5);
	font-size: 0.75rem;
}

.product-details-desc-text {
	font-size: 0.9rem;
	line-height: 1.35rem;
}

.product-specs-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.top-icon-mobile-div {
	top: 0px;
	right: 0px;
	z-index: 10000;
	display: none;
	width: 100%;
	margin-top: 0rem;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.cart-amounts-div {
	position: relative;
	left: -1.25rem;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 0.35rem 0.25rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	color: #333;
	font-size: 0.8rem;
	line-height: 0.8rem;
	font-weight: 700;
}

.text-cart-button-div {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.product-details-specs {
	width: 50%;
}

.product-detail-spec-label {
	overflow: visible;
	width: auto;
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	border-bottom: 1px solid hsla(194.1322314049587, 0%, 72%, 0.5);
	font-size: 0.85rem;
	font-weight: 700;
}

.product-details-specs-numbers {
	width: 50%;
}

.product-detail-spec-label-numbers {
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	border-bottom: 1px solid hsla(194.1322314049587, 0%, 72%, 0.5);
	font-size: 0.85rem;
	font-weight: 400;
}

.product-details-card-price-div {
	width: 40%;
}

.product-details-spec-column {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
	min-width: 50%;
}

.product-details-spec-column._2 {
	padding-left: 0.5rem;
}

.product-details-spec-column._1 {
	width: 100%;
	margin-bottom: 1.5rem;
}

.product-spec-column-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.product-details-slider-arrow {
	font-size: 1.75rem;
}

.tab-link-tab-1 {
	background-color: hsla(194.1322314049587, 0%, 72%, 0.5);
}

.tab-link-tab-1.w--current {
	padding-right: 1rem;
	padding-left: 1rem;
	border-style: solid solid none;
	border-width: 1px;
	border-color: hsla(194.1322314049587, 0%, 72%, 0.5);
	background-color: #fff;
}

.tab-link-inactive {
	padding-right: 1rem;
	padding-left: 1rem;
	border-style: solid solid none none;
	border-width: 1px;
	border-color: hsla(194.1322314049587, 0%, 72%, 0.5);
	background-color: #f1f1f1;
}

.tab-content-wrapper {
	border-style: solid;
	border-width: 1px;
	border-color: hsla(194.1322314049587, 0%, 72%, 0.5);
	background-color: #fff;
}

.tab-spec-list-item {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 0.25rem 1rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	border-bottom: 1px solid hsla(194.1322314049587, 0%, 72%, 0.5);
	font-size: 0.85rem;
	font-weight: 700;
}

.out-of-stock-spec-text {
	color: #b5b5b5;
	text-transform: uppercase;
}

.in-stock-spec-text {
	color: #72a752;
	text-transform: uppercase;
}

.please-call-spec-text {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-left: 1.25rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-image: url('../images/homepage-icons-phone.svg');
	background-position: 0% 50%;
	background-size: 15px 15px;
	background-repeat: no-repeat;
	color: #666;
	text-transform: uppercase;
}

.basket-amounts-div-2 {
	position: relative;
	left: -1.25rem;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 0.35rem 0.25rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	color: #333;
	font-size: 0.8rem;
	line-height: 0.8rem;
	font-weight: 700;
}

.nav-item-with-icon {
	display: none;
}

.mobile-icon-text-holder {
	display: none;
}

.mobile-icon-holder {
	display: none;
}

.basket-amounts-div-3 {
	position: relative;
	left: -1.25rem;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 0.35rem 0.25rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	color: #333;
	font-size: 0.8rem;
	line-height: 0.8rem;
	font-weight: 700;
}

.icon-search-2-column-wrap-2 {
	display: block;
	width: 58%;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.hidden-register-button-div-2 {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.telephone-number-2 {
	padding-right: 0.5rem;
	color: #333;
	font-size: 0.85rem;
}

.account-link-2 {
	padding: 0.25rem 0.75rem;
	border-style: solid;
	border-width: 1px;
	border-color: hsla(0, 0%, 75%, 0.5);
	background-color: #f1f1f1;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	font-size: 0.8rem;
	line-height: 1.05rem;
}

.account-link-2:hover {
	background-color: #fff;
}

.account-link-2.top {
	padding: 0.25rem 0.75rem;
	border-bottom-style: none;
	line-height: 1.15rem;
}

.mobile-menu-arrow-2 {
	display: none;
}

.icon-search-2-column-wrap-3 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 58%;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.top-half-div-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	max-width: 1600px;
	padding: 1rem 0px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.main-nav-link-2 {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	margin-right: 1px;
	margin-left: 1px;
	padding: 0.6rem 0.25rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	color: #000;
	font-size: 0.8rem;
	line-height: 1rem;
	font-weight: 700;
	text-align: center;
	letter-spacing: 0.01rem;
	text-decoration: none;
	text-transform: uppercase;
}

.main-nav-link-2:hover {
	background-color: #333;
	color: #fff;
}

.main-nav-link-2.w--current {
	border-color: #e77c25;
}

.main-nav-link-2.mobile-duplicate {
	display: none;
}

.main-nav-link-2.hidden {
	display: none;
}

.main-nav-link-2.full {
	width: 100%;
	margin-right: 0px;
	margin-left: 0px;
	font-size: 0.75rem;
}

.main-nav-link-2.full {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-right: 0px;
	margin-left: 0px;
	font-size: 0.75rem;
}

.main-nav-link-2.full.withicon {
	display: none;
}

.main-nav-link-2.mobile-duplicate {
	display: none;
}

.navbar-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #fff;
}

.search-column-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 58%;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	align-self: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
}

.search-bar-form-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 315px;
	margin-bottom: 0px;
	padding-right: 0.5rem;
}

.nav-menu-2 {
	z-index: 2;
	display: block;
	width: 100%;
	height: 3.25rem;
	float: right;
	background-color: #ffcb05;
	box-shadow: 0 7px 20px 0 rgba(0, 0, 0, 0.12);
	color: #fff;
}

.search-link-icon-mobile-2 {
	display: none;
	width: 40px;
	height: 40px;
	min-height: 40px;
	min-width: 40px;
	background-image: url('../images/search-icons-02.svg');
	background-position: 50% 50%;
	background-size: 25px 25px;
	background-repeat: no-repeat;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
}

.search-link-icon-mobile-2:hover {
	background-color: #f1f1f1;
	background-image: url('../images/search-icons-01.svg');
}

.dropdown-link-3 {
	display: block;
	width: 100%;
	padding: 0.1rem 0.5rem 0.1rem 0.75rem;
	border-bottom: 1px none rgba(0, 0, 0, 0.2);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #666;
	font-size: 0.8rem;
	line-height: 1.3rem;
	font-weight: 400;
	text-decoration: none;
	text-transform: none;
}

.dropdown-link-3:hover {
	background-color: hsla(0, 0%, 100%, 0.7);
	color: #000;
}

.dropdown-link-3.w--current {
	background-color: hsla(0, 0%, 100%, 0.7);
	box-shadow: inset 4px 0 0 0 #e77c25;
	color: #1f304f;
}

.dropdown-link-3.subcategory {
	font-weight: 700;
	text-transform: none;
}

.search-input-hidden-2 {
	display: block;
	width: 100%;
	margin-bottom: 0px;
	margin-left: 0.25rem;
	padding-left: 0px;
	border-style: none none solid;
	border-width: 1px;
	border-color: #fff #fff #bebebe;
	opacity: 1;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
}

.search-input-hidden-2:focus {
	border-bottom-color: #ffcb05;
	color: #000;
}

.brand-column-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 34%;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

.plus-sign {
	display: block;
	background-image: url('../images/search-icons-plus-grey.svg'), url('../images/search-icons-minus-grey.svg');
	background-position: 95% 50%, 95% 50%;
	background-size: 18px, 15px 15px;
	background-repeat: no-repeat, no-repeat;
}

.search-heading-div-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 0rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}

.search-filter-title-2 {
	width: 70%;
	height: 51px;
	padding: 0.35rem 1rem 0.35rem 0rem;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	color: #666;
	font-size: 1.46rem;
	line-height: 2.5rem;
	font-weight: 600;
}

.label-section-title-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-bottom: 0px;
	padding: 0.45rem 1rem;
	border-left: 4px solid #ffcb05;
	background-color: hsla(0, 0%, 85%, 0.49);
	background-image: url('../images/search-icons-minus-grey.svg');
	background-position: 95% 50%;
	background-size: 15px 15px;
	background-repeat: no-repeat;
	box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.05), inset 0 1px 0 0 rgba(0, 0, 0, 0.05);
	-webkit-transition: all 350ms ease;
	transition: all 350ms ease;
	color: #333;
	font-weight: 400;
	text-decoration: none;
}

.label-section-title-2:hover {
	background-color: #ffcb05;
	background-image: url('../images/search-icons-minus.svg');
	background-size: 15px;
}

.label-section-title-2.plus-sign {
	display: none;
	background-image: url('../images/search-icons-plus-grey.svg'), url('../images/search-icons-minus-grey.svg');
	background-position: 95% 50%, 95% 50%;
	background-size: 15px 15px, 15px 15px;
	background-repeat: no-repeat, no-repeat;
}

.label-section-title-2.plus-sign:hover {
	background-image: url('../images/search-icons-plus-white.svg'), url('../images/search-icons-minus-grey.svg');
	background-position: 95% 50%, 95% 50%;
	background-size: 15px 15px, 15px 15px;
	background-repeat: no-repeat, no-repeat;
}

.div-block-4 {
	-webkit-align-self: flex-end;
	-ms-flex-item-align: end;
	align-self: flex-end;
}

.button-3 {
	-webkit-align-self: flex-start;
	-ms-flex-item-align: start;
	align-self: flex-start;
}

.div-block-5 {
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.div-block-6 {
	display: none;
}

.language-dropdown-link-2 {
	padding: 0.25rem 0.75rem 0.25rem 2.5rem;
	border: 1px solid #f1f1f1;
	background-color: #f1f1f1;
	background-image: url('../images/flags-homepage-icons-44.svg');
	background-position: 8px 50%;
	background-size: 24px 24px;
	background-repeat: no-repeat;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	font-size: 0.7rem;
	font-weight: 500;
}

.language-dropdown-link-2:hover {
	background-color: #fff;
}

.language-dropdown-link-2._1 {
	background-image: url('../images/flags-homepage-icons-43.svg');
}

.language-dropdown-link-2._2 {
	background-image: url('../images/flags-homepage-icons-45.svg');
}

.top-nav-link-2 {
	display: block;
	margin-bottom: 1px;
	padding: 0.5rem 0.75rem 0.5rem 2rem;
	background-image: url('../images/stores-dk.svg');
	background-position: 0% 50%;
	background-size: 18px 18px;
	background-repeat: no-repeat;
	color: #333;
	font-size: 0.75rem;
	line-height: 0.75rem;
	font-weight: 700;
	letter-spacing: 0.01px;
	text-decoration: none;
	text-transform: uppercase;
}

.top-nav-link-2.language {
	display: none;
	padding-right: 0.5rem;
	padding-left: 0.5rem;
	background-image: none;
	font-size: 0.7rem;
}

.dropdown-list-2 {
	position: absolute;
	z-index: 1;
	display: none;
}

.dropdown-list-2.w--open {
	right: 7px;
}

.dropdown-list-3 {
	z-index: 1;
}

.pagination-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding-top: 1.5rem;
	padding-bottom: 0.5rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.previous-div {
	-webkit-box-ordinal-group: 0;
	-webkit-order: -1;
	-ms-flex-order: -1;
	order: -1;
}

.center-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.previous-link-text-inactive {
	color: #909090;
	font-size: 0.85rem;
}

.pagination-page-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 30px;
	height: 30px;
	margin-right: 0.25rem;
	margin-left: 0.25rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border: 1px solid #ebebeb;
	background-color: hsla(0, 0%, 100%, 0.85);
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #0c366c;
	font-weight: 700;
}

.pagination-page-link:hover {
	background-color: #fff;
	text-decoration: none;
}

.pagination-page-link.w--current {
	background-color: #288ab1;
	color: #fff;
}

.dot-dot-dot {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 30px;
	height: 30px;
	padding-left: 10px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	color: #686868;
	font-weight: 700;
	text-align: center;
	letter-spacing: 0.2rem;
}

.next-div {
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
}

.next-link-text {
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #1c5ba2;
}

.next-link-text:hover {
	color: #000;
}

.view-type-block {
	display: inline-block;
	margin-top: 0em;
	margin-bottom: 0em;
	float: left;
}

.view-type {
	width: 30px;
	height: 30px;
	min-width: 0px;
	margin-left: 10px;
	float: left;
	border: 1px solid #516370;
	opacity: 1;
	color: #516370;
	font-size: 0.8em;
	text-align: center;
	text-decoration: none;
}

.view-type:hover {
	opacity: 0.8;
}

.view-type.grid-view {
	width: 20px;
	height: 20px;
	margin-top: 4px;
	margin-left: 0px;
	border-style: none;
	background-image: url('../images/icon_gridview_blk.svg');
	background-position: 50% 50%;
	background-size: 20px;
	background-repeat: no-repeat;
	opacity: 0.6;
}

.view-type.list-view {
	width: 20px;
	height: 20px;
	margin-top: 4px;
	margin-left: 5px;
	padding-top: 0px;
	float: left;
	border-style: none;
	background-image: url('../images/icon_listview_blk.svg');
	background-position: 50% 50%;
	background-size: 20px;
	background-repeat: no-repeat;
	opacity: 0.6;
}

.view-type.list-view {
	margin-top: 0px;
	background-image: url('../images/icon_listview_blk.svg');
	background-position: 50% 50%;
	background-size: 20px;
	background-repeat: no-repeat;
	opacity: 1;
}

.view-type.list-view:hover {
	opacity: 1;
}

.view-type.list-view.on-list {
	opacity: 1;
}

.view-type.list-view.on-grid-view-list-icon {
	opacity: 0.6;
}

.view-type.grid-view {
	margin-top: 0px;
	background-image: url('../images/icon_gridview_blk.svg');
	background-position: 50% 50%;
	background-size: 20px;
	background-repeat: no-repeat;
	opacity: 0.6;
}

.view-type.grid-view:hover {
	width: 20px;
	opacity: 0.6;
}

.view-type.grid-view.on-list-page {
	opacity: 0.6;
}

.view-type.grid-view.on-list-page:hover {
	opacity: 1;
}

.view-type.grid-view.on-grid-view {
	opacity: 1;
}

.pagination-form-wrapper {
	margin-bottom: 0px;
}

.pagination-block {
	margin-top: 0em;
	margin-bottom: 0em;
	float: right;
	-webkit-box-ordinal-group: 1;
	-webkit-order: 0;
	-ms-flex-order: 0;
	order: 0;
}

.pagination-bar {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 0px;
	padding-left: 0px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	border-top: 1px none rgba(81, 99, 112, 0.29);
	border-radius: 1px;
}

.show-per-page {
	display: inline-block;
}

.page {
	display: inline-block;
}

.pagination-dropdown {
	display: inline-block;
	width: 45px;
	height: 30px;
	margin-right: 5px;
	margin-bottom: 0px;
	margin-left: 5px;
	padding: 4px 0px 4px 4px;
	font-size: 12px;
}

.pagination-dropdown.per-page-dropdown {
	margin-right: 0px;
}

.page-block {
	display: inline-block;
	margin-right: 20px;
}

.pagination-form {
	font-size: 12px;
}

.view-type-block-2 {
	display: inline-block;
	margin-top: 0.5em;
	margin-bottom: 0.5em;
	float: left;
}

.sort-dropdown {
	height: 30px;
	margin-right: 0px;
	margin-bottom: 0px;
	padding: 4px 8px;
	font-size: 0.65em;
	line-height: 1.5em;
	text-transform: uppercase;
}

.sort-form-wrapper {
	position: static;
	left: 0px;
	margin-top: 0em;
	margin-right: 0.5rem;
	margin-bottom: 0.5em;
	float: left;
}

.pagination-holder-list-view {
	display: block;
	margin-right: 0px;
	margin-left: 0.35rem;
	padding-right: 0px;
	padding-left: 0px;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.pagination-holder-list-view.grid-view {
	padding-right: 0px;
}

.add-to-cart-text-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	max-width: 92px;
	padding-right: 0.5rem;
	padding-left: 0.5rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #333;
	font-size: 0.75rem;
	line-height: 1rem;
	font-weight: 700;
	text-decoration: none;
}

.add-to-cart-text-2:hover {
	background-image: none;
}

.add-to-cart-text-2.pre-order {
	max-width: 101px;
	color: #333;
	text-align: center;
	text-transform: uppercase;
}

.add-to-cart-text-2.add-to-cart {
	margin-right: 2.75rem;
	color: #333;
	font-weight: 700;
	text-align: center;
	text-transform: uppercase;
	max-width: 145px;
}

#product-container .add-to-cart-text-2.add-to-cart {
	font-size: 1.2rem;
}

.add-to-cart-text-2.pre-order-date {
	max-width: 101px;
	color: #333;
	font-weight: 400;
	text-align: center;
	text-transform: none;
}

.register-drop-down-link-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 0.15rem 0.35rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border: 1px solid #dadada;
	background-color: #fff;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-size: 0.65rem;
	line-height: 1.1rem;
	text-transform: uppercase;
}

.register-drop-down-link-2:hover {
	background-color: #f1f1f1;
}

.cart-icon-link-wrapper-3 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 42px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	background-color: #fff;
}

.cart-icon-link-wrapper-3.grey {
	background-color: #fff;
}

.add-to-cart-icon-3 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 42px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: flex-end;
	-ms-flex-item-align: end;
	align-self: flex-end;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	background-color: #ffcb05;
	background-image: url('../images/homepage-icons_triangle-LT-GREY-36.svg');
	background-position: 0% 50%;
	background-size: contain;
	background-repeat: no-repeat;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
}

.add-to-cart-icon-3:hover {
	width: 100%;
	background-color: #ddd;
}

.add-to-cart-icon-3.with-text {
	background-image: url('../images/homepage-icons_triangle-LT-GREY-36.svg');
	background-position: 0% 50%;
	background-size: contain;
	background-repeat: no-repeat;
	text-decoration: none;
}

.add-to-cart-icon-3.with-text:hover {
	background-color: hsla(47.51999999999999, 100%, 44.33%, 1);
	box-shadow: none;
}

#products_module.cms_list .new-present-div-2,
.property-slider-outer-wrap-2 .new-present-div-2 {
	position: absolute;
	top: 230px;
	height: 45px;
	overflow: hidden;
	width: 100%;
}

.trio-card-div-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-bottom: 1rem;
}

.property-spotlight-image-link-3 {
	width: 100%;
	height: 275px;
	margin-bottom: 0rem;
	border-bottom: 1px solid #dadada;
	background-image: url('../images/product-1_700x428_1.png');
	background-position: 50% 50%;
	background-size: 275px;
	background-repeat: no-repeat;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	text-decoration: none;
}

.property-spotlight-image-link-3:hover {
	opacity: 0.85;
}

.property-spotlight-image-link-3._2 {
	height: 275px;
	margin-bottom: 0rem;
	border-bottom-style: solid;
	background-image: url('../images/product-2_700x448.png');
	background-position: 50% 50%;
	background-size: 275px;
	background-repeat: no-repeat;
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
}

.property-spotlight-image-link-3._2:hover {
	opacity: 0.85;
}

.property-spotlight-image-link-3._3 {
	margin-bottom: 0rem;
	border-bottom-style: solid;
	background-image: url('../images/product-3_700x448.png');
	background-position: 50% 50%;
	background-size: 275px;
	background-repeat: no-repeat;
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
}

.property-spotlight-image-link-3._3:hover {
	border-bottom-color: #dadada;
	opacity: 0.85;
}

.property-spotlight-image-link-3._4 {
	border-bottom-style: solid;
	background-image: url('../images/product-4_700x448.png');
	background-position: 50% 50%;
	background-size: 275px;
	background-repeat: no-repeat;
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
}

.property-spotlight-image-link-3._4:hover {
	opacity: 0.85;
}

.property-spotlight-image-link-3 img.special-offer-icon {
	width: auto;
	max-width: 100px;
	display: block;
	height: auto;
}

.property-spotlight-image-link-3 .special-offer-date,
.product-details-slideshow-wrapper .special-offer-date {
	color: #606060;
	font-size: 0.8rem;
	background-color: #fff;
	padding: 1px 3px;
	display: table;
	font-weight: normal;
	box-shadow: 2px 0px 7px 0px rgba(0, 0, 0, 0.25);
	margin: 5px 0px 0px 0px;
	font-weight: bold;
}

.product-details-slideshow-wrapper .special-offer-date {
	position: absolute;
	top: 90px;
	left: 0;
	z-index: 200;
}

.spotlight-list-wrapper-3 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	min-height: 199px;
	padding-top: 0.5rem;
	float: left;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.present-icon-wrapper-3 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	min-height: 45px;
	min-width: 150px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: hsla(0, 0%, 75%, 0.35);
}

.present-icon-wrapper-3-product-comparison {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	min-height: 45px;
	min-width: 150px;
	align-items: center;
}

.property-spotlight-wrapper-level-4 {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 100%;
	margin-right: 0rem;
	margin-left: 0.35rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	border-style: solid none none;
	border-width: 1px;
	border-color: #dadada;
}

#products_module.responsive.cms_list .items .item-wrapper .list-view-cart-row,
#products_module.responsive.cms_list .items .item-wrapper .cart-icon-link-wrapper-3 {
	margin-bottom: 0;
}

#products_module.cms_entity .product-details-wrapper .product-details-info-wrapper .product-details-column .info-and-pricing .price-and-shipping-div .item-prices .listing-details-heading {
	font-size: 1.6rem;
	line-height: 2rem;
}

#products_module.cms_entity .product-details-wrapper .product-details-info-wrapper .product-details-column .info-and-pricing .price-and-shipping-div .item-prices .stephanis-card-dropdown .listing-details {
	font-size: 1rem;
	line-height: 1rem;
	margin-top: 10px;
}

#products_module.cms_entity .product-details-wrapper .product-details-info-wrapper .product-details-column .info-and-pricing .price-and-shipping-div .item-prices .listing-details-column.large-now-price.large-single {
	padding-top: 0;
}

#products_module.cms_entity .product-details-wrapper .product-details-info-wrapper .product-details-column .info-and-pricing .price-and-shipping-div .item-prices .listing-detail-column-wrapper-2 {
	margin-bottom: 15px;
	margin-bottom: 15px;
}

#products_module.cms_entity .product-details-wrapper .product-details-slideshow-wrapper .product-details-slideshow .mini-slide-image-inner-wrapper {
	height: 100px;
}

.wishlist-save-icon-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 42px;
	height: 42px;
	max-height: 42px;
	max-width: 42px;
	min-height: 42px;
	min-width: 42px;
	margin-right: 0rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #ddd;
	background-image: url('../images/homepage-icons_heart_white-37.svg');
	background-position: 50% 50%;
	background-size: 22px 22px;
	background-repeat: no-repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

.wishlist-save-icon-2:hover {
	background-image: url('../images/homepage-icons_heart_yellow-46.svg');
}

.present-text-3 {
	padding-right: 0.5rem;
	color: #666;
	font-size: 0.7rem;
	line-height: 0.95rem;
	font-weight: 500;
}

#products_module.responsive.cms_list .item-wrapper .new-present-div-2 .present-icon-wrapper-3 .present-text-3 {
	line-height: 0.85rem;
}

.sale-banner-div-2 {
	position: absolute;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: auto;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 50%;
	max-height: 90px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-style: none solid;
	border-width: 1px;
	border-color: #dadada;
	background-color: transparent;
	background-image: none;
}

.prop-spotlight-details-wrapper-3 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding: 0rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	background-color: transparent;
}

.add-to-cart-icon-4 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 42px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: flex-end;
	-ms-flex-item-align: end;
	align-self: flex-end;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	background-color: #ffcb05;
	background-image: url('../images/homepage-icons_triangle-LT-GREY-36.svg');
	background-position: 0% 50%;
	background-size: contain;
	background-repeat: no-repeat;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
}

.add-to-cart-icon-4:hover {
	width: 100%;
	background-color: #ddd;
}

.add-to-cart-icon-4.out-of-stock {
	background-color: #dedede;
	background-image: none;
	background-position: 0px 0px;
	background-size: auto;
	background-repeat: repeat;
	opacity: 1;
}

.add-to-cart-icon-4.with-text {
	background-image: url('../images/homepage-icons_triangle-LT-GREY-36.svg');
	background-position: 0% 50%;
	background-size: contain;
	background-repeat: no-repeat;
	text-decoration: none;
}

.add-to-cart-icon-4.with-text:hover {
	background-color: hsla(47.51999999999999, 100%, 44.33%, 1);
	box-shadow: none;
}

.property-slider-arrow-link-4 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50px;
	height: 50px;
	margin: auto 0px auto 4px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 50px;
	background-color: rgba(97, 97, 97, 0);
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	color: #666;
	font-size: 1.25rem;
}

.property-slider-arrow-link-4:hover {
	background-color: #ffcb05;
}

.property-slider-arrow-link-4.property-arrow-right {
	margin-right: 4px;
}

.sale-banner-div-3 {
	position: absolute;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: auto;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 50%;
	max-height: 90px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-style: none solid;
	border-width: 1px;
	border-color: #dadada;
	background-color: transparent;
	background-image: none;
}

.icon-5 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 45px;
	height: 45px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #666;
	font-size: 1.75rem;
	font-style: normal;
	font-weight: 600;
}

.icon-5:hover {
	color: #fff;
}

.out-of-stock-text-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	padding-right: 1.5rem;
	padding-left: 0rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #b5b5b5;
	font-size: 0.75rem;
	line-height: 1rem;
	font-weight: 700;
	text-decoration: none;
	text-transform: uppercase;
}

.div-block-7 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50px;
	height: 20px;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}

.tooltip-holder {
	position: absolute;
	width: 300px;
}

.thumbnail-view-tooltip {
	position: absolute;
	left: 108px;
	top: 40px;
	z-index: 999;
	display: none;
	padding: 2px 6px;
	background-color: #ffcb05;
	box-shadow: 1px 1px 8px -5px #000;
	font-size: 0.75rem;
	font-weight: 500;
}

.list-view-tooltip {
	position: absolute;
	left: 155px;
	top: 40px;
	bottom: auto;
	z-index: 999;
	display: none;
	padding: 2px 6px;
	background-color: #ffcb05;
	box-shadow: 1px 1px 8px -5px #000;
	font-size: 0.75rem;
	font-weight: 500;
	text-align: center;
}

.search-input-hidden-3 {
	display: block;
	width: 100%;
	margin-bottom: 0px;
	margin-left: 0.25rem;
	padding-left: 0px;
	border-style: none none solid;
	border-width: 1px;
	border-color: #fff #fff #bebebe;
	opacity: 1;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
}

.search-input-hidden-3:focus {
	border-bottom-color: #ffcb05;
	color: #000;
}

.cart-amounts-div-2 {
	position: relative;
	left: -1.25rem;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 0.35rem 0.25rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	color: #333;
	font-size: 0.8rem;
	line-height: 0.8rem;
	font-weight: 700;
}

.nav-menu-3 {
	z-index: 2;
	display: block;
	width: 100%;
	height: 3.25rem;
	float: right;
	background-color: #ffcb05;
	box-shadow: 0 7px 20px 0 rgba(0, 0, 0, 0.12);
	color: #fff;
}

html.w-mod-js *[data-ix="minislideshow-load"] {
	opacity: 0;
	-webkit-transform: translate(0px, 200px);
	-ms-transform: translate(0px, 200px);
	transform: translate(0px, 200px);
}

html.w-mod-js *[data-ix="intro-load"] {
	opacity: 0;
	-webkit-transform: translate(0px, 200px);
	-ms-transform: translate(0px, 200px);
	transform: translate(0px, 200px);
}

html.w-mod-js *[data-ix="price-section-open"] {
	opacity: 1;
}

html.w-mod-js *[data-ix="price-section-open-2"] {
	opacity: 1;
}

html.w-mod-js *[data-ix="price-section-close"] {
	opacity: 1;
}

html.w-mod-js *[data-ix="brand-section-open"] {
	opacity: 1;
}

@media (min-width: 992px) {
	.sign-off-div-wrap .column-3 {
		width: 85%;
	}

	.sign-off-div-wrap .column-2 {
		width: 15%;
	}

	.footer-signoff-links.last {
		margin-right: 0px;
	}
}

@media (max-width: 1885px) and (min-width: 992px) {
	.cb-link-box-div.resize {
		height: 17vw;
		max-height: 275px;
	}
}

@media (max-width: 991px) {
	.cb-link-box-div.resize {
		height: 33vw;
	}
}

@media (max-width: 767px) {
	.cb-link-box-div.resize {
		height: 33vw;
		background-size: 100% 33vw;
	}
}

@media (max-width: 479px) {
	.cb-link-box-div.resize {
		height: 62vw;
		background-size: 100% 62vw;
	}

	.cb-link-box-div.large.resize {
		background-size: 100% 35vw;
		height: 35vw;
		background-position: 100% !important;
	}
}



@media (max-width: 991px) {
	.header-section {
		left: 0%;
		top: 0%;
		right: 0%;
		bottom: auto;
		margin-top: -1px;
	}

	.top-icon-div {
		width: 100%;
		height: 60px;
		max-width: 600px;
		min-width: 500px;
		margin-top: 0.25rem;
		margin-bottom: 1rem;
		padding-right: 0rem;
		padding-left: 0rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.top-icon-link-block {
		margin-left: 1rem;
		border-radius: 50%;
		-webkit-transition-property: all;
		transition-property: all;
		-webkit-transition-duration: 300ms;
		transition-duration: 300ms;
	}

	.top-icon-link-block.first-icon-link {
		margin-left: 0rem;
		-webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
		transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
	}

	.top-icon-link-block.first-icon-link:hover {
		border-radius: 50%;
	}

	.top-icon-link-block.header.phone {
		width: 32px;
		height: 32px;
	}

	.top-icon-link-block.header.stores {
		position: absolute;
		margin-top: 12px;
		margin-left: -2.55rem;
		padding-top: 0rem;
		padding-bottom: 0rem;
	}

	.top-icon-link-block.header.support {
		padding-top: 0rem;
	}

	.top-icon-link-block.header.mobile-cart {
		padding-top: 0rem;
		padding-bottom: 0rem;
	}

	.main-nav-link {
		margin-top: 0rem;
		padding: 1rem;
		border-color: rgba(93, 63, 58, 0.35);
		border-top-style: none;
		border-right-style: none;
		border-left-style: none;
		color: #00283b;
		font-size: 1rem;
		line-height: 1.5rem;
		font-weight: 600;
		text-align: left;
	}

	.main-nav-link:hover {
		border-style: none none solid;
		border-color: rgba(93, 63, 58, 0.35);
		background-color: hsla(0, 0%, 100%, 0.7);
		line-height: 1.5rem;
	}

	.main-nav-link.w--current {
		border-bottom-color: rgba(93, 63, 58, 0.35);
		background-color: hsla(0, 0%, 100%, 0.7);
	}

	.main-nav-link.mobile-duplicate {
		display: block;
		width: 85%;
		margin-right: 0px;
		margin-left: 0px;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-bottom-style: none;
		-webkit-transition-property: all;
		transition-property: all;
		-webkit-transition-duration: 450ms;
		transition-duration: 450ms;
		color: #333;
		text-transform: none;
	}

	.main-nav-link.mobile-duplicate.capital-letters {
		text-transform: uppercase;
	}

	.main-nav-link.mobile-duplicate:hover,
	.mm-listitem:hover .main-nav-link.mobile-duplicate {
		border-style: none;
		border-bottom-color: transparent;
		background-color: #ffcb05;
		color: #333;
	}

	.main-nav-link.hidden {
		color: hsla(199.32203389830508, 42.22%, 36.09%, 1);
	}

	.main-nav-link.full {
		height: 2.5rem;
		margin-right: 0px;
		margin-left: 0px;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		border-bottom-style: solid;
		border-bottom-width: 1px;
		background-color: #fff;
		-webkit-transition-duration: 450ms;
		transition-duration: 450ms;
		color: #333;
		font-size: 1rem;
	}

	.main-nav-link.full:hover {
		height: 2.5rem;
		border-bottom-style: solid;
		border-bottom-width: 1px;
		background-color: #333;
		color: #e4c700;
	}

	.main-nav-link.full.withicon {
		display: block;
		border-bottom-style: none;
		background-color: #f0f0f0;
	}

	.nav-menu {
		z-index: 100000000000;
		display: block;
		overflow: auto;
		width: 100%;
		height: 72vh;
		margin-top: 0px;
		border-top: 4px solid #bebebe;
		border-left: 1px solid #bebebe;
		background-color: #f1f1f1;
		text-align: left;
	}

	.brand {
		width: 50%;
		min-height: 54px;
		min-width: 400px;
		margin-top: 0.25rem;
		margin-bottom: 0.25rem;
	}

	.menu-button-text {
		margin-top: -8px;
		color: #000;
		font-size: 0.7rem;
		line-height: 1rem;
		text-align: center;
	}

	.navbar {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: auto;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-justify-content: space-around;
		-ms-flex-pack: distribute;
		justify-content: space-around;
	}

	.mobile-menu-icon {
		-webkit-transition: all 450ms ease;
		transition: all 450ms ease;
		color: #000;
		font-size: 2rem;
		text-align: center;
	}

	.mobile-menu-icon:hover {
		color: #ffcb05;
	}

	.mobile-link-label {
		display: inline-block;
		margin-top: 0rem;
		margin-bottom: 0rem;
		line-height: 1.25rem;
	}

	.two-part-mobile-nav-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-bottom: 1px solid rgba(93, 63, 58, 0.35);
		background-color: #fff;
	}

	.container {
		max-width: 991px;
	}

	.container.nav-container {
		display: block;
		width: 100%;
		height: 3.5rem;
		max-width: 940px;
		margin-top: 0px;
	}

	.container.nav-container {
		display: block;
		width: 100%;
		height: 3.5rem;
		max-width: 940px;
		margin-top: 0px;
	}

	.menu-button {
		position: absolute;
		right: auto;
		top: 0%;
		left: 50px;
		bottom: auto;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 68px;
		height: 80px;
		margin-top: 15px;
		padding-top: 20px;
		padding-right: 10px;
		padding-left: 10px;
		float: right;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-transition: background-color 300ms ease;
		transition: background-color 300ms ease;
		color: #fff;
	}

	.menu-button:hover {
		background-color: #f1f1f1;
		color: #ddd;
	}

	.menu-button.w--open {
		background-color: transparent;
	}

	.stephanis-logo {
		width: 100%;
		max-width: 100%;
		padding-right: 0rem;
	}

	.dropdown-wrapper {
		display: none;
	}

	.mobile-menu-arrow {
		display: block;
		width: 15%;
		border-left: 1px solid rgba(93, 63, 58, 0.35);
		background-image: url('../images/arrow-grey-icons-06.svg');
		background-position: 50% 50%;
		background-size: 15px 15px;
		background-repeat: no-repeat;
		-webkit-transition: all 450ms ease;
		transition: all 450ms ease;
		color: #000;
	}

	.mobile-menu-arrow:hover {
		background-color: #f1f1f1;
		opacity: 1;
	}

	.search-bar-div {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		padding-left: 0rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.search-bar-form {
		width: 380px;
		border-top: 2px none #e4c700;
		border-bottom: 1px solid hsla(0, 0%, 75%, 0.5);
	}

	.search-input-hidden {
		display: block;
		height: 40px;
		margin-left: 0rem;
		padding-left: 1rem;
		border-bottom-style: none;
		opacity: 1;
	}

	.slider-content-wrapper {
		float: none;
	}

	.slider-header {
		font-size: 35px;
		line-height: 39px;
	}

	.slide-text-wrap {
		max-width: 85%;
	}

	.slider-image-div {
		top: 40px;
		right: 36px;
		width: 320px;
	}

	.slider-header-emphasis {
		font-size: 35px;
		line-height: 34px;
	}

	.slider-row {
		top: 25px;
	}

	.slider-paragraph {
		display: block;
		max-width: 400px;
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
		font-size: 16px;
	}

	.slider-section {
		padding-top: 135px;
	}

	.slider-arrow {
		width: 50px;
		height: 50px;
		margin-right: 1rem;
		margin-bottom: 1rem;
	}

	.slider-arrow.left {
		width: 50px;
		height: 50px;
		margin-left: 1rem;
		font-size: 1.5rem;
	}

	.top-telephone-div {
		padding-left: 1rem;
		-webkit-align-self: flex-start;
		-ms-flex-item-align: start;
		align-self: flex-start;
	}

	.quicklink-icon-section {
		min-width: 100%;
		padding-top: 2rem;
		padding-bottom: 1rem;
	}

	.quicklink-icon-wrapper {
		width: 100%;
		min-width: auto;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.quicklink-icon-div-wrapper {
		width: 50%;
		padding-bottom: 1rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.quicklink-icon-div-wrapper.repeat {
		min-height: 338px;
		padding: 1rem;
	}

	.content-box-section {
		padding-bottom: 0rem;
	}

	.cb-section-wrapper {
		width: 100%;
		min-width: auto;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.small-cb-div-wrapper {
		width: 33.33%;
		padding-bottom: 2rem;
	}

	.cb-link-box-div {
		width: 48%;
		margin: 2% 0rem;
		padding-right: 0.5rem;
	}

	.cb-link-box-div.large {
		width: 100%;
		margin-top: 0%;
		margin-bottom: 0%;
		padding-right: 0rem;
	}

	.four-link-div-wrapper {
		margin-bottom: 0.75rem;
		padding-right: 2rem;
		padding-left: 2rem;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.two-link-div-wrapper {
		margin-bottom: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.cb-product-slider {
		width: 100%;
		margin-right: 0rem;
		margin-bottom: 2rem;
		margin-left: 0rem;
	}

	.ribbon-div-wrapper {
		width: 100%;
		min-width: auto;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.listing-details-column.large-now-price-product-details {
		padding-top: 0.5rem;
	}

	.listing-details-column.large-stephanis-card-price-product-details {
		padding-top: 0.5rem;
	}

	.listing-details-heading {
		padding-bottom: 0.25rem;
	}

	.listing-details-heading.original-price {
		padding-bottom: 0.25rem;
		font-size: 0.9rem;
	}

	.listing-details-heading.now-price {
		padding-bottom: 0.25rem;
		font-size: 0.9rem;
	}

	.listing-details-heading.large-now-price {
		padding-bottom: 0.25rem;
	}

	.listing-details-heading.large-now-price.centered {
		font-size: 1.175rem;
	}

	.listing-details-heading.large-now-price-product-details {
		padding-bottom: 0rem;
		line-height: 1.45rem;
	}

	.listing-details-heading.large-now-price-product-details.centered {
		font-size: 1.175rem;
	}

	.listing-details-heading.product-details {
		padding-bottom: 0rem;
		line-height: 1.45rem;
	}

	.property-slider-arrow-link {
		bottom: 600px;
		width: 60px;
		height: 60px;
		margin-right: 1.75rem;
		margin-left: 1.75rem;
		border-width: 6px;
		box-shadow: none;
	}

	.property-slider-arrow-link:hover {
		box-shadow: none;
	}

	.property-slider-arrow-link.property-arrow-right {
		left: auto;
		top: 0%;
		right: 0%;
		bottom: 600px;
		margin-right: 1.75rem;
		box-shadow: none;
	}

	.property-slider-arrow-link.property-arrow-right:hover {
		box-shadow: none;
	}

	.property-slider-arrow-link.left-arrow {
		left: 0%;
		top: 0%;
		right: auto;
		bottom: 620px;
	}

	.property-spotlight-slide {
		width: 49%;
		margin-right: 0.5%;
		margin-left: 0.5%;
		padding-right: 0.5rem;
		padding-left: 0.5rem;
		background-image: none;
		font-size: 1.25rem;
	}

	.listing-details {
		line-height: 1.2rem;
	}

	.property-slider-widget {
		min-width: auto;
	}

	.property-spotlight-image-link {
		display: block;
		height: 275px;
	}

	.property-spotlight-mask {
		width: 100%;
		min-width: auto;
		padding-right: 2%;
		padding-left: 2%;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.listing-price {
		width: 35%;
		margin-right: 15px;
	}

	.listing-status-text {
		font-size: 1rem;
	}

	.spotlight-list-wrapper {
		width: 100%;
	}

	.icon {
		color: #8b8b8b;
		font-size: 2.15rem;
	}

	.note-paragraph {
		width: 80%;
	}

	.present-div {
		top: 200px;
		height: 50px;
	}

	.ribbon-title {
		max-width: 550px;
		margin-right: auto;
		margin-left: auto;
	}

	.ribbon-banner {
		min-height: 175px;
	}

	.banner-wrapper {
		width: 100%;
		min-width: auto;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.banner-arrow {
		height: 40px;
		min-width: 158px;
		padding-left: 1.25rem;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		border-style: none;
		background-color: #e4c700;
	}

	.shop-categories-section-wrapper {
		width: 100%;
		min-width: auto;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.four-link-div-wrapper-copy {
		margin-bottom: 0rem;
		padding-right: 2rem;
		padding-left: 2rem;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.shop-cat-link-div {
		width: 48%;
		margin: 2% 0rem;
		padding-right: 0rem;
	}

	.shop-cat-link-div.large {
		width: 100%;
		padding-right: 0rem;
	}

	.shop-cat-link-div._2 {
		padding-right: 0rem;
	}

	.shop-cat-link-div.level-1 {
		margin-top: 0%;
	}

	.shop-cat-link-div.level-2 {
		margin-top: 0%;
		margin-bottom: 0rem;
	}

	.newsletter-submit-button.search-filter-button {
		margin-top: 0px;
		margin-bottom: 5px;
	}

	.social-media-wrapper.footer-socmed-wrapper {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.link-list-wrapper {
		width: 100%;
		min-width: auto;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.list-link-div {
		width: 180px;
		margin-bottom: 1rem;
	}

	.list-link-div.div-2 {
		width: 260px;
	}

	.grip-logo-link {
		margin-right: 0rem;
		margin-bottom: 1rem;
		margin-left: 0rem;
		float: right;
	}

	.footer-signoff-links {
		margin-right: 0.75rem;
		margin-left: 0rem;
	}

	.footer-copyright {
		margin-right: 0rem;
		margin-bottom: 0.25rem;
		float: left;
	}

	.column-3 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.column-2 {
		padding-right: 0px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: end;
		-webkit-align-items: flex-end;
		-ms-flex-align: end;
		align-items: flex-end;
	}

	.acrobat-reader {
		max-width: 150px;
	}

	.footer-signoff-link-wrap {
		display: block;
		margin-bottom: 1.5rem;
		float: left;
	}

	.acrobat-reader-link {
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		-ms-flex-order: 1;
		order: 1;
	}

	.sign-off-div-wrap {
		width: 100%;
		min-width: auto;
	}

	.wishlist-div {
		height: 30px;
		min-width: 70px;
		padding-top: 0rem;
	}

	.search-link-icon {
		overflow: visible;
		width: 30px;
		height: 30px;
		min-height: 0px;
		min-width: 0px;
		font-size: 0.5rem;
		line-height: 0.5rem;
	}

	.top-half-div {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-top: 1rem;
		padding-bottom: 0.5rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.12);
	}

	.mm-wrapper_opened .top-half-div {
		box-shadow: none;
	}

	.search-column {
		display: block;
		width: 100%;
		margin-top: 0rem;
		padding-right: 0rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		-ms-flex-order: 1;
		order: 1;
	}

	.search-column.mobilehidden {
		display: none;
	}

	.brand-column {
		width: 100%;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-ordinal-group: 0;
		-webkit-order: -1;
		-ms-flex-order: -1;
		order: -1;
	}

	.icon-column {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		padding-bottom: 0rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.cb-left-arrow {
		width: 70px;
		font-size: 1.75rem;
	}

	.cb-rightt-arrow {
		width: 70px;
		font-size: 1.75rem;
	}

	.present-icon-wrapper {
		min-height: 50px;
	}

	.present-text-wrapper {
		min-height: 50px;
	}

	.about-us-content-wrap {
		width: 100%;
		min-width: auto;
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.multi-slider-container {
		width: 100%;
	}

	.listing-two-column-wrapper {
		width: 100%;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.duo-set-wrapper {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.listing-details-heading-2 {
		font-size: 1rem;
	}

	.slide-4 {
		padding-right: 1rem;
		padding-left: 1rem;
		background-image: none;
		font-size: 1.25rem;
	}

	.slide-4.features-2 {
		padding-top: 1rem;
	}

	.slide-4.features-1 {
		padding-top: 1rem;
	}

	.property-slider-arrow-link-2 {
		bottom: 40px;
		width: 60px;
		height: 60px;
		margin-right: 20px;
		margin-left: 20px;
		border-width: 6px;
		background-color: hsla(0, 0%, 100%, 0.55);
		box-shadow: 9px 9px 30px 0 rgba(90, 90, 90, 0.45), inset 0 0 8px 0 hsla(0, 0%, 100%, 0.65);
	}

	.property-slider-arrow-link-2:hover {
		box-shadow: 9px 9px 30px 0 rgba(90, 90, 90, 0.45);
	}

	.property-slider-arrow-link-2.property-arrow-right {
		box-shadow: -9px 9px 30px 0 rgba(90, 90, 90, 0.45), inset 0 0 8px 0 hsla(0, 0%, 100%, 0.65);
	}

	.property-slider-arrow-link-2.property-arrow-right:hover {
		box-shadow: -9px 9px 30px 0 rgba(90, 90, 90, 0.45);
	}

	.property-spotlight-slide-2 {
		width: 49%;
		padding-right: 0.5rem;
		padding-left: 0.5rem;
		background-image: none;
		font-size: 1.25rem;
	}

	.listing-details-2 {
		font-size: 1rem;
	}

	.property-slider-widget-2 {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.quad-slide-card-wrapper {
		width: 48%;
		margin-bottom: 2rem;
	}

	.quad-set-wrapper {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.content-box-heading {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
	}

	.property-spotlight-image-link-2 {
		display: block;
		width: 50%;
	}

	.property-spotlight-image-link-2:hover {
		border-bottom-color: #dadada;
	}

	.property-duo-mask {
		width: 100%;
		margin-right: 20px;
		margin-left: 20px;
	}

	.property-spotlight-quad-slide {
		width: 100%;
		padding-right: 2rem;
		padding-left: 2rem;
		background-image: none;
		font-size: 1.25rem;
	}

	.duo-slider-arrow-link {
		bottom: 40px;
		width: 60px;
		height: 60px;
		margin-right: 20px;
		margin-left: 20px;
		border-width: 6px;
		background-color: hsla(0, 0%, 100%, 0.55);
		box-shadow: 9px 9px 30px 0 rgba(90, 90, 90, 0.45), inset 0 0 8px 0 hsla(0, 0%, 100%, 0.65);
	}

	.duo-slider-arrow-link:hover {
		box-shadow: 9px 9px 30px 0 rgba(90, 90, 90, 0.45);
	}

	.duo-slider-arrow-link.duo-arrow-right {
		box-shadow: -9px 9px 30px 0 rgba(90, 90, 90, 0.45), inset 0 0 8px 0 hsla(0, 0%, 100%, 0.65);
	}

	.duo-slider-arrow-link.duo-arrow-right:hover {
		box-shadow: -9px 9px 30px 0 rgba(90, 90, 90, 0.45);
	}

	.listing-column-wrapper.main {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.property-spotlight-mask-2 {
		width: 100%;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.arrow {
		width: 60px;
		height: 60px;
		margin-top: 40px;
		margin-right: 20px;
		margin-left: 20px;
		border-width: 6px;
		background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, 0.25)), to(hsla(0, 0%, 100%, 0.25)));
		background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.25), hsla(0, 0%, 100%, 0.25));
	}

	.arrow.multi-slider {
		top: -225px;
		right: 10px;
		margin-right: 10px;
		background-color: hsla(0, 0%, 100%, 0.55);
		background-image: none;
		box-shadow: -11px 7px 30px 0 rgba(90, 90, 90, 0.45), inset 0 0 8px 0 hsla(0, 0%, 100%, 0.65);
	}

	.arrow.multi-slider:hover {
		background-color: #bd571a;
		background-image: none;
		box-shadow: -11px 7px 30px 0 rgba(90, 90, 90, 0.45);
	}

	.arrow.left.multi-slider {
		left: 10px;
		top: -225px;
		margin-left: 10px;
		background-color: hsla(0, 0%, 100%, 0.55);
		background-image: none;
		box-shadow: 9px 9px 30px 0 rgba(90, 90, 90, 0.45), inset 0 0 8px 0 hsla(0, 0%, 100%, 0.65);
		color: #fff;
	}

	.arrow.left.multi-slider:hover {
		background-color: #bd571a;
		background-image: none;
		box-shadow: 9px 9px 30px 0 rgba(90, 90, 90, 0.45);
	}

	.property-quad-mask {
		width: 100%;
		margin-right: 20px;
		margin-left: 20px;
	}

	.quad-slider-arrow-link {
		bottom: 40px;
		width: 60px;
		height: 60px;
		margin-right: 20px;
		margin-left: 20px;
		border-width: 6px;
		background-color: hsla(0, 0%, 100%, 0.55);
		box-shadow: 9px 9px 30px 0 rgba(90, 90, 90, 0.45), inset 0 0 8px 0 hsla(0, 0%, 100%, 0.65);
	}

	.quad-slider-arrow-link:hover {
		box-shadow: 9px 9px 30px 0 rgba(90, 90, 90, 0.45);
	}

	.quad-slider-arrow-link.quad-arrow-right {
		box-shadow: -9px 9px 30px 0 rgba(90, 90, 90, 0.45), inset 0 0 8px 0 hsla(0, 0%, 100%, 0.65);
	}

	.quad-slider-arrow-link.quad-arrow-right:hover {
		box-shadow: -9px 9px 30px 0 rgba(90, 90, 90, 0.45);
	}

	.paragraph-text {
		width: 100%;
		padding-right: 0px;
		font-size: 1rem;
		line-height: 1.5rem;
	}

	.paragraph-text.about-us {
		font-size: 1.3rem;
		line-height: 2rem;
	}

	.about-us-div._2 {
		padding-top: 2rem;
		padding-bottom: 1.5rem;
	}

	.listing-status-text-2 {
		font-size: 1rem;
	}

	.spotlight-container {
		width: 50%;
		padding-right: 10px;
		padding-left: 0px;
	}

	.spotlight-container.fourth {
		display: block;
		width: 50%;
		margin-right: auto;
		margin-left: auto;
		padding-right: 0.5rem;
		float: left;
	}

	.spotlight-container.second {
		display: block;
		width: 50%;
		margin-right: auto;
		margin-left: auto;
		padding-right: 1rem;
		float: left;
	}

	.spotlight-container.first {
		display: block;
		width: 50%;
		margin-right: auto;
		margin-left: auto;
		padding-right: 0.5rem;
		float: left;
	}

	.spotlight-container.third {
		display: block;
		width: 50%;
		margin-right: auto;
		margin-left: auto;
		padding-right: 0.5rem;
		float: left;
	}

	.listing-detail-column-wrapper-2.trio-product-details {
		margin-bottom: 2rem;
	}

	.duo-slide-card-wrapper {
		width: 48%;
		margin-bottom: 2rem;
	}

	.icon-2 {
		color: #fff;
		font-size: 2.15rem;
	}

	.property-spotlight-duo-slide {
		width: 100%;
		padding-right: 2rem;
		padding-left: 2rem;
		background-image: none;
		font-size: 1.25rem;
	}

	.icon-search-2-column-wrap {
		position: fixed;
		right: -90px;
		top: 1.7rem;
		left: auto;
		bottom: auto;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 11.5rem;
		padding-top: 0.8rem;
		padding-bottom: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-align-self: auto;
		-ms-flex-item-align: auto;
		align-self: auto;
		text-align: center;
	}

	.main-slider-arrow-icon-2 {
		font-size: 1.8rem;
	}

	.basket-title {
		margin-top: 1.255rem;
	}

	.sign-in-icon.account-2 {
		min-height: 18px;
		min-width: 18px;
		margin-bottom: 0.35rem;
	}

	.basket-icon-new.wishlist {
		min-height: 18px;
		min-width: 18px;
	}

	.top-icon-wrapper {
		width: 100px;
		padding-top: 0rem;
	}

	.top-icon-wrapper.basket-2 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}

	.top-icon-wrapper.wishlist-2 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}

	.top-icon-wrapper.cart-mobile {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}

	.top-icon-wrapper.basket-2 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}

	.basket-div {
		width: 125px;
		height: 30px;
		padding-top: 0rem;
	}

	.account-dropdown {
		padding-top: 1.8rem;
		text-align: center;
	}

	.account-dropdown.w--open {
		text-align: center;
	}

	.account-dropdown-list {
		position: absolute;
		left: 0%;
		top: auto;
		right: 0%;
		bottom: 0%;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-top: 0.25rem;
		opacity: 0;
	}

	.account-dropdown-list.w--open {
		position: relative;
		z-index: 1000;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}

	.account-link {
		padding: 0.1rem 0.5rem;
		font-size: 0.7rem;
		line-height: 1.05rem;
		text-decoration: none;
	}

	.account-link.top {
		padding: 0.1rem 0.5rem;
		border-right-style: none;
		border-bottom-style: solid;
		font-size: 0.7rem;
		line-height: 1.05rem;
	}

	.dropdown {
		width: 100px;
		padding-top: 2.5px;
	}

	.link {
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.arrow-link {
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.property-slider-arrow-link-3 {
		bottom: 600px;
		width: 60px;
		height: 60px;
		margin-right: 1.75rem;
		margin-left: 1.75rem;
		border-width: 6px;
		box-shadow: none;
	}

	.property-slider-arrow-link-3:hover {
		box-shadow: none;
	}

	.property-slider-arrow-link-3.property-arrow-right {
		left: auto;
		top: 0%;
		right: 0%;
		bottom: 600px;
		margin-right: 1.75rem;
		box-shadow: none;
	}

	.property-slider-arrow-link-3.property-arrow-right:hover {
		box-shadow: none;
	}

	.cart-icon-link-wrapper-2 {
		width: 100%;
		-webkit-box-align: end;
		-webkit-align-items: flex-end;
		-ms-flex-align: end;
		align-items: flex-end;
	}

	.banner-arrow-2 {
		height: 40px;
		min-width: 158px;
		padding-left: 1.25rem;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		border-style: none;
		background-color: #e4c700;
	}

	.top-nav-div {
		display: none;
		height: 0px;
		opacity: 0;
	}

	.top-nav-wrapper {
		padding-left: 0.5rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.top-nav-link.stores {
		margin-right: 0.5rem;
		margin-left: 0.5rem;
	}

	.top-nav-link.support {
		margin-right: 0.5rem;
		margin-left: 0.5rem;
	}

	.top-nav-link.phone {
		margin-right: 0.5rem;
		margin-left: 0.5rem;
	}

	.top-nav-link.free-pickup-and-delivery {
		margin-right: 0.5rem;
		margin-left: 0.5rem;
	}

	.top-nav-link.credit-card {
		margin-right: 0.5rem;
		margin-left: 0.5rem;
	}

	.basket-amounts-div {
		margin-right: 0rem;
	}

	.wishlist-dropdown {
		text-align: center;
	}

	.wishlist-dropdown.w--open {
		text-align: center;
	}

	.icon-title-new {
		margin-bottom: 0.5rem;
	}

	.icon-title-new.account {
		margin-top: 0rem;
	}

	.basket-icon-2 {
		max-height: 22px;
		max-width: 22px;
		min-height: 18px;
		min-width: 18px;
	}

	.listing-details-heading-3 {
		padding-bottom: 0.25rem;
		font-size: 0.9rem;
	}

	.listing-details-heading-3.original-price {
		padding-bottom: 0.25rem;
		font-size: 0.9rem;
	}

	.listing-details-heading-3.now-price {
		padding-bottom: 0.25rem;
		font-size: 0.9rem;
	}

	.new-present-div {
		top: 224px;
		width: 50%;
	}

	.register-now-link {
		display: block;
		margin-top: 0.5rem;
		padding: 0.1rem 0.5rem;
		border-style: solid;
		border-width: 1px;
		border-color: hsla(0, 0%, 75%, 0.5);
		background-color: #f1f1f1;
		opacity: 0;
		color: #333;
		font-size: 0.7rem;
		line-height: 1.05rem;
		text-decoration: none;
		text-transform: none;
	}

	.register-now-link:hover {
		background-color: #fff;
	}

	.hidden-register-button-div {
		position: absolute;
		left: -4.1rem;
		top: 42.2031px;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 5rem;
		margin-left: 100px;
		padding-top: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.present-icon-wrapper-2 {
		min-height: 50px;
	}

	.present-text-wrapper-2 {
		min-height: 50px;
	}

	.link-2 {
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.stephanis-card-dropdown {
		line-height: 1rem;
	}

	.add-to-cart-text {
		padding-left: 3.75rem;
		background-position: 29px 50%;
	}

	.add-to-cart-text.add-to-cart {
		padding-left: 0.5rem;
	}

	.add-to-cart-text.pre-order {
		padding-left: 0.5rem;
	}

	.add-to-cart-text.pre-order-date {
		padding-left: 0.5rem;
	}

	.dropdown-toggle {
		padding-right: 1.5rem;
	}

	.language-arrow-icon {
		margin-right: 0.25rem;
	}

	.language-dropdown-list.w--open {
		right: 0rem;
	}

	.out-of-stock-text {
		padding-left: 1rem;
		background-image: none;
	}

	.two-part-button-toggle {
		width: 20%;
		margin-bottom: 1px;
		padding-top: 1em;
		padding-bottom: 1em;
		float: right;
		background-color: rgba(0, 0, 0, 0.15);
		background-image: url('../images/icon_arrow2_right_wht_50.svg');
		background-position: 50% 50%;
		background-size: 1em 1em;
		background-repeat: no-repeat;
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25), inset 1px 0 0 0 hsla(0, 0%, 100%, 0.25);
		color: transparent;
		font-size: 0.875rem;
		line-height: 1.875rem;
		text-align: center;
		cursor: pointer;
	}

	.left-nav-list-content-wrap {
		overflow: hidden;
		height: 0px;
	}

	.section-wrap.inside-content-section {
		padding-top: 14.4rem;
	}

	.section-wrap.inside-content-section {
		padding-top: 0rem;
	}

	.section-wrap.inside-content-section.level-1 {
		margin-top: 0px;
		padding-top: 0rem;
	}

	.main-content-column {
		padding-right: 0px;
		padding-left: 0px;
	}

	.inside-content-beckground {
		max-width: 100%;
		padding-top: 1.5rem;
		padding-bottom: 2rem;
	}

	.inside-content-beckground.level-1 {
		margin-top: 6rem;
		padding-top: 0rem;
	}

	.main-content-wrapper {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.main-content-wrapper.inside-left {
		padding-top: 1rem;
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.left-nav-list {
		margin-bottom: 1.5em;
	}

	.nav-link {
		display: block;
		width: 100%;
		height: auto;
		min-width: 100%;
		margin-right: 0px;
		margin-bottom: 1px;
		margin-left: 0px;
		padding: 0.75rem 1rem;
		float: left;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-bottom: 1px solid rgba(140, 205, 214, 0.1);
		background-color: transparent;
		box-shadow: none;
		color: #fff;
		line-height: 1.375rem;
		font-weight: 600;
		text-align: left;
		text-transform: capitalize;
	}

	.nav-link:hover {
		background-color: #0054a6;
		box-shadow: inset 4px 0 0 0 #00b9f2;
		color: #fff;
	}

	.nav-link.w--current {
		height: auto;
		margin-left: 0px;
		background-color: rgba(39, 127, 139, 0.15);
		box-shadow: none;
		color: #fff;
	}

	.nav-link.w--current:hover {
		background-color: rgba(131, 142, 153, 0.2);
		box-shadow: none;
	}

	.nav-link.mobile-list-back {
		background-color: rgba(0, 0, 0, 0.15);
		color: hsla(0, 0%, 100%, 0.55);
		cursor: pointer;
	}

	.nav-link.two-part-mobile {
		width: 80%;
		float: left;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}

	.two-part-button-list {
		position: absolute;
		left: 0px;
		top: 0px;
		z-index: 150;
		display: block;
		width: 100%;
		margin-left: 100%;
		padding-bottom: 100vh;
		background-color: #28313b;
	}

	.two-part-button-wrap {
		position: static;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		max-width: none;
		float: none;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.two-part-drop-list-back-arrow {
		display: inline-block;
		min-width: 0.75em;
		margin-right: 0.25em;
		background-image: url('../images/icon_arrow2_left_wht_50.svg');
		background-position: 0px 55%;
		background-size: 12px 12px;
		background-repeat: no-repeat;
		color: transparent;
		text-align: center;
	}

	.main-content-container {
		padding-top: 1rem;
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.main-content-container.left-right {
		padding-top: 1rem;
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.inside-content-beckground-2 {
		max-width: 100%;
		padding-top: 1.5rem;
		padding-bottom: 2rem;
	}

	.right-sidebar-column {
		margin-top: 1.5rem;
		padding-top: 1.5rem;
		border-top: 2px solid rgba(0, 84, 166, 0.15);
	}

	.right-sidebar-column.inside-right {
		padding-right: 0px;
		padding-left: 0px;
	}

	.sidebar-section-wrap {
		margin-left: 0em;
	}

	.container-3 {
		max-width: 100%;
		padding-right: 20px;
		padding-left: 20px;
	}

	.container-3.inside-right {
		padding: 1rem 1.5rem 1.5px;
	}

	.inside-page-content-wrap-no-sidebars {
		margin-right: 0%;
		margin-left: 0%;
	}

	.tag-div {
		border-right-style: none;
		border-bottom-style: solid;
		border-left-style: none;
	}

	.category-div-wrapper {
		position: relative;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 30%;
		margin-bottom: 1rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: end;
		-webkit-align-items: flex-end;
		-ms-flex-align: end;
		align-items: flex-end;
		border-style: solid;
		border-width: 1px;
		border-color: hsla(194.1322314049587, 0%, 72%, 0.5);
	}

	.search-wrapper {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.property-type-div {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.area-min-input {
		width: 40%;
	}

	.property-type-list-div-10.area {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-style: none solid solid;
		border-width: 1px;
		border-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-10.brand-subtype {
		display: block;
		overflow: auto;
		height: 150px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		border-top: 1px none #d8d8d8;
		border-right: 1px solid #d8d8d8;
		border-left: 1px solid #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-10.municipality {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		border-style: none solid;
		border-width: 1px;
		border-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-10.district {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		border-style: none solid;
		border-width: 1px;
		border-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-10.price-range {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-style: none solid;
		border-width: 1px;
		border-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-10.price {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		border-style: none solid;
		border-width: 1px;
		border-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-10.property-id {
		border-right: 1px solid #d8d8d8;
		border-left: 1px solid #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-10.technology {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		border-style: none solid;
		border-width: 1px;
		border-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-10.status {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		border-style: none solid;
		border-width: 1px;
		border-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-10.parish {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		border-style: none solid;
		border-width: 1px;
		border-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-10.pool {
		border-style: none solid;
		border-width: 1px;
		border-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-10.size {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		border-style: none solid;
		border-right-width: 1px;
		border-right-color: #d8d8d8;
		border-bottom-width: 1px;
		border-bottom-color: #d8d8d8;
		border-left-width: 1px;
		border-left-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-10.color {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		border-style: none solid solid;
		border-width: 1px;
		border-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-10.size-subtype {
		display: block;
		overflow: auto;
		height: 150px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		border-top: 1px none #d8d8d8;
		border-right: 1px solid #d8d8d8;
		border-left: 1px solid #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.vertical-search-form {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.area-maximum-input {
		width: 40%;
	}

	.beds-div {
		width: 100%;
	}

	.price-range-div {
		width: 100%;
	}

	.property-subtype-div {
		width: 100%;
	}

	.municipality-div {
		width: 100%;
	}

	.clear-all-link {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-right: 0.25rem;
	}

	.single-field.third {
		width: 25%;
	}

	.single-field.property {
		width: 25%;
	}

	.single-field.name {
		width: 25%;
	}

	.single-field.half {
		width: 25%;
	}

	.parish-div {
		width: 100%;
	}

	.vertical-search-form-div {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		box-shadow: 0 0 80px 0 rgba(0, 0, 0, 0.1);
	}

	.baths-div {
		width: 100%;
	}

	.area-range-wrapper {
		width: 60%;
	}

	.status-div {
		width: 100%;
	}

	.price-range-wrapper {
		width: 60%;
	}

	.clear-all-div {
		width: 40%;
		padding: 1rem;
	}

	.property-id-div {
		width: 100%;
	}

	.search-filter-title {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 60%;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.min-and-max-vertical-search-div {
		width: 40%;
		padding-right: 1rem;
	}

	.price-min-input {
		width: 40%;
	}

	.search-heading-div {
		width: 1000%;
		padding-top: 0rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
	}

	.search-link {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-left: 0.25rem;
	}

	.district-div {
		width: 100%;
	}

	.property-type-field-label {
		font-size: 0.85rem;
	}

	.label-section-title {
		border-right: 1px solid #d8d8d8;
		background-color: #fff;
		background-position: 97.5% 50%;
		box-shadow: inset 0 1px 0 0 #d8d8d8, inset 0 -1px 0 0 #d8d8d8;
	}

	.label-section-title:hover {
		background-image: url('../images/search-icons-minus.svg');
		background-size: 15px;
		color: #fff;
	}

	.area-range-div {
		width: 100%;
	}

	.pool-div {
		width: 100%;
	}

	.price-max-input {
		width: 40%;
	}

	.search-card-wrapper {
		width: 100%;
	}

	.top-filtering-div {
		height: auto;
		margin-top: 0.25rem;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.filter-options-dropdown {
		margin-left: 0px;
	}

	.dropdown-toggle-2 {
		padding-right: 30px;
	}

	.side-filter-buttons {
		margin-top: 0px;
		padding-right: 0px;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.filter-products-link {
		margin-left: 0rem;
	}

	.property-type-list-div-brand.area {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-style: none solid solid;
		border-width: 1px;
		border-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-brand.brand-subtype {
		display: block;
		overflow: auto;
		height: 150px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		border-top: 1px none #d8d8d8;
		border-right: 1px solid #d8d8d8;
		border-left: 1px solid #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-brand.municipality {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		border-style: none solid;
		border-width: 1px;
		border-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-brand.district {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		border-style: none solid;
		border-width: 1px;
		border-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-brand.price-range {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-style: none solid;
		border-width: 1px;
		border-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-brand.price {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		border-style: none solid;
		border-width: 1px;
		border-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-brand.property-id {
		border-right: 1px solid #d8d8d8;
		border-left: 1px solid #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-brand.technology {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		border-style: none solid;
		border-width: 1px;
		border-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-brand.status {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		border-style: none solid;
		border-width: 1px;
		border-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-brand.parish {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		border-style: none solid;
		border-width: 1px;
		border-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-brand.pool {
		border-style: none solid;
		border-width: 1px;
		border-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-brand.size {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		border-style: none solid;
		border-right-width: 1px;
		border-right-color: #d8d8d8;
		border-bottom-width: 1px;
		border-bottom-color: #d8d8d8;
		border-left-width: 1px;
		border-left-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-brand.color {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		border-style: none solid solid;
		border-width: 1px;
		border-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-brand.size-subtype {
		display: block;
		overflow: auto;
		height: 150px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		border-top: 1px none #d8d8d8;
		border-right: 1px solid #d8d8d8;
		border-left: 1px solid #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-size.area {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-style: none solid solid;
		border-width: 1px;
		border-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-size.brand-subtype {
		display: block;
		overflow: auto;
		height: 150px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		border-top: 1px none #d8d8d8;
		border-right: 1px solid #d8d8d8;
		border-left: 1px solid #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-size.municipality {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		border-style: none solid;
		border-width: 1px;
		border-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-size.district {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		border-style: none solid;
		border-width: 1px;
		border-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-size.price-range {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-style: none solid;
		border-width: 1px;
		border-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-size.price {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		border-style: none solid;
		border-width: 1px;
		border-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-size.property-id {
		border-right: 1px solid #d8d8d8;
		border-left: 1px solid #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-size.technology {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		border-style: none solid;
		border-width: 1px;
		border-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-size.status {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		border-style: none solid;
		border-width: 1px;
		border-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-size.parish {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		border-style: none solid;
		border-width: 1px;
		border-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-size.pool {
		border-style: none solid;
		border-width: 1px;
		border-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-size.size {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		border-style: none solid;
		border-right-width: 1px;
		border-right-color: #d8d8d8;
		border-bottom-width: 1px;
		border-bottom-color: #d8d8d8;
		border-left-width: 1px;
		border-left-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-size.color {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		border-style: none solid solid;
		border-width: 1px;
		border-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-size.size-subtype {
		display: block;
		overflow: auto;
		height: 150px;
		padding-left: 1rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		border-top: 1px none #d8d8d8;
		border-right: 1px solid #d8d8d8;
		border-left: 1px solid #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-size.size-subtype {
		display: block;
		overflow: auto;
		height: 150px;
		padding-left: 1rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		border-top: 1px none #d8d8d8;
		border-right: 1px solid #d8d8d8;
		border-left: 1px solid #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-tech.area {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-style: none solid solid;
		border-width: 1px;
		border-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-tech.brand-subtype {
		display: block;
		overflow: auto;
		height: 150px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		border-top: 1px none #d8d8d8;
		border-right: 1px solid #d8d8d8;
		border-left: 1px solid #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-tech.municipality {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		border-style: none solid;
		border-width: 1px;
		border-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-tech.district {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		border-style: none solid;
		border-width: 1px;
		border-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-tech.price-range {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-style: none solid;
		border-width: 1px;
		border-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-tech.price {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		border-style: none solid;
		border-width: 1px;
		border-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-tech.property-id {
		border-right: 1px solid #d8d8d8;
		border-left: 1px solid #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-tech.technology {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		border-style: none solid;
		border-width: 1px;
		border-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-tech.status {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		border-style: none solid;
		border-width: 1px;
		border-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-tech.parish {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		border-style: none solid;
		border-width: 1px;
		border-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-tech.pool {
		border-style: none solid;
		border-width: 1px;
		border-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-tech.size {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		border-style: none solid;
		border-right-width: 1px;
		border-right-color: #d8d8d8;
		border-bottom-width: 1px;
		border-bottom-color: #d8d8d8;
		border-left-width: 1px;
		border-left-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-tech.color {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		border-style: none solid solid;
		border-width: 1px;
		border-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-tech.size-subtype {
		display: block;
		overflow: auto;
		height: 150px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		border-top: 1px none #d8d8d8;
		border-right: 1px solid #d8d8d8;
		border-left: 1px solid #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-color.area {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-style: none solid solid;
		border-width: 1px;
		border-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-color.brand-subtype {
		display: block;
		overflow: auto;
		height: 150px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		border-top: 1px none #d8d8d8;
		border-right: 1px solid #d8d8d8;
		border-left: 1px solid #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-color.municipality {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		border-style: none solid;
		border-width: 1px;
		border-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-color.district {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		border-style: none solid;
		border-width: 1px;
		border-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-color.price-range {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-style: none solid;
		border-width: 1px;
		border-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-color.price {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		border-style: none solid;
		border-width: 1px;
		border-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-color.property-id {
		border-right: 1px solid #d8d8d8;
		border-left: 1px solid #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-color.technology {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		border-style: none solid;
		border-width: 1px;
		border-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-color.status {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		border-style: none solid;
		border-width: 1px;
		border-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-color.parish {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		border-style: none solid;
		border-width: 1px;
		border-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-color.pool {
		border-style: none solid;
		border-width: 1px;
		border-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-color.size {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		border-style: none solid;
		border-right-width: 1px;
		border-right-color: #d8d8d8;
		border-bottom-width: 1px;
		border-bottom-color: #d8d8d8;
		border-left-width: 1px;
		border-left-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-color.color {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		border-style: none solid solid;
		border-width: 1px;
		border-color: #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.property-type-list-div-color.size-subtype {
		display: block;
		overflow: auto;
		height: 150px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		border-top: 1px none #d8d8d8;
		border-right: 1px solid #d8d8d8;
		border-left: 1px solid #d8d8d8;
		background-color: hsla(0, 0%, 91%, 0.35);
		background-image: none;
	}

	.icon-4 {
		margin-right: 10px;
	}

	.hidden-desktop-tablet-nav-link {
		display: block;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-bottom: 1px solid rgba(93, 63, 58, 0.35);
		background-color: #fff;
	}

	.product-details-wrapper {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.product-slider-column {
		width: 100%;
	}

	.product-details-column {
		width: 100%;
	}

	.mini-view-wrapper {
		margin-bottom: 1rem;
		border-bottom: 1px solid hsla(194.1322314049587, 0%, 72%, 0.5);
	}

	.top-icon-mobile-div {
		display: none;
		width: 100%;
		height: 60px;
		max-width: 600px;
		min-width: 500px;
		margin-top: 0.25rem;
		margin-bottom: 1rem;
		padding-right: 0rem;
		padding-left: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		grid-auto-columns: 1fr;
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		-ms-grid-columns: 1fr 1fr;
		grid-template-columns: 1fr 1fr;
		-ms-grid-rows: auto auto auto;
		grid-template-rows: auto auto auto;
	}

	.cart-amounts-div {
		margin-right: 0rem;
	}

	.text-cart-button-div {
		position: relative;
		left: -1.5rem;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-top: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.basket-icon-mobile-cart {
		max-height: 22px;
		max-width: 22px;
		min-height: 18px;
		min-width: 18px;
	}

	.spec-tab {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		border-style: solid;
		border-width: 1px;
		border-color: hsla(194.1322314049587, 0%, 72%, 0.5);
	}

	.tab-link-tab-1.w--current {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		height: 35px;
		padding-left: 1rem;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-top-style: none;
		border-right-style: none;
		border-left-style: none;
	}

	.tab-link-inactive {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		height: 36px;
		padding-left: 1rem;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-right-style: none;
	}

	.tab-content-wrapper {
		border-top-style: none;
		border-bottom-style: none;
	}

	.stock-location-text {
		max-width: 65%;
	}

	.tabs-menu-div {
		width: 25%;
	}

	.tabs-content-div {
		width: 75%;
	}

	.basket-amounts-div-2 {
		width: 5rem;
		margin-right: 0rem;
		padding-top: 0rem;
	}

	.nav-item-with-icon {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-bottom: 1px solid rgba(93, 63, 58, 0.15);
	}

	.mobile-icon-text-holder {
		display: inline-block;
		float: none;
	}

	.mobile-icon-holder {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 25px;
		height: 20px;
		margin-left: 20px;
		float: none;
	}

	.basket-amounts-div-3 {
		margin-right: 0rem;
	}

	.icon-search-2-column-wrap-2 {
		display: none;
		width: 100%;
		padding-top: 0rem;
		padding-bottom: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.hidden-register-button-div-2 {
		position: relative;
		left: -1.5rem;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-top: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.account-link-2 {
		padding: 0.1rem 0.5rem;
		font-size: 0.7rem;
		line-height: 1.05rem;
		text-decoration: none;
	}

	.account-link-2.top {
		padding: 0.1rem 0.5rem;
		border-right-style: none;
		border-bottom-style: solid;
		font-size: 0.7rem;
		line-height: 1.05rem;
	}

	.mobile-menu-arrow-2 {
		display: block;
		width: 15%;
		border-left: 1px solid rgba(93, 63, 58, 0.35);
		background-image: url('../images/arrow-grey-icons-06.svg');
		background-position: 50% 50%;
		background-size: 15px 15px;
		background-repeat: no-repeat;
		-webkit-transition: all 450ms ease;
		transition: all 450ms ease;
		color: #000;
	}

	.mobile-menu-arrow-2:hover {
		background-color: #f1f1f1;
		opacity: 1;
	}

	.icon-search-2-column-wrap-3 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		padding-top: 0rem;
		padding-bottom: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.top-half-div-2 {
		padding-top: 1rem;
		padding-bottom: 1rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.12);
	}

	.main-nav-link-2 {
		margin-top: 0rem;
		padding: 1rem;
		border-color: rgba(93, 63, 58, 0.35);
		border-top-style: none;
		border-right-style: none;
		border-left-style: none;
		color: #00283b;
		font-size: 1rem;
		line-height: 1.5rem;
		font-weight: 600;
		text-align: left;
	}

	.main-nav-link-2:hover {
		border-style: none none solid;
		border-color: rgba(93, 63, 58, 0.35);
		background-color: hsla(0, 0%, 100%, 0.7);
		line-height: 1.5rem;
	}

	.main-nav-link-2.w--current {
		border-bottom-color: rgba(93, 63, 58, 0.35);
		background-color: hsla(0, 0%, 100%, 0.7);
	}

	.main-nav-link-2.mobile-duplicate {
		display: block;
		width: 85%;
		margin-right: 0px;
		margin-left: 0px;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-bottom-style: none;
		-webkit-transition-property: all;
		transition-property: all;
		-webkit-transition-duration: 450ms;
		transition-duration: 450ms;
		color: #333;
	}

	.main-nav-link-2.mobile-duplicate:hover {
		border-style: none;
		border-bottom-color: transparent;
		background-color: #333;
		color: #e4c700;
	}

	.main-nav-link-2.hidden {
		color: hsla(199.32203389830508, 42.22%, 36.09%, 1);
	}

	.main-nav-link-2.full {
		height: 3.5rem;
		margin-right: 0px;
		margin-left: 0px;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		border-bottom-style: solid;
		border-bottom-width: 1px;
		background-color: #fff;
		-webkit-transition-duration: 450ms;
		transition-duration: 450ms;
		color: #333;
		font-size: 1rem;
	}

	.main-nav-link-2.full:hover {
		height: 3.5rem;
		border-bottom-style: solid;
		border-bottom-width: 1px;
		background-color: #333;
		color: #e4c700;
	}

	.main-nav-link-2.full {
		height: 2.5rem;
		margin-right: 0px;
		margin-left: 0px;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		border-bottom-style: solid;
		border-bottom-width: 1px;
		background-color: #fff;
		-webkit-transition-duration: 450ms;
		transition-duration: 450ms;
		color: #333;
		font-size: 1rem;
	}

	.main-nav-link-2.full:hover {
		height: 2.5rem;
		border-bottom-style: solid;
		border-bottom-width: 1px;
		background-color: #333;
		color: #e4c700;
	}

	.main-nav-link-2.full.withicon {
		display: block;
		border-bottom-style: none;
		background-color: #f0f0f0;
	}

	.main-nav-link-2.mobile-duplicate {
		display: block;
		width: 85%;
		margin-right: 0px;
		margin-left: 0px;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-bottom-style: none;
		-webkit-transition-property: all;
		transition-property: all;
		-webkit-transition-duration: 450ms;
		transition-duration: 450ms;
		color: #333;
	}

	.main-nav-link-2.mobile-duplicate:hover {
		border-style: none;
		border-bottom-color: transparent;
		background-color: #333;
		color: #e4c700;
	}

	.navbar-2 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-justify-content: space-around;
		-ms-flex-pack: distribute;
		justify-content: space-around;
	}

	.search-column-2 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 50%;
		margin-top: 0rem;
		padding-right: 0rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		-ms-flex-order: 1;
		order: 1;
	}

	.menu-button-2 {
		position: absolute;
		left: auto;
		top: 0%;
		right: 0%;
		bottom: auto;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 68px;
		height: 80px;
		padding-right: 10px;
		padding-left: 10px;
		float: right;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-transition: background-color 300ms ease;
		transition: background-color 300ms ease;
		color: #fff;
	}

	.menu-button-2:hover {
		background-color: #f1f1f1;
		color: #ddd;
	}

	.menu-button-2.w--open {
		background-color: transparent;
	}

	.search-bar-form-2 {
		width: 380px;
		border-top: 2px none #e4c700;
		border-bottom: 1px solid hsla(0, 0%, 75%, 0.5);
	}

	.mobile-menu-icon-2 {
		-webkit-transition: all 450ms ease;
		transition: all 450ms ease;
		color: #000;
		font-size: 2rem;
		text-align: center;
	}

	.mobile-menu-icon-2:hover {
		color: #e4c700;
	}

	.nav-menu-2 {
		z-index: 100000000000;
		display: block;
		width: 75%;
		margin-top: 227px;
		border-top: 4px solid #bebebe;
		border-left: 1px solid #bebebe;
		background-color: #f1f1f1;
		text-align: left;
	}

	.search-input-hidden-2 {
		display: block;
		height: 40px;
		margin-left: 0rem;
		padding-left: 1rem;
		border-bottom-style: none;
		opacity: 1;
	}

	.brand-column-2 {
		width: 100%;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-ordinal-group: 0;
		-webkit-order: -1;
		-ms-flex-order: -1;
		order: -1;
	}

	.search-heading-div-2 {
		width: 1000%;
		padding-top: 0rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
	}

	.search-filter-title-2 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 85%;
		padding-left: 0.35rem;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.label-section-title-2 {
		border-right: 1px solid #d8d8d8;
		background-color: #fff;
		background-position: 97% 50%;
		box-shadow: inset 0 1px 0 0 #d8d8d8, inset 0 -1px 0 0 #d8d8d8;
	}

	.label-section-title-2:hover {
		background-image: url('../images/search-icons-minus.svg');
		background-size: 15px;
		color: #fff;
	}

	.label-section-title-2.plus-sign {
		background-image: url('../images/search-icons-plus-grey.svg');
		background-position: 97% 50%;
		background-size: 15px 15px;
		background-repeat: no-repeat;
	}

	.div-block-6 {
		position: absolute;
		top: 35px;
		right: 100px;
		display: block;
		width: 30px;
		height: 30px;
		margin-top: 0px;
		margin-left: 0px;
		padding-top: 0px;
		padding-right: 0px;
	}

	.dropdown-list-2 {
		right: 6px;
	}

	.pagination-div {
		padding-top: 0.5rem;
	}

	.add-to-cart-text-2 {
		padding-left: 3.75rem;
		background-position: 29px 50%;
	}

	.add-to-cart-text-2.pre-order {
		padding-left: 0.5rem;
	}

	.add-to-cart-text-2.add-to-cart {
		padding-left: 0.5rem;
	}

	.add-to-cart-text-2.pre-order-date {
		padding-left: 0.5rem;
	}

	.cart-icon-link-wrapper-3 {
		-webkit-box-align: end;
		-webkit-align-items: flex-end;
		-ms-flex-align: end;
		align-items: flex-end;
	}

	.new-present-div-2 {
		top: 224px;
	}

	.property-spotlight-image-link-3 {
		display: block;
	}

	.property-spotlight-image-link-3:hover {
		border-bottom-color: #dadada;
	}

	.present-icon-wrapper-3 {
		min-height: 50px;
	}

	.property-slider-arrow-link-4 {
		bottom: 600px;
		width: 60px;
		height: 60px;
		margin-right: 1.75rem;
		margin-left: 1.75rem;
		border-width: 6px;
		box-shadow: none;
	}

	.property-slider-arrow-link-4:hover {
		box-shadow: none;
	}

	.property-slider-arrow-link-4.property-arrow-right {
		left: auto;
		top: 0%;
		right: 0%;
		bottom: 600px;
		margin-right: 1.75rem;
		box-shadow: none;
	}

	.property-slider-arrow-link-4.property-arrow-right:hover {
		box-shadow: none;
	}

	.icon-5 {
		color: #8b8b8b;
		font-size: 2.15rem;
	}

	.out-of-stock-text-2 {
		padding-left: 1rem;
		background-image: none;
	}

	.menu-button-3 {
		position: absolute;
		left: auto;
		top: 0%;
		right: 0%;
		bottom: auto;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 68px;
		height: 80px;
		margin-top: 15px;
		padding-top: 20px;
		padding-right: 10px;
		padding-left: 10px;
		float: right;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-transition: background-color 300ms ease;
		transition: background-color 300ms ease;
		color: #fff;
	}

	.menu-button-3:hover {
		background-color: #f1f1f1;
		color: #ddd;
	}

	.menu-button-3.w--open {
		background-color: transparent;
	}

	.search-input-hidden-3 {
		display: block;
		height: 40px;
		margin-left: 0rem;
		padding-left: 1rem;
		border-bottom-style: none;
		opacity: 1;
	}

	.mobile-menu-icon-3 {
		-webkit-transition: all 450ms ease;
		transition: all 450ms ease;
		color: #000;
		font-size: 2rem;
		text-align: center;
	}

	.mobile-menu-icon-3:hover {
		color: #e4c700;
	}

	.cart-amounts-div-2 {
		margin-right: 0rem;
	}

	.nav-menu-3 {
		z-index: 100000000000;
		display: block;
		overflow: auto;
		width: 100%;
		height: 72vh;
		margin-top: 0px;
		border-top: 4px solid #bebebe;
		border-left: 1px solid #bebebe;
		background-color: #f1f1f1;
		text-align: left;
	}

	#products_module.responsive.cms_entity .product-title {
		margin-left: 0px;
	}
}

@media (max-width: 767px) {
	.header-section {
		left: 0%;
		top: 0%;
		right: 0%;
		bottom: auto;
		padding-top: 1px;
	}

	.top-icon-div {
		position: absolute;
		left: 0%;
		top: 0%;
		right: auto;
		bottom: 0%;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: 5.5rem;
		max-width: 160px;
		min-width: auto;
		margin-top: 0rem;
		margin-bottom: 0rem;
		padding-bottom: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.top-icon-link-block {
		margin-right: 0.75rem;
	}

	.top-icon-link-block.first-icon-link.header {
		width: 28px;
		height: 28px;
		margin-right: 0rem;
	}

	.top-icon-link-block.header {
		width: 28px;
		height: 28px;
		margin-right: 0rem;
		margin-left: 0rem;
	}

	.top-icon-link-block.header.phone {
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.top-icon-link-block.header.stores {
		width: 50px;
	}

	.top-icon-link-block.header.mobile-cart {
		width: 28px;
		margin-right: 0.1rem;
	}

	.main-nav-link.mobile-duplicate {
		width: 88%;
	}

	.main-nav-link.full.withicon {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}

	.nav-menu {
		overflow: auto;
		width: 100%;
		height: 72vh;
		margin-top: 0px;
	}

	.brand {
		width: 330px;
		max-width: 275px;
		min-height: 54px;
		min-width: auto;
		margin-top: 0rem;
		margin-bottom: 0.5rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.brand.w--current {
		width: 275px;
	}

	.container {
		max-width: 767px;
	}

	.container.nav-container {
		max-width: 728px;
	}

	.container.nav-container {
		max-width: 728px;
	}

	.menu-button {
		height: 70px;
		margin-top: 0rem;
		margin-right: 0rem;
		padding-top: 1.4rem;
		padding-bottom: 2px;
		left: 20px;
	}

	.menu-button:hover {
		background-color: transparent;
	}

	.menu-button.w--open {
		margin-top: 0rem;
		margin-right: 0rem;
		padding-top: 1.4rem;
	}

	.stephanis-logo {
		min-width: 275px;
	}

	.mobile-menu-arrow {
		background-size: 16px 16px;
	}

	.mobile-menu-arrow:hover {
		background-size: 16px 16px;
	}

	.search-input-hidden {
		height: 35px;
	}

	.slider-header {
		padding-top: 1px;
		padding-right: 10px;
		padding-left: 8px;
		font-size: 26px;
		line-height: 32px;
	}

	.slide-text-wrap {
		max-width: 85%;
	}

	.slider-cta-link {
		position: static;
		margin-top: 10%;
		padding-left: 26px;
		background-size: 18px 18px;
		font-size: 14px;
	}

	.slider-nav {
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.slider-image-div {
		top: 20px;
		right: 0px;
		width: 260px;
	}

	.slider-widget {
		height: 300px;
	}

	.slider-header-emphasis {
		font-size: 26px;
	}

	.slider-row {
		top: 16px;
		padding-right: 10px;
		padding-left: 10px;
	}

	.slider-paragraph {
		display: none;
		font-size: 14px;
		line-height: 20px;
	}

	.slider-section {
		padding-top: 111px;
	}

	.top-telephone-div {
		width: 85px;
	}

	.telephone-number {
		display: none;
		padding-left: 0.5rem;
	}

	.quicklink-icon-div-wrapper {
		width: 50%;
		padding-right: 1rem;
		padding-left: 1rem;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.quicklink-icon-div-wrapper.repeat {
		width: 50%;
	}

	.small-cb-div-wrapper {
		width: 50%;
	}

	.four-link-div-wrapper {
		margin-top: 0rem;
	}

	.property-spotlight-wrapper {
		width: 100%;
		padding-right: 0px;
	}

	.listing-details-column {
		width: 100%;
	}

	.listing-details-column.large-now-price {
		padding-top: 0.5rem;
	}

	.listing-details-column.large-stephanis-card-price {
		padding-top: 0.5rem;
	}

	.listing-details-column.large-now-price-product-details {
		width: 48%;
		margin-right: 0rem;
		margin-bottom: 1.5rem;
		padding-top: 0.5rem;
	}

	.listing-details-column.large-stephanis-card-price-product-details {
		width: 48%;
		margin-right: 0rem;
		margin-bottom: 1.5rem;
		padding-top: 0.5rem;
	}

	.listing-details-heading {
		padding-bottom: 0rem;
	}

	.listing-details-heading.now-price {
		font-size: 1.175rem;
	}

	.listing-details-heading.large-now-price {
		padding-bottom: 0rem;
	}

	.listing-details-heading.large-now-price-product-details {
		padding-bottom: 0rem;
	}

	.status-bar-div {
		height: 33px;
	}

	.property-slider-arrow-link {
		bottom: 610px;
		margin-right: 20px;
		margin-left: 20px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		border-width: 5px;
		box-shadow: inset 0 0 8px 0 rgba(96, 96, 96, 0.3);
	}

	.property-slider-arrow-link.property-arrow-right {
		bottom: 610px;
		margin-left: 1.75rem;
		box-shadow: inset 0 0 8px 0 rgba(96, 96, 96, 0.3);
	}

	.property-slider-arrow-link.left-arrow {
		margin-right: 1.75rem;
		margin-left: 1.75rem;
	}

	.property-spotlight-slide {
		width: 100%;
	}

	.property-slider-widget {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}

	.property-spotlight-image-link {
		height: 375px;
	}

	.property-spotlight-image-link._4 {
		background-size: contain;
	}

	.property-spotlight-mask {
		width: 100%;
	}

	.listing-price {
		width: 100%;
	}

	.listing-status.for-sale {
		margin-top: -1rem;
	}

	.listing-status.sold {
		margin-top: -1rem;
	}

	.listing-status.pending {
		margin-top: -1rem;
	}

	.listing-status.for-sale {
		margin-top: -1rem;
	}

	.listing-status.sold {
		margin-top: -1rem;
	}

	.listing-status.pending {
		margin-top: -1rem;
	}

	.icon {
		color: #8b8b8b;
		font-size: 2.05rem;
	}

	.note-paragraph {
		width: 80%;
	}

	.present-div {
		top: 200px;
	}

	.ribbon-banner {
		min-height: 150px;
	}

	.four-link-div-wrapper-copy {
		margin-top: 0rem;
	}

	.shop-cat-link-div {
		height: 175px;
	}

	.newsletter-form {
		width: 350px;
	}

	.newsletter-submit-button.search-filter-button {
		min-width: 82px;
		font-size: 0.6rem;
	}

	.list-link-div,
	.list-link-div.div-2,
	.list-link-div.div-3 {
		width: 50%;
	}

	.grip-logo-link {
		margin-bottom: 0rem;
		float: none;
	}

	.footer-signoff-links.last {
		margin-right: 0rem;
	}

	.footer-copyright {
		float: none;
	}

	.column-3 {
		overflow: hidden;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.column-2 {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.footer-signoff-link-wrap {
		display: inline-block;
		float: none;
		text-align: center;
	}

	.acrobat-reader-link {
		margin-right: 0rem;
	}

	.search-link-icon {
		width: 35px;
		height: 35px;
		min-height: 35px;
		min-width: 35px;
		background-size: 22px 22px;
	}

	.top-half-div {
		padding-bottom: 1.25rem;
	}

	.search-column {
		width: 350px;
		margin-top: 0.2rem;
	}

	.brand-column {
		position: absolute;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		padding-bottom: 0rem;
	}

	.icon-column {
		width: 100%;
		max-width: 160px;
	}

	.cb-left-arrow:hover {
		border-left-width: 4px;
	}

	.cb-rightt-arrow {
		border-right-width: 4px;
	}

	.about-us-content-wrap {
		display: none;
		padding-top: 2rem;
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.multi-slider-container {
		width: 100%;
	}

	.property-spotlight-wrapper-2 {
		width: 100%;
		padding-right: 0px;
	}

	.listing-details-column-2 {
		width: 100%;
	}

	.slide-4.features-2 {
		padding: 0rem;
	}

	.slide-4.features-1 {
		padding: 0rem;
	}

	.status-bar-div-2 {
		height: 33px;
	}

	.property-slider-arrow-link-2 {
		width: 65px;
		height: 65px;
		margin-right: 20px;
		margin-left: 20px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		border-width: 5px;
		background-color: rgba(96, 96, 96, 0.5);
		box-shadow: 9px 9px 30px 0 rgba(90, 90, 90, 0.45), inset 0 0 8px 0 rgba(96, 96, 96, 0.3);
	}

	.property-slider-arrow-link-2.property-arrow-right {
		box-shadow: -9px 9px 30px 0 rgba(90, 90, 90, 0.45), inset 0 0 8px 0 rgba(96, 96, 96, 0.3);
	}

	.property-spotlight-slide-2 {
		width: 100%;
		margin-right: 0%;
		margin-left: 0%;
	}

	.multi-slider {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}

	.property-slider-widget-2 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}

	.quad-slide-card-wrapper {
		width: 100%;
		padding-right: 0px;
	}

	.property-duo-mask {
		width: 100%;
	}

	.property-spotlight-quad-slide {
		width: 100%;
	}

	.duo-slider-arrow-link {
		width: 65px;
		height: 65px;
		margin-right: 20px;
		margin-left: 20px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		border-width: 5px;
		background-color: rgba(96, 96, 96, 0.5);
		box-shadow: 9px 9px 30px 0 rgba(90, 90, 90, 0.45), inset 0 0 8px 0 rgba(96, 96, 96, 0.3);
	}

	.duo-slider-arrow-link.duo-arrow-right {
		box-shadow: -9px 9px 30px 0 rgba(90, 90, 90, 0.45), inset 0 0 8px 0 rgba(96, 96, 96, 0.3);
	}

	.listing-column-wrapper.main {
		margin-top: 2rem;
		margin-bottom: 2rem;
	}

	.property-spotlight-mask-2 {
		width: 100%;
	}

	.arrow {
		top: 40px;
		width: 65px;
		height: 65px;
		margin-right: 20px;
		margin-left: 20px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		border-width: 5px;
		background-image: -webkit-gradient(linear, left top, left bottom, from(#233d7a), to(#233d7a)), -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, 0.25)), to(hsla(0, 0%, 100%, 0.25)));
		background-image: linear-gradient(180deg, #233d7a, #233d7a), linear-gradient(180deg, hsla(0, 0%, 100%, 0.25), hsla(0, 0%, 100%, 0.25));
	}

	.arrow.multi-slider {
		top: -240px;
		background-color: rgba(96, 96, 96, 0.5);
		box-shadow: -11px 7px 30px 0 rgba(90, 90, 90, 0.45), inset 0 0 8px 0 rgba(96, 96, 96, 0.3);
	}

	.arrow.left {
		margin-left: 10px;
	}

	.arrow.left.multi-slider {
		top: -240px;
		background-color: rgba(96, 96, 96, 0.5);
		box-shadow: 9px 9px 30px 0 rgba(90, 90, 90, 0.45), inset 0 0 8px 0 rgba(96, 96, 96, 0.3);
	}

	.property-quad-mask {
		width: 100%;
	}

	.quad-slider-arrow-link {
		width: 65px;
		height: 65px;
		margin-right: 20px;
		margin-left: 20px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		border-width: 5px;
		background-color: rgba(96, 96, 96, 0.5);
		box-shadow: 9px 9px 30px 0 rgba(90, 90, 90, 0.45), inset 0 0 8px 0 rgba(96, 96, 96, 0.3);
	}

	.quad-slider-arrow-link.quad-arrow-right {
		box-shadow: -9px 9px 30px 0 rgba(90, 90, 90, 0.45), inset 0 0 8px 0 rgba(96, 96, 96, 0.3);
	}

	.paragraph-text {
		width: 100%;
		font-size: 0.95rem;
		line-height: 1.45rem;
	}

	.paragraph-text.about-us {
		padding-right: 0rem;
		font-size: 1.1rem;
		line-height: 1.75rem;
	}

	.about-us-div._2 {
		padding-top: 441px;
	}

	.spotlight-container {
		width: 100%;
		padding-right: 0px;
	}

	.spotlight-container.fourth {
		width: 74%;
		padding-right: 15px;
		padding-left: 15px;
		float: none;
	}

	.spotlight-container.second {
		width: 100%;
		min-width: auto;
		padding-right: 15px;
		padding-left: 15px;
		float: none;
		background-color: #fff;
		box-shadow: inset 1px 0 0 0 rgba(96, 96, 96, 0.3);
	}

	.spotlight-container.first {
		width: 100%;
		max-width: none;
		min-width: auto;
		margin-right: 2rem;
		margin-left: 2rem;
		padding-right: 0px;
		padding-bottom: 1rem;
		padding-left: 0rem;
		float: none;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
		background-color: transparent;
	}

	.spotlight-container.third {
		width: 74%;
		padding-right: 15px;
		padding-left: 15px;
		float: none;
	}

	.listing-detail-column-wrapper-2.trio-product-details {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.duo-slide-card-wrapper {
		width: 100%;
		padding-right: 0px;
	}

	.icon-2 {
		font-size: 2.05rem;
	}

	.property-spotlight-duo-slide {
		width: 100%;
	}

	.icon-search-2-column-wrap {
		position: absolute;
		top: 0%;
		bottom: 0%;
		display: block;
		width: 100px;
		padding-top: 0rem;
		padding-bottom: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.sign-in-icon.account-2 {
		margin-bottom: 0.25rem;
	}

	.top-icon-wrapper {
		display: none;
		width: 75px;
	}

	.top-icon-wrapper.basket-2 {
		margin-top: 0px;
	}

	.top-icon-wrapper.wishlist-2 {
		display: none;
	}

	.top-icon-wrapper.cart-mobile {
		width: 28px;
		margin-right: 0.25rem;
	}

	.basket-div {
		width: 100px;
	}

	.account-dropdown {
		padding-top: 1.8rem;
	}

	.account-link {
		padding: 0.05rem 0.3rem;
		font-size: 0.65rem;
		line-height: 1rem;
	}

	.account-link.top {
		padding: 0.05rem 0.3rem;
		font-size: 0.65rem;
	}

	.dropdown {
		width: 85px;
		padding-top: 2.75px;
	}

	.link {
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}

	.arrow-link {
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}

	.property-slider-arrow-link-3 {
		bottom: 610px;
		margin-right: 20px;
		margin-left: 20px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		border-width: 5px;
		box-shadow: none;
	}

	.property-slider-arrow-link-3.property-arrow-right {
		bottom: 610px;
		margin-left: 1.75rem;
		box-shadow: none;
	}

	.top-nav-div {
		position: absolute;
		top: 0px;
		opacity: 0;
	}

	.top-nav-wrapper {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.top-nav-link.stores {
		display: none;
		margin-right: 0.15rem;
		margin-left: 0.15rem;
		padding-right: 0.25rem;
		padding-left: 1.5rem;
		background-position: 0% 50%;
	}

	.top-nav-link.support {
		display: none;
		margin-right: 0.25rem;
		margin-left: 0.25rem;
		padding-right: 0.25rem;
		padding-left: 1.5rem;
		background-position: 0% 50%;
	}

	.top-nav-link.phone {
		display: none;
		margin-right: 0.15rem;
		margin-left: 0.15rem;
		padding-right: 0.25rem;
		padding-left: 1.5rem;
		background-position: 0% 50%;
	}

	.top-nav-link.free-pickup-and-delivery {
		display: none;
		margin-right: 0.15rem;
		margin-left: 0.15rem;
		padding-right: 0.25rem;
		padding-left: 1.5rem;
		background-position: 0% 50%;
	}

	.top-nav-link.credit-card {
		display: none;
		margin-right: 0.15rem;
		margin-left: 0.15rem;
		padding-right: 0.25rem;
		padding-left: 1.5rem;
		background-position: 0% 50%;
	}

	.top-nav-link.register {
		padding-right: 0.25rem;
	}

	.top-nav-link.log-in-option {
		padding-right: 0.35rem;
		padding-left: 0.25rem;
	}

	.top-nav-link.language {
		padding-right: 0.25rem;
		padding-left: 0.25rem;
	}

	.basket-amounts-div {
		left: 0rem;
	}

	.wishlist-dropdown {
		margin-top: -0.15rem;
	}

	.register-now-link {
		padding: 0.05rem 0.3rem;
		font-size: 0.65rem;
		line-height: 1rem;
	}

	.hidden-register-button-div {
		position: relative;
		left: 0rem;
	}

	.login-and-register-div {
		padding-left: 1.25rem;
	}

	.language-div {
		display: none;
	}

	.product-title {
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}

	.link-2 {
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}

	.add-to-cart-text {
		padding-left: 0.5rem;
	}

	.language-dropdown-list.w--open {
		right: auto;
	}

	.mobile-icon-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.stores-icon-mobile {
		width: 32px;
		height: 32px;
		margin-right: 0.5rem;
		margin-left: 0.5rem;
		background-image: url('../images/homepage-icons-store.svg');
		background-position: 50% 50%;
		background-size: 20px 22px;
		background-repeat: no-repeat;
	}

	.phone-icon-mobile {
		width: 32px;
		height: 32px;
		margin-right: 0.5rem;
		margin-left: 0.5rem;
		background-image: url('../images/homepage-icons-phone.svg');
		background-position: 50% 50%;
		background-size: 20px 20px;
		background-repeat: no-repeat;
	}

	.free-pickup-icon-mobile {
		width: 32px;
		height: 32px;
		background-image: url('../images/homepage-icons-truckgrey-31.svg');
		background-position: 50% 50%;
		background-size: 22px 22px;
		background-repeat: no-repeat;
	}

	.free-pickup-icon-mobile {
		width: 32px;
		height: 32px;
		background-image: url('../images/homepage-icons-phone.svg');
		background-position: 50% 50%;
		background-size: 22px 22px;
		background-repeat: no-repeat;
	}

	.language-div-mobile {
		margin-right: 0.5rem;
		margin-left: 0.5rem;
	}

	.delivery-icon-mobile {
		width: 32px;
		height: 32px;
		margin-right: 0.5rem;
		margin-left: 0.5rem;
		background-image: url('../images/homepage-icons-truckgrey-31.svg');
		background-position: 50% 50%;
		background-size: 20px 20px;
		background-repeat: no-repeat;
	}

	.stephanis-card-icon-mobile {
		width: 32px;
		height: 32px;
		margin-right: 0.5rem;
		margin-left: 0.5rem;
		background-image: url('../images/homepage-icons-creditcard-grey-32.svg');
		background-position: 50% 50%;
		background-size: 20px 20px;
		background-repeat: no-repeat;
	}

	.support-icon-mobile {
		width: 32px;
		height: 32px;
		margin-right: 0.5rem;
		margin-left: 0.5rem;
		background-image: url('../images/homepage-icons-support.svg');
		background-position: 50% 50%;
		background-size: 20px 20px;
		background-repeat: no-repeat;
	}

	.main-content-wrap.inside-left {
		margin-right: 0px;
		margin-left: 0px;
	}

	.main-content-wrap.left-right {
		margin-right: 0px;
		margin-left: 0px;
	}

	.main-content-wrap.inside-right {
		margin-right: 0px;
		margin-left: 0px;
	}

	.main-content-wrap.full {
		margin-right: 0px;
		margin-left: 0px;
	}

	.section-wrap.inside-content-section {
		padding-top: 7rem;
	}

	.section-wrap.inside-content-section {
		padding-top: 0rem;
	}

	.section-wrap.inside-content-section.level-1 {
		padding-top: 0rem;
	}

	.inside-content-beckground.level-1 {
		margin-top: 5.25rem;
	}

	.main-content-wrapper {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.intro-text-style {
		font-size: 1.125em;
	}

	.inside-page-header-content-wrap {
		padding-right: 0px;
		padding-left: 0px;
	}

	.main-content-container {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.right-sidebar-column {
		margin-top: 1.5rem;
		padding-top: 1.5rem;
	}

	.sidebar-section-wrap {
		margin-left: 0rem;
	}

	.container-3 {
		padding-right: 10px;
		padding-left: 10px;
	}

	.container-3.inside-right {
		padding-top: 1rem;
	}

	.category-div-wrapper {
		width: 46%;
		margin-bottom: 0.75rem;
	}

	.area-min-input {
		width: 45%;
	}

	.property-type-list-div-10.brand-subtype {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.property-type-list-div-10.technology {
		display: block;
		overflow: auto;
		height: 150px;
	}

	.property-type-list-div-10.size {
		display: block;
		overflow: auto;
		height: 150px;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.property-type-list-div-10.size-subtype {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.area-maximum-input {
		width: 45%;
	}

	.single-field.third {
		width: 33.33%;
	}

	.single-field.property {
		width: 50%;
	}

	.single-field.name {
		width: 50%;
	}

	.single-field.half {
		width: 50%;
	}

	.area-range-wrapper {
		width: 100%;
	}

	.price-range-wrapper {
		width: 100%;
	}

	.clear-all-div {
		width: 100%;
	}

	.search-filter-title {
		width: 100%;
		border-bottom: 1px none #d8d8d8;
	}

	.min-and-max-vertical-search-div {
		width: 85%;
	}

	.price-min-input {
		width: 45%;
	}

	.property-type-field-label {
		overflow: visible;
	}

	.price-max-input {
		width: 45%;
	}

	.search-card-wrapper {
		padding-left: 0rem;
	}

	.property-spotlight-wrapper-level-3 {
		width: 100%;
		margin-right: 0rem;
		margin-bottom: 1rem;
		margin-left: 0rem;
		padding-right: 0px;
	}

	.top-filtering-div {
		height: auto;
		margin-right: 0rem;
		margin-left: 0rem;
		-webkit-flex-wrap: wrap-reverse;
		-ms-flex-wrap: wrap-reverse;
		flex-wrap: wrap-reverse;
		-webkit-align-content: stretch;
		-ms-flex-line-pack: stretch;
		align-content: stretch;
	}

	.trio-card-div {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.filter-options-dropdown {
		height: 45px;
	}

	.dropdown-toggle-2 {
		height: 45px;
	}

	.side-filter-buttons {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-top: 5px;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.filter-products-link {
		margin-left: 0rem;
	}

	.property-type-list-div-brand.brand-subtype {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.property-type-list-div-brand.technology {
		display: block;
		overflow: auto;
		height: 150px;
	}

	.property-type-list-div-brand.size {
		display: block;
		overflow: auto;
		height: 150px;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.property-type-list-div-brand.size-subtype {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.property-type-list-div-size.brand-subtype {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.property-type-list-div-size.technology {
		display: block;
		overflow: auto;
		height: 150px;
	}

	.property-type-list-div-size.size {
		display: block;
		overflow: auto;
		height: 150px;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.property-type-list-div-size.size-subtype {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.property-type-list-div-size.size-subtype {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.property-type-list-div-tech.brand-subtype {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.property-type-list-div-tech.technology {
		display: block;
		overflow: auto;
		height: 150px;
	}

	.property-type-list-div-tech.size {
		display: block;
		overflow: auto;
		height: 150px;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.property-type-list-div-tech.size-subtype {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.property-type-list-div-color.brand-subtype {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.property-type-list-div-color.technology {
		display: block;
		overflow: auto;
		height: 150px;
	}

	.property-type-list-div-color.size {
		display: block;
		overflow: auto;
		height: 150px;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.property-type-list-div-color.size-subtype {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.hidden-desktop-tablet-nav-link {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}

	.product-details-title {
		padding-right: 0.5rem;
		padding-left: 0.5rem;
		font-size: 1.5rem;
		line-height: 1.65rem;
	}

	.mini-view-image {
		width: 175px;
		height: 175px;
	}

	.product-details-slider {
		height: 400px;
	}

	.top-icon-mobile-div {
		position: absolute;
		left: 0%;
		top: 0%;
		right: auto;
		bottom: 0%;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 50%;
		height: 5.2rem;
		max-width: 100px;
		min-width: auto;
		margin-top: 0rem;
		margin-bottom: 0rem;
		padding-bottom: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-ordinal-group: 0;
		-webkit-order: -1;
		-ms-flex-order: -1;
		order: -1;
	}

	.mobile-logo-cart-wrapper {
		display: block;
		width: 100%;
		padding-bottom: 3rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.cart-amounts-div {
		left: 0rem;
		padding: 0rem;
		font-size: 0.75rem;
	}

	.text-cart-button-div {
		left: 0rem;
	}

	.spec-tab {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.tab-spec-list-item {
		padding-right: 0.75rem;
		padding-left: 0.75rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.stock-location-text {
		max-width: 100%;
	}

	.tabs-menu-div {
		width: 100%;
	}

	.tabs-content-div {
		width: 100%;
	}

	.basket-amounts-div-2 {
		left: 0rem;
		display: none;
	}

	.basket-amounts-div-3 {
		left: 0rem;
	}

	.icon-search-2-column-wrap-2 {
		position: absolute;
		left: 0%;
		top: 0%;
		right: auto;
		bottom: 0%;
		width: 100px;
		padding-top: 0rem;
		padding-bottom: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.hidden-register-button-div-2 {
		position: relative;
		left: 0rem;
	}

	.telephone-number-2 {
		display: none;
		padding-left: 0.5rem;
	}

	.account-link-2 {
		padding: 0.05rem 0.3rem;
		font-size: 0.65rem;
		line-height: 1rem;
	}

	.account-link-2.top {
		padding: 0.05rem 0.3rem;
		font-size: 0.65rem;
	}

	.mobile-menu-arrow-2 {
		background-size: 16px 16px;
	}

	.mobile-menu-arrow-2:hover {
		background-size: 16px 16px;
	}

	.icon-search-2-column-wrap-3 {
		position: absolute;
		left: 0%;
		top: 0%;
		right: auto;
		bottom: 0%;
		width: 100px;
		padding-top: 0rem;
		padding-bottom: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.top-half-div-2 {
		padding-bottom: 1.5rem;
	}

	.main-nav-link-2.mobile-duplicate {
		width: 88%;
	}

	.main-nav-link-2.full.withicon {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}

	.main-nav-link-2.mobile-duplicate {
		width: 88%;
	}

	.search-column-2 {
		width: 350px;
		margin-top: 4rem;
	}

	.menu-button-2 {
		height: 70px;
		margin-top: 0rem;
		margin-right: 0rem;
		padding-top: 1.4rem;
		padding-bottom: 2px;
	}

	.menu-button-2:hover {
		background-color: transparent;
	}

	.menu-button-2.w--open {
		margin-top: 0rem;
		margin-right: 0rem;
		padding-top: 1.4rem;
	}

	.nav-menu-2 {
		width: 90%;
		margin-top: 140px;
	}

	.search-input-hidden-2 {
		height: 35px;
	}

	.brand-column-2 {
		position: absolute;
		width: 100%;
		padding-bottom: 0rem;
	}

	.search-filter-title-2 {
		width: 80%;
		border-bottom: 1px none #d8d8d8;
	}

	.div-block-6 {
		left: auto;
		top: 24px;
		right: 4.75rem;
		bottom: 0%;
		margin-top: 0px;
		margin-left: 0px;
	}

	.top-nav-link-2.language {
		padding-right: 0.25rem;
		padding-left: 0.25rem;
	}

	.dropdown-list-2.w--open {
		right: 0px;
	}

	.pagination-page-link {
		height: 30px;
	}

	.add-to-cart-text-2 {
		padding-left: 0.5rem;
	}

	.trio-card-div-2 {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.property-spotlight-wrapper-level-4 {
		width: 100%;
		margin-right: 0rem;
		margin-bottom: 1rem;
		margin-left: 0rem;
		padding-right: 0px;
	}

	.property-slider-arrow-link-4 {
		bottom: 610px;
		margin-right: 20px;
		margin-left: 20px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		border-width: 5px;
		box-shadow: none;
	}

	.property-slider-arrow-link-4.property-arrow-right {
		bottom: 610px;
		margin-left: 1.75rem;
		box-shadow: none;
	}

	.icon-5 {
		color: #8b8b8b;
		font-size: 2.05rem;
	}

	.menu-button-3 {
		height: 70px;
		margin-top: 0rem;
		margin-right: 0rem;
		padding-top: 1.4rem;
		padding-bottom: 2px;
	}

	.menu-button-3:hover {
		background-color: transparent;
	}

	.menu-button-3.w--open {
		margin-top: 0rem;
		margin-right: 0rem;
		padding-top: 1.4rem;
	}

	.search-input-hidden-3 {
		height: 35px;
	}

	.cart-amounts-div-2 {
		left: 0rem;
		padding: 0rem;
		font-size: 0.75rem;
	}

	.nav-menu-3 {
		overflow: auto;
		width: 100%;
		height: 72vh;
		margin-top: 0px;
	}

	#products_module.responsive.cms_entity .product-title {
		padding-right: 0rem;
		padding-left: 0rem;
		font-size: 1.5rem;
		line-height: 1.65rem;
	}

	.category-title._1 {
		width: 90%;
	}
}

@media (max-width: 479px) {
	#products_module.responsive.cms_entity .product-title {
		padding-top: 5px;
		font-size: 1.25rem;
		line-height: 1.45rem;
	}

	.top-icon-div {
		height: auto;
		max-width: 100%;
		min-width: 100%;
		margin-top: 0rem;
		padding-right: 0.5rem;
		padding-bottom: 0rem;
		padding-left: 0.5rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.top-icon-link-block.first-icon-link.header {
		width: 40px;
		max-width: 40px;
		min-width: 40px;
		margin-right: 0.15rem;
		padding-right: 0.1rem;
		padding-left: 0.1rem;
	}

	.top-icon-link-block.header {
		width: 32px;
		height: 32px;
		margin-right: 0.65rem;
	}

	.top-icon-link-block.header.wishlist {
		width: 40px;
		max-width: 40px;
		margin-right: 0.15rem;
		padding-right: 0.1rem;
		padding-left: 0.1rem;
	}

	.top-icon-link-block.header.phone {
		width: 28px;
		height: 28px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.top-icon-link-block.header.basket {
		width: 40px;
		max-width: 40px;
		padding-right: 0.1rem;
		padding-left: 0.1rem;
	}

	.top-icon-link-block.header.stores {
		width: 40px;
		max-width: 40px;
		padding-right: 0.1rem;
		padding-left: 0.1rem;
	}

	.top-icon-link-block.header.support {
		width: 40px;
		max-width: 40px;
		padding-right: 0.1rem;
		padding-left: 0.1rem;
	}

	.top-icon-link-block.header.mobile-cart {
		width: 40px;
		height: 28px;
		max-width: 40px;
		padding-right: 0.1rem;
		padding-left: 0.1rem;
	}

	.main-nav-link {
		font-size: 0.9rem;
	}

	.main-nav-link.mobile-duplicate {
		width: 83%;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
	}

	.main-nav-link.full {
		height: 2.5rem;
		padding-top: 0.25rem;
		padding-bottom: 0.25rem;
		font-size: 0.9rem;
	}

	.main-nav-link.full:hover {
		height: 2.5rem;
	}

	.main-nav-link.full.withicon {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}

	.nav-menu {
		overflow: auto;
		width: 100%;
		height: 72vh;
		margin-top: 0px;
		padding-right: 1px;
	}

	.brand {
		width: 300px;
		max-width: 185px;
		min-height: 35px;
		min-width: 175px;
		margin-top: 0.25rem;
		margin-right: 0rem;
		margin-bottom: 0.25rem;
		padding-bottom: 0rem;
		padding-left: 0px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-flex-shrink: 0;
		-ms-flex-negative: 0;
		flex-shrink: 0;
	}

	.brand.w--current {
		width: 100px;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.menu-button-text {
		display: none;
		font-size: 0.7rem;
	}

	.navbar {
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.mobile-menu-icon {
		position: absolute;
		left: auto;
		top: 0%;
		right: 0%;
		bottom: auto;
		height: 1.5rem;
		margin-top: 1rem;
		margin-right: 1rem;
		font-size: 1.75rem;
	}

	.mobile-menu-icon:hover {
		color: #000;
	}

	.mobile-link-label {
		line-height: 1.05rem;
	}

	.container {
		max-width: 479px;
	}

	.container.nav-container {
		overflow: visible;
		height: 3.5rem;
		max-width: none;
	}

	.container.nav-container {
		max-width: none;
	}

	.menu-button {
		position: fixed;
		top: 0%;
		bottom: auto;
		z-index: 10000;
		width: 38px;
		height: 38px;
		margin-top: 0rem;
		margin-right: 0rem;
		padding: 4px;
		-webkit-align-self: auto;
		-ms-flex-item-align: auto;
		-ms-grid-row-align: auto;
		align-self: auto;
		background-color: #fff;
	}

	.menu-button:hover {
		background-color: #fff;
	}

	.menu-button.w--open {
		top: 0px;
		bottom: auto;
		height: 48px;
		margin-top: 0rem;
		padding-top: 16px;
	}

	.icon-only {
		opacity: 1;
	}

	.stephanis-logo {
		max-width: 165px;
		min-width: 165px;
	}

	.mobile-menu-arrow {
		width: 17%;
	}

	.search-bar-div {
		width: 40px;
	}

	.search-bar-form {
		width: 40px;
		min-height: 38px;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		border-top-style: none;
		border-bottom-style: none;
	}

	.search-input-hidden {
		display: block;
		height: 35px;
		min-width: 209px;
		margin-top: 1px;
		opacity: 1;
	}

	.slider-column-2 {
		margin-top: 35px;
	}

	.slider-column-1 {
		padding-right: 15px;
		padding-left: 15px;
	}

	.slider-header {
		font-size: 28px;
		line-height: 32px;
	}

	.slide-text-wrap {
		max-width: 80%;
	}

	.slider-cta-link {
		position: static;
		bottom: 0px;
		margin-top: 2%;
	}

	.slider-nav {
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.slider-image-div {
		position: static;
		display: block;
		width: 240px;
		margin-right: auto;
		margin-left: auto;
	}

	.slider-widget {
		height: 300px;
	}

	.slider-header-emphasis {
		font-size: 28px;
	}

	.slider-row {
		top: 10px;
	}

	.slider-paragraph {
		display: none;
	}

	.slider-section {
		padding-top: 94px;
	}

	.slider-arrow {
		font-size: 1.5rem;
	}

	.top-telephone-div {
		width: 35px;
		padding-left: 0rem;
	}

	.telephone-number {
		display: none;
	}

	.quicklink-icon-text-wrap {
		text-align: center;
	}

	.quicklink-text-title {
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
	}

	.quicklink-icon-div-wrapper {
		width: 100%;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.quicklink-icon-div-wrapper.repeat {
		width: 100%;
		min-height: auto;
		margin-bottom: 0.25rem;
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
	}

	.small-cb-div-wrapper {
		width: 100%;
	}

	.cb-link-box-div {
		width: 100%;
		height: 225px;
	}

	.four-link-div-wrapper {
		margin-top: 0rem;
		margin-bottom: 1.5rem;
	}

	.cb-product-slider {
		margin-bottom: 1.5rem;
	}

	.property-spotlight-wrapper {
		padding-right: 0px;
	}

	.prop-spotlight-details-wrapper {
		width: 100%;
		padding-left: 0rem;
	}

	.listing-details-heading {
		padding-bottom: 0rem;
	}

	.listing-details-heading.original-price {
		padding-bottom: 0.15rem;
	}

	.listing-details-heading.now-price {
		padding-bottom: 0.15rem;
	}

	.listing-details-heading.large-now-price {
		padding-bottom: 0rem;
	}

	.listing-details-heading.large-now-price-product-details {
		padding-bottom: 0rem;
	}

	.property-slider-arrow-link {
		bottom: 600px;
		width: 40px;
		height: 40px;
		margin-right: 10px;
		margin-left: 10px;
		border-width: 4px;
		background-color: rgba(96, 96, 96, 0.75);
		box-shadow: none;
		font-size: 1.25rem;
	}

	.property-slider-arrow-link.property-arrow-right {
		bottom: 600px;
		margin-right: 1.5rem;
		margin-left: 1.5rem;
		box-shadow: none;
	}

	.property-slider-arrow-link.left-arrow {
		bottom: 610px;
		margin-right: 1.5rem;
		margin-left: 1.5rem;
	}

	.property-spotlight-slide {
		width: 100%;
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.listing-details {
		font-size: 0.725rem;
	}

	.property-slider-widget {
		width: 100%;
		height: 100%;
		padding-top: 0rem;
	}

	.property-spotlight-image-link {
		height: 225px;
	}

	.property-spotlight-mask {
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.listing-price {
		width: 100%;
		margin-bottom: 0.5rem;
		font-size: 1.4rem;
	}

	.listing-detail-column-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}

	.icon {
		font-size: 1.5rem;
	}

	.icon:hover {
		border-radius: 50px;
		background-color: #ffcb05;
	}

	.present-div {
		top: 200px;
	}

	.ribbon-title {
		max-width: 210px;
		margin-right: auto;
		margin-left: auto;
		font-size: 1.75rem;
	}

	.ribbon-banner {
		min-height: 100px;
	}

	.banner-link-title {
		padding-left: 0.75rem;
		font-size: 2.25rem;
		line-height: 2.75rem;
	}

	.banner-arrow {
		height: 40px;
		padding-left: 0.75rem;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		border-style: none;
		background-color: #e4c700;
	}

	.banner-link-subtitle {
		padding-left: 0.75rem;
		font-size: 2.25rem;
		line-height: 2.75rem;
	}

	.four-link-div-wrapper-copy {
		margin-top: 0rem;
	}

	.category-title._1 {
		width: 90%;
	}

	.category-title.level-1 {
		width: 172px;
	}

	.shop-cat-link-div {
		width: 100%;
		height: 225px;
	}

	.quicklinks-repeat-div {
		padding-bottom: 0rem;
	}

	.newsletter-div {
		padding-right: 0.25rem;
		padding-left: 0.25rem;
	}

	.newsletter-title {
		line-height: 2.5rem;
	}

	.newsletter-sub-title {
		font-size: 0.8rem;
	}

	.newsletter-form {
		width: 275px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.newsletter-text-field {
		height: 42px;
		margin-bottom: 0.75rem;
	}

	.newsletter-submit-button {
		height: 40px;
	}

	.newsletter-submit-button.search-filter-button {
		display: inline-block;
		height: auto;
		padding-bottom: 2px;
	}

	.social-media-wrapper.footer-socmed-wrapper.social-2 {
		width: 100%;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.social-media-link-block-2.footer-socmed-link-block {
		margin-right: 0.25rem;
		margin-left: 0.25rem;
	}

	.social-media-title {
		line-height: 2.5rem;
		margin-right: 10px;
		margin-left: 10px;
	}

	.footer-list-link:hover {
		border-left-color: transparent;
	}

	.list-link-div,
	.list-link-div.div-2,
	.list-link-div.div-3 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		margin-bottom: 1.5rem;
		padding-bottom: 1rem;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-bottom: 1px solid hsla(0, 0%, 55%, 0.25);
	}

	.list-link-div.last {
		margin-bottom: 0rem;
	}

	.list-link-wrapper {
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.grip-logo-link {
		float: none;
	}

	.footer-signoff-links.last {
		margin-right: 0rem;
	}

	.column-3 {
		margin-bottom: 0.5rem;
	}

	.column-2 {
		padding-left: 0px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.acrobat-reader-link {
		margin-bottom: 1.5rem;
		-webkit-box-ordinal-group: 0;
		-webkit-order: -1;
		-ms-flex-order: -1;
		order: -1;
	}

	.wishlist-div {
		position: relative;
		z-index: 2000;
		max-width: 45px;
		margin-right: 0rem;
	}

	.search-link-icon {
		display: inline-block;
	}

	.top-half-div {
		padding: 0rem 0px;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.search-column {
		display: block;
		width: 100%;
		height: 38px;
		margin-top: 0.2rem;
		padding-left: 0rem;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		background-color: #fff;
		box-shadow: 0 -1px 0 0 hsla(0, 0%, 75%, 0.5);
	}

	.brand-column {
		width: 100%;
		height: 3.5rem;
		padding-right: 1rem;
		padding-bottom: 0rem;
		padding-left: 1rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.cb-arrow-icon {
		font-size: 1.5rem;
	}

	.cb-left-arrow {
		width: 50px;
	}

	.cb-rightt-arrow {
		width: 50px;
		font-size: 1.5rem;
	}

	.search-link-icon-mobile {
		display: block;
		width: 38px;
		height: 38px;
		min-height: 38px;
		min-width: 38px;
		margin-top: 1px;
		margin-left: 0px;
		padding-left: 0px;
		background-color: #f1f1f1;
		background-size: 22px 22px;
	}

	.search-link-icon-mobile:hover {
		background-color: #e4c700;
	}

	.about-us-content-wrap {
		padding-top: 2.25rem;
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.multi-slider-container {
		width: 100%;
		padding-top: 0px;
		padding-bottom: 0px;
		line-height: 1.55rem;
	}

	.property-spotlight-wrapper-2 {
		padding-right: 0px;
	}

	.listing-two-column-wrapper {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.slide-4 {
		width: 100%;
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.property-slider-arrow-link-2 {
		width: 45px;
		height: 45px;
		margin-right: 10px;
		margin-left: 10px;
		border-width: 4px;
		font-size: 1.25rem;
	}

	.property-spotlight-slide-2 {
		width: 100%;
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}

	.multi-slider {
		width: 100%;
		height: 100%;
	}

	.property-slider-widget-2 {
		width: 100%;
		height: 100%;
		padding-top: 2rem;
	}

	.quad-slide-card-wrapper {
		padding-right: 0px;
	}

	.content-box-heading {
		margin-bottom: 0.5rem;
		font-size: 1.5rem;
	}

	.property-spotlight-image-link-2 {
		width: 100%;
	}

	.property-duo-mask {
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.property-spotlight-quad-slide {
		width: 100%;
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.duo-slider-arrow-link {
		width: 45px;
		height: 45px;
		margin-right: 10px;
		margin-left: 10px;
		border-width: 4px;
		font-size: 1.25rem;
	}

	.listing-column-wrapper.main {
		margin-top: 1rem;
	}

	.property-spotlight-mask-2 {
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.arrow {
		top: -13px;
		width: 45px;
		height: 45px;
		margin: 30px 10px 25rem;
		border-width: 4px;
		font-size: 1.25rem;
	}

	.arrow.multi-slider {
		top: -200px;
		right: 0px;
		width: 54px;
		height: 54px;
	}

	.arrow.left {
		margin-left: 0px;
		border-width: 5px;
	}

	.arrow.left.multi-slider {
		left: 0px;
		top: -200px;
		width: 54px;
		height: 54px;
	}

	.property-quad-mask {
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.quad-slider-arrow-link {
		width: 45px;
		height: 45px;
		margin-right: 10px;
		margin-left: 10px;
		border-width: 4px;
		font-size: 1.25rem;
	}

	.paragraph-text {
		font-size: 0.85rem;
		line-height: 1.35rem;
	}

	.paragraph-text.about-us {
		padding-right: 0rem;
		font-size: 1.025rem;
		line-height: 1.6rem;
	}

	.about-us-div._2 {
		padding-top: 510px;
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.spotlight-container {
		padding-right: 0px;
	}

	.spotlight-container.fourth {
		width: 100%;
		min-width: auto;
		padding-top: 8px;
		padding-right: 0px;
		padding-left: 0px;
	}

	.spotlight-container.second {
		width: 100%;
		min-width: auto;
		padding-top: 8px;
		padding-right: 0px;
		padding-left: 0px;
	}

	.spotlight-container.first {
		width: 100%;
		min-width: auto;
		margin-right: 1.25rem;
		margin-left: 1.25rem;
		padding: 10px 0px 0px;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
		background-color: transparent;
	}

	.spotlight-container.third {
		width: 100%;
		min-width: auto;
		padding-top: 8px;
		padding-right: 0px;
		padding-left: 0px;
	}

	.listing-detail-column-wrapper-2 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}

	.duo-slide-card-wrapper {
		padding-right: 0px;
	}

	.property-slider-outer-wrap-2 {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}

	.icon-2 {
		font-size: 1.5rem;
	}

	.property-spotlight-duo-slide {
		width: 100%;
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.icon-search-2-column-wrap {
		display: none;
		width: 100%;
		padding-top: 0rem;
		padding-bottom: 0.35rem;
	}

	.sign-in-title {
		padding-top: 0.5rem;
	}

	.basket-title {
		padding-top: 0.25rem;
	}

	.stores-title {
		padding-top: 0.5rem;
	}

	.support-title {
		padding-top: 0.5rem;
	}

	.top-icon-wrapper {
		width: auto;
		margin-right: 0rem;
		margin-left: 0rem;
	}

	.top-icon-wrapper.basket-2 {
		margin-top: -0.5rem;
	}

	.top-icon-wrapper.wishlist-2 {
		margin-top: -0.5rem;
		margin-right: 1rem;
	}

	.top-icon-wrapper.cart-mobile {
		margin-top: -0.5rem;
	}

	.top-icon-wrapper.basket-2 {
		margin-top: -0.5rem;
	}

	.basket-div {
		max-width: 55px;
		margin-right: 0rem;
		margin-left: -0.5rem;
	}

	.account-dropdown {
		margin-top: -0.1rem;
	}

	.account-dropdown-list {
		margin-bottom: 0.5rem;
		opacity: 0;
	}

	.account-dropdown-list.w--open {
		position: absolute;
		left: 0%;
		top: auto;
		right: 0%;
		bottom: 0%;
		z-index: 10000;
		padding-top: 0.25rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
	}

	.account-link {
		min-width: 50px;
		line-height: 0.9rem;
	}

	.account-link.top {
		width: 50px;
		border-right-style: solid;
		line-height: 0.9rem;
		text-align: center;
	}

	.wishlist-save-icon {
		padding-left: 0.125rem;
	}

	.property-slider-arrow-link-3 {
		bottom: 600px;
		width: 45px;
		height: 45px;
		margin-right: 10px;
		margin-left: 10px;
		border-width: 4px;
		box-shadow: none;
		font-size: 1.25rem;
	}

	.property-slider-arrow-link-3.property-arrow-right {
		bottom: 600px;
		margin-right: 1.5rem;
		margin-left: 1.5rem;
		box-shadow: none;
	}

	.property-slider-arrow-link-3.property-arrow-right:hover {
		background-color: transparent;
	}

	.banner-arrow-2 {
		height: 40px;
		padding-left: 0.75rem;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		border-style: none;
		background-color: #e4c700;
	}

	.top-nav-div {
		opacity: 0;
	}

	.top-nav-wrapper {
		height: 32px;
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
	}

	.top-nav-link.stores {
		display: none;
		height: 32px;
		margin-right: 0.1rem;
		margin-left: 0.1rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
	}

	.top-nav-link.support {
		height: 32px;
		margin-right: 0.1rem;
		margin-left: 0.1rem;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
	}

	.top-nav-link.phone {
		height: 32px;
		margin-right: 0.1rem;
		margin-left: 0.1rem;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
	}

	.top-nav-link.free-pickup-and-delivery {
		display: none;
		height: 32px;
		margin-right: 0.1rem;
		margin-left: 0.1rem;
		padding-left: 1.35rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
	}

	.top-nav-link.credit-card {
		display: none;
		height: 32px;
		margin-right: 0.1rem;
		margin-left: 0.1rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
	}

	.basket-amounts-div {
		left: 0rem;
		margin-right: 0rem;
		padding-top: 0rem;
	}

	.new-present-div {
		width: 100%;
	}

	.register-now-link {
		line-height: 0.9rem;
	}

	.hidden-register-button-div {
		left: -0.75rem;
	}

	.login-and-register-div {
		padding-left: 1.1rem;
	}

	.language-dropdown-list.w--open {
		right: 0rem;
	}

	.stores-icon-mobile {
		margin-right: 0.54rem;
		margin-left: 0.45rem;
	}

	.phone-icon-mobile {
		margin-right: 0.45rem;
		margin-left: 0.45rem;
	}

	.language-div-mobile {
		margin-right: 0.45rem;
		margin-left: 0.45rem;
	}

	.delivery-icon-mobile {
		margin-right: 0.45rem;
		margin-left: 0.45rem;
	}

	.stephanis-card-icon-mobile {
		margin-right: 0.45rem;
		margin-left: 0.45rem;
	}

	.support-icon-mobile {
		margin-right: 0.45rem;
		margin-left: 0.45rem;
	}

	.two-part-button-toggle {
		width: 25%;
		line-height: 1.625rem;
	}

	.section-wrap.inside-content-section {
		width: 100%;
		padding-top: 7rem;
	}

	.section-wrap.inside-content-section {
		width: 100%;
		padding-top: 0rem;
	}

	.section-wrap.inside-content-section.level-1 {
		padding-top: 0rem;
	}

	.inside-content-beckground.level-1 {
		margin-top: 3.5rem;
	}

	.main-content-wrapper {
		width: 100%;
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.main-content-wrapper.inside-left {
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.nav-link.two-part-mobile {
		width: 75%;
	}

	.main-content-container {
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.main-content-container.left-right {
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.container-3.inside-right {
		padding-top: 1rem;
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.category-div-wrapper {
		width: 92%;
	}

	.property-type-list-div-10.area {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.property-type-list-div-10.brand-subtype {
		height: 130px;
		margin-bottom: 0rem;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.property-type-list-div-10.price-range {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.property-type-list-div-10.technology {
		height: 130px;
	}

	.property-type-list-div-10.size {
		height: 125px;
	}

	.property-type-list-div-10.color {
		display: block;
	}

	.property-type-list-div-10.size-subtype {
		height: 130px;
		margin-bottom: 0rem;
		padding-left: 1rem;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.vertical-search-form {
		width: 100%;
	}

	.single-field.third {
		width: 50%;
	}

	.single-field.name {
		width: 100%;
	}

	.single-field.half {
		width: 100%;
	}

	.vertical-search-form-div {
		width: 100%;
	}

	.div-line-sections {
		height: 8px;
	}

	.div-line-sections.first.colored {
		height: 8px;
	}

	.div-line-sections.colored {
		height: 8px;
	}

	.min-div._2 {
		width: 22px;
		height: 22px;
	}

	.min-and-max-vertical-search-div {
		width: 100%;
		padding-right: 0rem;
	}

	.max-div._2 {
		width: 22px;
		height: 22px;
	}

	.line-div {
		height: 8px;
	}

	.property-spotlight-wrapper-level-3 {
		padding-right: 0px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.top-filtering-div {
		height: auto;
	}

	.filter-options-dropdown {
		height: 40px;
		border-style: solid;
		border-width: 1px;
		border-color: hsla(194.1322314049587, 0%, 72%, 0.5);
	}

	.dropdown-toggle-2 {
		height: 40px;
		padding-right: 35px;
	}

	.side-filter-buttons {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-right: 5px;
	}

	.filter-products-link {
		margin-left: 0rem;
		font-size: 0.85rem;
		line-height: 1.3rem;
	}

	.property-type-list-div-brand.area {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.property-type-list-div-brand.brand-subtype {
		height: 127px;
		margin-bottom: 0rem;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.property-type-list-div-brand.price-range {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.property-type-list-div-brand.technology {
		height: 130px;
	}

	.property-type-list-div-brand.size {
		height: 125px;
	}

	.property-type-list-div-brand.color {
		display: block;
	}

	.property-type-list-div-brand.size-subtype {
		height: 130px;
		margin-bottom: 0rem;
		padding-left: 1rem;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.property-type-list-div-size.area {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.property-type-list-div-size.brand-subtype {
		height: 130px;
		margin-bottom: 0rem;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.property-type-list-div-size.price-range {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.property-type-list-div-size.technology {
		height: 130px;
	}

	.property-type-list-div-size.size {
		height: 125px;
	}

	.property-type-list-div-size.color {
		display: block;
	}

	.property-type-list-div-size.size-subtype {
		height: 127px;
		margin-bottom: 0rem;
		padding-left: 1rem;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.property-type-list-div-size.size-subtype {
		height: 127px;
		margin-bottom: 0rem;
		padding-left: 1rem;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.property-type-list-div-tech.area {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.property-type-list-div-tech.brand-subtype {
		height: 130px;
		margin-bottom: 0rem;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.property-type-list-div-tech.price-range {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.property-type-list-div-tech.technology {
		height: 127px;
	}

	.property-type-list-div-tech.size {
		height: 125px;
	}

	.property-type-list-div-tech.color {
		display: block;
	}

	.property-type-list-div-tech.size-subtype {
		height: 130px;
		margin-bottom: 0rem;
		padding-left: 1rem;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.property-type-list-div-color.area {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.property-type-list-div-color.brand-subtype {
		height: 130px;
		margin-bottom: 0rem;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.property-type-list-div-color.price-range {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.property-type-list-div-color.technology {
		height: 130px;
	}

	.property-type-list-div-color.size {
		height: 125px;
	}

	.property-type-list-div-color.color {
		display: block;
	}

	.property-type-list-div-color.size-subtype {
		height: 130px;
		margin-bottom: 0rem;
		padding-left: 1rem;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.icon-4 {
		margin-right: 9px;
	}

	.product-details-title {
		font-size: 1.25rem;
		line-height: 1.45rem;
	}

	.mini-view-image {
		height: 100px;
	}

	.product-details-slider {
		height: 285px;
	}

	.top-icon-mobile-div {
		left: -5px;
		top: 3%;
		height: 3.5rem;
		max-width: 75px;
		margin-top: 0rem;
		padding-right: 0.5rem;
		padding-bottom: 0rem;
		padding-left: 0.5rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.mobile-logo-cart-wrapper {
		padding-bottom: 3.5rem;
	}

	.cart-amounts-div {
		left: 0rem;
		margin-right: 0rem;
		padding-top: 0rem;
		font-size: 0.625rem;
	}

	.basket-icon-mobile-cart {
		max-height: 20px;
		max-width: 20px;
		margin-top: 0.25rem;
	}

	.product-details-specs {
		border-right: 1px solid hsla(194.1322314049587, 0%, 72%, 0.5);
	}

	.product-detail-spec-label {
		font-size: 0.75rem;
		line-height: 1.25rem;
	}

	.product-detail-spec-label-numbers {
		padding-left: 0.5rem;
		font-size: 0.75rem;
		line-height: 1.25rem;
	}

	.stock-location-text {
		font-size: 0.8rem;
		line-height: 1.15rem;
	}

	.basket-amounts-div-2 {
		left: 0rem;
		margin-right: 0rem;
		padding-top: 0rem;
	}

	.basket-amounts-div-3 {
		left: 0rem;
		margin-right: 0rem;
		padding-top: 0rem;
	}

	.icon-search-2-column-wrap-2 {
		display: none;
		width: 100%;
		padding-top: 0rem;
		padding-bottom: 0.35rem;
	}

	.hidden-register-button-div-2 {
		left: -0.75rem;
	}

	.telephone-number-2 {
		display: none;
	}

	.account-link-2 {
		min-width: 50px;
		line-height: 0.9rem;
	}

	.account-link-2.top {
		width: 50px;
		border-right-style: solid;
		line-height: 0.9rem;
		text-align: center;
	}

	.mobile-menu-arrow-2 {
		width: 17%;
	}

	.icon-search-2-column-wrap-3 {
		display: none;
		width: 100%;
		padding-top: 0rem;
		padding-bottom: 0.35rem;
	}

	.top-half-div-2 {
		padding: 0rem 0px;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.main-nav-link-2 {
		font-size: 0.9rem;
	}

	.main-nav-link-2.mobile-duplicate {
		width: 83%;
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
	}

	.main-nav-link-2.full {
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
		font-size: 0.9rem;
	}

	.main-nav-link-2.full {
		height: 2.5rem;
		padding-top: 0.25rem;
		padding-bottom: 0.25rem;
		font-size: 0.9rem;
	}

	.main-nav-link-2.full:hover {
		height: 2.5rem;
	}

	.main-nav-link-2.full.withicon {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}

	.main-nav-link-2.mobile-duplicate {
		width: 83%;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
	}

	.navbar-2 {
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.search-column-2 {
		width: 100%;
		height: 38px;
		margin-top: 4rem;
		padding-left: 1rem;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		background-color: #fff;
		box-shadow: 0 -1px 0 0 hsla(0, 0%, 75%, 0.5);
	}

	.menu-button-2 {
		position: absolute;
		left: auto;
		top: auto;
		right: 0%;
		bottom: 0%;
		z-index: 10000;
		width: 38px;
		height: 38px;
		margin-right: 1rem;
		padding: 4px;
		background-color: #d6d6d6;
	}

	.menu-button-2:hover {
		background-color: #e4c700;
	}

	.menu-button-2.w--open {
		margin-top: 0rem;
		padding-top: 4px;
	}

	.search-bar-form-2 {
		width: 40px;
		min-height: 40px;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		border-top-style: none;
		border-bottom-style: none;
	}

	.mobile-menu-icon-2 {
		font-size: 1.75rem;
	}

	.mobile-menu-icon-2:hover {
		color: #000;
	}

	.nav-menu-2 {
		width: 80%;
		margin-top: 94px;
	}

	.search-link-icon-mobile-2 {
		display: block;
		width: 38px;
		height: 38px;
		min-height: 38px;
		min-width: 38px;
		margin-top: 1px;
		margin-left: 0px;
		padding-left: 0px;
		background-color: #f1f1f1;
		background-size: 22px 22px;
	}

	.search-link-icon-mobile-2:hover {
		background-color: #e4c700;
	}

	.search-input-hidden-2 {
		display: block;
		height: 38px;
		min-width: 209px;
		margin-top: 1px;
		opacity: 1;
	}

	.brand-column-2 {
		width: 100%;
		height: 3.5rem;
		padding-right: 1rem;
		padding-bottom: 0rem;
		padding-left: 1rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.search-filter-title-2 {
		width: 74%;
		padding-left: 0.5rem;
	}

	.div-block-6 {
		left: auto;
		top: 0.75rem;
		right: 3.6rem;
		bottom: 0%;
	}

	.view-type-block {
		float: none;
	}

	.view-type.grid-view {
		margin-top: 0px;
		padding-top: 0px;
	}

	.view-type.list-view {
		margin-top: 0px;
	}

	.pagination-form-wrapper {
		clear: both;
	}

	.pagination-block {
		display: block;
		margin-right: auto;
		margin-left: auto;
		float: left;
	}

	.view-type-block-2 {
		float: right;
	}

	.sort-form-wrapper {
		margin-left: 0px;
	}

	.pagination-holder-list-view {
		margin-bottom: 1rem;
	}

	.property-spotlight-wrapper-level-4 {
		padding-right: 0px;
	}

	.property-slider-arrow-link-4 {
		bottom: 600px;
		width: 45px;
		height: 45px;
		margin-right: 10px;
		margin-left: 10px;
		border-width: 4px;
		box-shadow: none;
		font-size: 1.25rem;
	}

	.property-slider-arrow-link-4.property-arrow-right {
		bottom: 600px;
		margin-right: 1.5rem;
		margin-left: 1.5rem;
		box-shadow: none;
	}

	.property-slider-arrow-link-4.property-arrow-right:hover {
		background-color: transparent;
	}

	.icon-5 {
		font-size: 1.5rem;
	}

	.icon-5:hover {
		border-radius: 50px;
		background-color: #ffcb05;
	}

	.menu-button-3 {
		position: fixed;
		left: auto;
		top: 0%;
		right: -12px;
		bottom: auto;
		z-index: 10000;
		width: 38px;
		height: 38px;
		margin-top: 0rem;
		margin-right: 0rem;
		padding: 4px;
		-webkit-align-self: auto;
		-ms-flex-item-align: auto;
		-ms-grid-row-align: auto;
		align-self: auto;
		background-color: #fff;
	}

	.menu-button-3:hover {
		background-color: #fff;
	}

	.menu-button-3:active {
		left: auto;
		top: 0%;
		right: -12px;
		bottom: auto;
		width: 38px;
		height: 38px;
		margin-right: 0rem;
		background-color: #fff;
	}

	.menu-button-3.w--open {
		top: 0px;
		bottom: auto;
		height: 48px;
		margin-top: 0rem;
		padding-top: 16px;
	}

	.search-input-hidden-3 {
		display: block;
		height: 35px;
		min-width: 209px;
		margin-top: 1px;
		opacity: 1;
	}

	.mobile-menu-icon-3 {
		position: absolute;
		left: auto;
		top: 0%;
		right: 0%;
		bottom: auto;
		height: 1.5rem;
		margin-top: 1rem;
		margin-right: 1rem;
		font-size: 1.75rem;
	}

	.mobile-menu-icon-3:hover {
		color: #000;
	}

	.cart-amounts-div-2 {
		left: 0rem;
		margin-right: 0rem;
		padding-top: 0rem;
		font-size: 0.625rem;
	}

	.nav-menu-3 {
		overflow: auto;
		width: 100%;
		height: 72vh;
		margin-top: 0px;
		padding-right: 1px;
	}

	.property-spotlight-image-link-3 img.special-offer-icon {
		max-width: 60px;
	}

	.property-spotlight-image-link-3 .special-offer-date {
		font-size: 0.65rem;
	}

	.top-social {
		margin-bottom: 1rem;
	}
}

@media screen and (max-width: 991px) {
	#w-node-3506c3af98df-f5292c4b {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 1;
		grid-row-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
	}
}

@media screen and (max-width: 991px) {
	#w-node-3506c3af98e8-f5292c4b {
		-ms-grid-column: 2;
		grid-column-start: 2;
		-ms-grid-column-span: 1;
		grid-column-end: 3;
		-ms-grid-row: 1;
		grid-row-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
	}
}

@media screen and (max-width: 991px) {
	#w-node-3506c3af98eb-f5292c4b {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 2;
		grid-row-start: 2;
		-ms-grid-row-span: 1;
		grid-row-end: 3;
	}
}

@media screen and (max-width: 991px) {
	#w-node-3506c3af98ee-f5292c4b {
		-ms-grid-column: 2;
		grid-column-start: 2;
		-ms-grid-column-span: 1;
		grid-column-end: 3;
		-ms-grid-row: 2;
		grid-row-start: 2;
		-ms-grid-row-span: 1;
		grid-row-end: 3;
	}
}

@media screen and (max-width: 991px) {
	#w-node-3506c3af98f3-f5292c4b {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 3;
		grid-row-start: 3;
		-ms-grid-row-span: 1;
		grid-row-end: 4;
	}
}

@media screen and (max-width: 991px) {
	#w-node-3506c3af98f6-f5292c4b {
		-ms-grid-column: 2;
		grid-column-start: 2;
		-ms-grid-column-span: 1;
		grid-column-end: 3;
		-ms-grid-row: 3;
		grid-row-start: 3;
		-ms-grid-row-span: 1;
		grid-row-end: 4;
	}
}

@media screen and (max-width: 991px) {
	#w-node-b6c06fd0eaf0-0a292c4e {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 1;
		grid-row-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
	}
}

@media screen and (max-width: 991px) {
	#w-node-b6c06fd0eaf9-0a292c4e {
		-ms-grid-column: 2;
		grid-column-start: 2;
		-ms-grid-column-span: 1;
		grid-column-end: 3;
		-ms-grid-row: 1;
		grid-row-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
	}
}

@media screen and (max-width: 991px) {
	#w-node-b6c06fd0eafc-0a292c4e {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 2;
		grid-row-start: 2;
		-ms-grid-row-span: 1;
		grid-row-end: 3;
	}
}

@media screen and (max-width: 991px) {
	#w-node-b6c06fd0eaff-0a292c4e {
		-ms-grid-column: 2;
		grid-column-start: 2;
		-ms-grid-column-span: 1;
		grid-column-end: 3;
		-ms-grid-row: 2;
		grid-row-start: 2;
		-ms-grid-row-span: 1;
		grid-row-end: 3;
	}
}

@media screen and (max-width: 991px) {
	#w-node-b6c06fd0eb04-0a292c4e {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 3;
		grid-row-start: 3;
		-ms-grid-row-span: 1;
		grid-row-end: 4;
	}
}

@media screen and (max-width: 991px) {
	#w-node-b6c06fd0eb07-0a292c4e {
		-ms-grid-column: 2;
		grid-column-start: 2;
		-ms-grid-column-span: 1;
		grid-column-end: 3;
		-ms-grid-row: 3;
		grid-row-start: 3;
		-ms-grid-row-span: 1;
		grid-row-end: 4;
	}
}

@media screen and (max-width: 991px) {
	#w-node-125dbeb93045-5c292c4f {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 1;
		grid-row-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
	}
}

@media screen and (max-width: 991px) {
	#w-node-125dbeb9304e-5c292c4f {
		-ms-grid-column: 2;
		grid-column-start: 2;
		-ms-grid-column-span: 1;
		grid-column-end: 3;
		-ms-grid-row: 1;
		grid-row-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
	}
}

@media screen and (max-width: 991px) {
	#w-node-125dbeb93051-5c292c4f {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 2;
		grid-row-start: 2;
		-ms-grid-row-span: 1;
		grid-row-end: 3;
	}
}

@media screen and (max-width: 991px) {
	#w-node-125dbeb93054-5c292c4f {
		-ms-grid-column: 2;
		grid-column-start: 2;
		-ms-grid-column-span: 1;
		grid-column-end: 3;
		-ms-grid-row: 2;
		grid-row-start: 2;
		-ms-grid-row-span: 1;
		grid-row-end: 3;
	}
}

@media screen and (max-width: 991px) {
	#w-node-125dbeb93059-5c292c4f {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 3;
		grid-row-start: 3;
		-ms-grid-row-span: 1;
		grid-row-end: 4;
	}
}

@media screen and (max-width: 991px) {
	#w-node-125dbeb9305c-5c292c4f {
		-ms-grid-column: 2;
		grid-column-start: 2;
		-ms-grid-column-span: 1;
		grid-column-end: 3;
		-ms-grid-row: 3;
		grid-row-start: 3;
		-ms-grid-row-span: 1;
		grid-row-end: 4;
	}
}

@media screen and (max-width: 991px) {
	#w-node-8bc6e442c148-3c292c50 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 1;
		grid-row-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
	}
}

@media screen and (max-width: 991px) {
	#w-node-8bc6e442c151-3c292c50 {
		-ms-grid-column: 2;
		grid-column-start: 2;
		-ms-grid-column-span: 1;
		grid-column-end: 3;
		-ms-grid-row: 1;
		grid-row-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
	}
}

@media screen and (max-width: 991px) {
	#w-node-8bc6e442c154-3c292c50 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 2;
		grid-row-start: 2;
		-ms-grid-row-span: 1;
		grid-row-end: 3;
	}
}

@media screen and (max-width: 991px) {
	#w-node-8bc6e442c157-3c292c50 {
		-ms-grid-column: 2;
		grid-column-start: 2;
		-ms-grid-column-span: 1;
		grid-column-end: 3;
		-ms-grid-row: 2;
		grid-row-start: 2;
		-ms-grid-row-span: 1;
		grid-row-end: 3;
	}
}

@media screen and (max-width: 991px) {
	#w-node-8bc6e442c15c-3c292c50 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 3;
		grid-row-start: 3;
		-ms-grid-row-span: 1;
		grid-row-end: 4;
	}
}

@media screen and (max-width: 991px) {
	#w-node-8bc6e442c15f-3c292c50 {
		-ms-grid-column: 2;
		grid-column-start: 2;
		-ms-grid-column-span: 1;
		grid-column-end: 3;
		-ms-grid-row: 3;
		grid-row-start: 3;
		-ms-grid-row-span: 1;
		grid-row-end: 4;
	}
}

@media screen and (max-width: 991px) {
	#w-node-7d2ab0c6cc96-a7292c51 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 1;
		grid-row-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
	}
}

@media screen and (max-width: 991px) {
	#w-node-7d2ab0c6cc9f-a7292c51 {
		-ms-grid-column: 2;
		grid-column-start: 2;
		-ms-grid-column-span: 1;
		grid-column-end: 3;
		-ms-grid-row: 1;
		grid-row-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
	}
}

@media screen and (max-width: 991px) {
	#w-node-7d2ab0c6cca2-a7292c51 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 2;
		grid-row-start: 2;
		-ms-grid-row-span: 1;
		grid-row-end: 3;
	}
}

@media screen and (max-width: 991px) {
	#w-node-7d2ab0c6cca5-a7292c51 {
		-ms-grid-column: 2;
		grid-column-start: 2;
		-ms-grid-column-span: 1;
		grid-column-end: 3;
		-ms-grid-row: 2;
		grid-row-start: 2;
		-ms-grid-row-span: 1;
		grid-row-end: 3;
	}
}

@media screen and (max-width: 991px) {
	#w-node-7d2ab0c6ccaa-a7292c51 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 3;
		grid-row-start: 3;
		-ms-grid-row-span: 1;
		grid-row-end: 4;
	}
}

@media screen and (max-width: 991px) {
	#w-node-7d2ab0c6ccad-a7292c51 {
		-ms-grid-column: 2;
		grid-column-start: 2;
		-ms-grid-column-span: 1;
		grid-column-end: 3;
		-ms-grid-row: 3;
		grid-row-start: 3;
		-ms-grid-row-span: 1;
		grid-row-end: 4;
	}
}

@media screen and (max-width: 991px) {
	#w-node-0a0f2a149803-a5292c55 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 1;
		grid-row-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
	}
}

@media screen and (max-width: 991px) {
	#w-node-0a0f2a14980c-a5292c55 {
		-ms-grid-column: 2;
		grid-column-start: 2;
		-ms-grid-column-span: 1;
		grid-column-end: 3;
		-ms-grid-row: 1;
		grid-row-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
	}
}

@media screen and (max-width: 991px) {
	#w-node-0a0f2a14980f-a5292c55 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 2;
		grid-row-start: 2;
		-ms-grid-row-span: 1;
		grid-row-end: 3;
	}
}

@media screen and (max-width: 991px) {
	#w-node-0a0f2a149812-a5292c55 {
		-ms-grid-column: 2;
		grid-column-start: 2;
		-ms-grid-column-span: 1;
		grid-column-end: 3;
		-ms-grid-row: 2;
		grid-row-start: 2;
		-ms-grid-row-span: 1;
		grid-row-end: 3;
	}
}

@media screen and (max-width: 991px) {
	#w-node-0a0f2a149817-a5292c55 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 3;
		grid-row-start: 3;
		-ms-grid-row-span: 1;
		grid-row-end: 4;
	}
}

@media screen and (max-width: 991px) {
	#w-node-0a0f2a14981a-a5292c55 {
		-ms-grid-column: 2;
		grid-column-start: 2;
		-ms-grid-column-span: 1;
		grid-column-end: 3;
		-ms-grid-row: 3;
		grid-row-start: 3;
		-ms-grid-row-span: 1;
		grid-row-end: 4;
	}
}

@media screen and (max-width: 991px) {
	#w-node-0a0f2a149803-bdd57644 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 1;
		grid-row-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
	}
}

@media screen and (max-width: 991px) {
	#w-node-0a0f2a14980c-bdd57644 {
		-ms-grid-column: 2;
		grid-column-start: 2;
		-ms-grid-column-span: 1;
		grid-column-end: 3;
		-ms-grid-row: 1;
		grid-row-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
	}
}

@media screen and (max-width: 991px) {
	#w-node-0a0f2a14980f-bdd57644 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 2;
		grid-row-start: 2;
		-ms-grid-row-span: 1;
		grid-row-end: 3;
	}
}

@media screen and (max-width: 991px) {
	#w-node-0a0f2a149812-bdd57644 {
		-ms-grid-column: 2;
		grid-column-start: 2;
		-ms-grid-column-span: 1;
		grid-column-end: 3;
		-ms-grid-row: 2;
		grid-row-start: 2;
		-ms-grid-row-span: 1;
		grid-row-end: 3;
	}
}

@media screen and (max-width: 991px) {
	#w-node-0a0f2a149817-bdd57644 {
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-row: 3;
		grid-row-start: 3;
		-ms-grid-row-span: 1;
		grid-row-end: 4;
	}
}

@media screen and (max-width: 991px) {
	#w-node-0a0f2a14981a-bdd57644 {
		-ms-grid-column: 2;
		grid-column-start: 2;
		-ms-grid-column-span: 1;
		grid-column-end: 3;
		-ms-grid-row: 3;
		grid-row-start: 3;
		-ms-grid-row-span: 1;
		grid-row-end: 4;
	}
}

/* GDPR */
#cookie-consent-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: rgb(38, 38, 38, 0.85);
	font-size: 1rem;
}

#cookie-consent-container .consent-actions a.consent-all-trigger,
#cookie-consent-container .consent-actions a.reject-all-trigger {
	color: #333;
	margin-bottom: 5px;
}

#cookie-consent-container .consent-actions a {
	margin-top: 5px;
}

#cookie-consent-container .consent-actions a.consent-all-trigger:hover,
#cookie-consent-container .consent-actions a.reject-all-trigger:hover {
	color: #fff;
}

#cookie-consent-form-container form .consent-header img {
	margin: 0 auto;
	display: block;
	float: none;
}

#cookie-consent-container .consent-disclaimer a {
	color: #ffcb05;
}

#cookie-consent-container .consent-disclaimer a:hover {
	text-decoration: none;
}

#cookie-consent-form a {
	border-bottom: 1px solid #ffc447;
	color: #666;
	font-weight: 500;
	text-decoration: none;
	cursor: pointer;
}

#cookie-consent-form a:hover {
	border-bottom-color: transparent;
	color: #333;
}

#cookie-consent-container .consent-disclaimer,
#cookie-consent-container .consent-actions {
	float: none;
	width: 100%;
	padding-left: 0px;
	display: block;
	max-width: 730px;
}

#cookie-consent-container .consent-disclaimer {
	order: 1;
	margin-bottom: 0;
	margin-top: 5px;
}

#cookie-consent-container .consent-actions {
	order: 2;
	text-align: center;
}

#cookie-consent-container .consent-dismiss-trigger-container {
	position: absolute;
	top: 15px;
	right: 20px;
}

body.consent-form-visible {
	height: 100%;
}

#cookie-consent-form-container {
	overflow-y: auto;
}

@media screen and (max-width: 1200px) {
	#cookie-consent-container .consent-actions .consent-actions-container {
		flex-direction: row;
		align-items: center;
	}
}

@media screen and (max-width: 991px) {
	#cookie-consent-form-container {
		font-size: 0.8rem;
		line-height: 1.4rem;
		max-height: 95vh;
	}
}

@media screen and (max-width: 767px) {
	#cookie-consent-container {
		font-size: 0.9rem;
	}

	#cookie-consent-form-container {
		max-height: 85vh;
	}

	#cookie-consent-container .consent-disclaimer {
		margin-top: 1.5rem;
	}
}

/* Advertisments */
.adshow_container .slide {
	background: unset;
	text-align: center;
}

.adshow_container.adshow_container_slideshow {
	position: relative;
}

.adshow_container .slide-arrow-container {
	-webkit-text-size-adjust: 100%;
	font-family: Roboto, sans-serif;
	line-height: 1.5rem;
	box-sizing: border-box;
	position: absolute;
	margin: auto;
	cursor: pointer;
	overflow: hidden;
	color: white;
	background-color: transparent;
	user-select: none;
	z-index: 3;
	padding-bottom: 0px;
	border-radius: 3rem;
	transition: all 450ms ease;
	font-size: 2rem;
	left: 0%;
	top: auto;
	right: auto;
	bottom: calc(50% - 22px);
	display: block;
	width: 45px;
	height: 45px;
	margin-right: 0rem;
	margin-left: 0rem;
}

.adshow_container .slide-arrow-container-left {
	left: 0%;
	margin-right: 0rem;
	margin-left: 1rem;
}

.adshow_container .slide-arrow-container-right {
	right: 0%;
	left: unset;
	margin-right: 1rem;
	margin-left: 0rem;
}

.adshow_container .slide-arrow-container:hover {
	background-color: #ffcb05;
}

.icon-slider-left,
.icon-slider-right {
	-webkit-text-size-adjust: 100%;
	text-align: center;
	cursor: pointer;
	color: #666;
	user-select: none;
	box-sizing: border-box;
	font-family: webflow-icons !important;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	width: 1em;
	height: 1em;
	font-size: 1.75rem;
	position: absolute;
	transition: all 450ms ease;
}

.icon-slider-left:before {
	content: "\e601";
}

.icon-slider-right:before {
	content: "\e600";
}

.adshow_container .slide-arrow-container:hover .icon-slider-left,
.adshow_container .slide-arrow-container:hover .icon-slider-right {
	color: #fff;
}

@media (max-width: 991px) {
	.adshow_container .slide-arrow-container-left {
		margin-left: 0;
	}

	.adshow_container .slide-arrow-container-right {
		margin-right: 0;
	}
}

.adshow_container ul {
	padding-left: 0px;
}

.adshow_container ul li {
	list-style: none;
	margin-bottom: 10px;
	text-align: center;
}

/* new homepage templates */
.cb-link-box-div-full {
	min-height: 275px;
	margin-right: 1rem;
	margin-left: 1rem;
}

@media (max-width: 991px) {
	.cb-link-box-div-full {
		margin-right: 0px;
		margin-left: 0px;
	}
}

@media (max-width: 991px) and (min-width: 768px) {
	.quicklink-icon-text-wrap.my-stephanis {
		padding-right: 80px;
	}
}

@media (min-width: 992px) {
	.slider-widget,
	.slider-widget .w-slide {
		height: 26vw;
	}

	.slider-widget .w-slide {
		background-position: 100%;
		background-size: 100% 26vw;
		background-repeat: no-repeat;
	}
}

@media (max-width: 991px) and (min-width: 768px) {
	.slider-widget,
	.slider-widget .w-slide {
		height: 25vw;
	}

	.slider-widget .w-slide {
		background-position: 100%;
		background-size: 100% 25vw;
		background-repeat: no-repeat;
	}
}

.preorder-price-label,
.pre-order-downpayment-amount-label {
	font-size: 1rem;
	line-height: 1rem;
	margin-top: 10px;
	text-transform: capitalize;
}


.item-prices .preorder-price-amount {
	margin-top: -13px;
}

.item-prices .large-preorder-downpayment-amount {
	margin-top: -13px;
}
/* Alert Box */
.alert-wrap.modal {
	display: none;
	position: fixed;
	z-index: 10000;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgb(0, 0, 0);
	background-color: rgba(0, 0, 0, 0.4);
}

.alert-wrap.modal .modal-content {
	display: flex;
	margin: 0 auto;
	width: 80%;
	align-items: center;
	justify-content: center;
	height: 100%;
}

.alert-wrap.modal .modal-content-inner {
	background-color: #fefefe;
	padding: 30px 20px 15px 20px;
	border: 1px solid #888;
	display: inline-block;
}

.alert-wrap.modal .close {
	color: #aaa;
	float: right;
	font-size: 28px;
	font-weight: bold;
	margin-top: -25px;
	margin-right: -10px;
}

.alert-wrap.modal .close:hover,
.alert-wrap.modal .close:focus {
	color: black;
	text-decoration: none;
	cursor: pointer;
}


/* Product Advertisements */
.product-category-advertisements-container {
	margin-bottom: 1rem;
}

.product-advertisements-container {
	margin-top: 2rem;
	margin-bottom: 1rem;
}

@media (max-width: 991px) {
	.product-category-advertisements-container {
		margin-top: 1rem;
	}
}

@media (max-width: 767px) {
	.product-category-advertisements-container {
		margin-top: 2rem;
	}
}

/* Product Advertisements Slider */
.adshow_container_slideshow .adshow_container_slick_slideshow {
	width: calc(100% - 60px);
	margin: 0 auto;
}

.adshow_container_slideshow .adshow_container_slick_slideshow .slide {
	visibility: hidden;
}

.adshow_container_slideshow .adshow_container_slick_slideshow.slick-initialized .slide {
	visibility: visible;
}

.adshow_container_slideshow .adshow_container_slick_slideshow .slick-dots {
	width: 100%;
	padding: 0;
	margin: 0;
	list-style: none;
	text-align: center;
}

.adshow_container_slideshow .adshow_container_slick_slideshow .slick-dots li {
	display: inline-block;
	width: 8px;
	height: 8px;
	margin: 0 5px;
	padding: 0;
	cursor: pointer;
}

.adshow_container_slideshow .adshow_container_slick_slideshow .slick-dots li button {
	font-size: 0;
	line-height: 0;
	display: block;
	width: 8px;
	height: 8px;
	padding: 0;
	cursor: pointer;
	color: transparent;
	border: 0;
	outline: none;
	background: #333;
	opacity: 0.75;
	border-radius: 100%;
}

.adshow_container_slideshow .adshow_container_slick_slideshow .slick-dots li.slick-active button {
	background-color: #ffcb05;
}

.adshow_container_slideshow .adshow_container_slick_slideshow .slick-prev,
.adshow_container_slideshow .adshow_container_slick_slideshow .slick-next {
	font-size: 0;
	line-height: 0;
	position: absolute;
	top: 45%;
	display: block;
	width: 35px;
	height: 35px;
	padding: 5px;
	cursor: pointer;
	color: transparent;
	border: none;
	outline: none;
	background: transparent;
	color: #333;
	border-radius: 100%;
}

.adshow_container_slideshow .adshow_container_slick_slideshow .slick-prev:hover,
.adshow_container_slideshow .adshow_container_slick_slideshow .slick-next:hover {
	background-color: #ffcb05;
	color: #fff;
}

.adshow_container_slideshow .adshow_container_slick_slideshow .slick-prev {
	left: -37px;
}

.adshow_container_slideshow .adshow_container_slick_slideshow .slick-next {
	right: -37px;
}

.adshow_container_slideshow .adshow_container_slick_slideshow .slick-prev:before,
.adshow_container_slideshow .adshow_container_slick_slideshow .slick-next:before {
	font-family: webflow-icons;
	font-size: 22px;
	line-height: 1;
	opacity: 0.75;
	font-weight: bold;
}

.adshow_container_slideshow .adshow_container_slick_slideshow .slick-next:before {
	content: "\e600";
}

.adshow_container_slideshow .adshow_container_slick_slideshow .slick-prev:before {
	content: "\e601";
}

