/*index styles*/
@media screen and (min-width: 992px) {
	.property-spotlight-mask {
		width: calc(100% - 160px);
	}
}

@media screen and (max-width: 991px) {
	.property-spotlight-mask {
		width: calc(100% - 40px);
	}
}

@media screen and (min-width: 1024px) and (max-width: 1360px) {
	.property-spotlight-slide {
		width: 31.33%;
	}
}

@media screen and (min-width: 992px) and (max-width: 1023px) {
	.property-spotlight-slide {
		width: 48%;
	}
}

@media screen and (min-width: 992px) {
	.property-quad-mask {
		width: calc(100% - 140px);
	}
}

@media screen and (max-width: 991px) {
	.property-quad-mask {
		width: calc(100% - 40px);
	}

	.top-nav-wrapper {
		display: none !important;
	}

	.top-nav-div {
		display: none !important;
	}
}

@media screen and (max-width: 1115px) {
	.telephone-number {
		display: none;
	}
}

html.html-home.addtocartpopup-popup {
	overflow: visible;
}

/*header styles*/
#printHeader {
	display: none;
}

.header-section {
	z-index: 9999;
}

.top-nav-div {
	z-index: 10000;
}

.brand-column {
	width: auto;
}

.mobile-navigation-menu {
	display: none;
	z-index: 1000;
	top: 97px;
	position: fixed;
	width: 100%;
	min-width: auto;
	max-width: none;
	border-top: 2px solid rgba(93, 63, 58, 0.35);
}

.mobile-navigation-menu.extra-space {
	top: 137px;
}

html.open-mobile-menu {
	overflow: hidden;
}

.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened ~ .mm-slideout {
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
	-webkit-transform: none;
	transform: none;
}

.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened ~ .mm-slideout {
	top: 100px;
}

.menu-button-close {
	display: none;
	background-image: url('/assets/images/menu-button-close.png');
	background-repeat: no-repeat;
	width: 60px;
	height: 60px;
	background-size: 30px;
	position: absolute;
	top: 23px;
	left: 50px;
	background-position: center;
}

.language-dropdown-link-2,
.language-dropdown-link {
	background-image: url('/20190619081922/assets/images/ru_RU.svg');
}

.language-dropdown-link-2._1,
.language-dropdown-link._1 {
	background-image: url('/20190619081922/assets/images/el_GR.svg');
}

.language-dropdown-link-2._2,
.language-dropdown-link._2 {
	background-image: url('/20190619081922/assets/images/en_US.svg');
}

.search-link-icon {
	border: 0 none;
	background-color: transparent;
	text-indent: -99999px;
	cursor: pointer;
}

.account-link-2 {
	text-transform: capitalize;
}

.no-link {
	cursor: default;
}

.desktop-cart-link {
	display: flex;
	align-items: center;
	text-decoration: none;
}

.header-section {
	top: 32px;
	-webkit-transition: top 300ms ease;
	transition: top 300ms ease;
}

.header-section.fixed-header {
	top: 0;
}

.basket-amounts-div-3 {
	display: block;
}

.container.nav-container {
	position: relative;
}

.dropdown-inner-wrap {
	width: 100%;
}

.dropdown-wrapper-custom.open:hover .main-nav-link.full {
	background-color: #58585a;
	color: #fff;
}

.dropdown-wrapper-custom.open .dropdown-list-custom {
	display: block;
	height: auto;
	opacity: 1;
	-webkit-transition: opacity 300ms ease 0ms, height 300ms ease 0ms;
	transition: opacity 300ms ease 0ms, height 300ms ease 0ms;
}

.dropdown-wrapper-custom.open .account-dropdown-list {
	margin-top: 0;
}

.subcateogry-div .dropdown-link.w--current {
	box-shadow: none;
	color: #000;
}

.w-dropdown-link.account-link-2.top.w--current {
	color: #222;
	background-color: #fff;
}

/*footer styles*/
#printFooter {
	display: none;
}

.social-media-title {
	color: #333;
}

.social-media-link-block-2.footer-socmed-link-block {
	background-color: transparent;
	border: 0 none;
	padding: 0;
	border-radius: 0;
	margin-right: 1.25rem;
	margin-left: 1.25rem;
	background-repeat: no-repeat;
	background-position: 50% 50%;
}

.social-media-link-block-2.footer-socmed-link-block.facebook {
	margin-right: 1rem;
	background-image: url('/20190619081922/assets/images/facebook_grey.svg');
	background-size: 47px 45px;
}

.social-media-link-block-2.footer-socmed-link-block.instagram {
	background-image: url('/20190619081922/assets/images/instagram_grey.svg');
	background-size: 35px 33px;
}

.social-media-link-block-2.footer-socmed-link-block.twitter {
	background-image: url('/20190619081922/assets/images/twitter_grey.svg');
	background-size: 55px 51px;
}

.social-media-link-block-2.footer-socmed-link-block.youtube {
	background-image: url('/20190619081922/assets/images/youtube_grey.svg');
	background-size: 55px 51px;
}

.social-media-link-block-2.footer-socmed-link-block.issue {
	background-image: url('/20190619081922/assets/images/issuu_grey.svg');
	background-size: 31px 31px;
}

.social-media-link-block-2.footer-socmed-link-block:hover {
	background-color: transparent;
	border: 0 none;
	padding: 0;
}

.footer-signoff-link,
.footer-list-link {
	text-transform: capitalize;
}

#GRIPFooterLogo {
	padding-top: 0 !important;
	width: 150px !important;
}

#GRIPFooterLogoImg {
	background-size: 150px 33px !important;
	width: 150px !important;
	height: 33px !important;
}

#GRIPFooterLogoText {
	line-height: 8px;
	padding-left: 37px !important;
	letter-spacing: 1px !important;
}

/*slideshow*/
.w-slide {
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.full-slide-link {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.slide-text-wrap h1,
.slide-text-wrap h2,
.slide-text-wrap h3 {
	display: inline-block;
	margin-top: 1px;
	margin-bottom: 5px;
	padding: 0.25rem 0.75rem 0.25rem 1.25rem;
	float: left;
	clear: left;
	background-color: rgba(53, 56, 64, 0.85);
	color: #fff;
	font-size: 2rem;
	line-height: 2.65rem;
	font-weight: 300;
	text-transform: uppercase;
}

.slide-text-wrap p {
	max-width: 327px;
	margin-top: 12px;
	margin-left: 1rem;
	padding: 0.5rem 0.5rem 0.5rem 1rem;
	float: left;
	clear: left;
	border-left: 4px solid #ffcb05;
	color: #fff;
}

/*content boxes*/
.shop-cat-link-div:hover .category-title {
	width: 100%;
}

.quicklink-text-title {
	color: #333;
}

.quicklink-icon-div-wrapper {
	border: 0 none;
	text-decoration: none;
}

/*ribbons*/
.prop-spotlight-details-wrapper-3 {
	margin-top: -8px;
}

.property-spotlight-slide-2 {
	width: 100%;
}

.property-slider-widget-2 .owl-carousel .owl-stage {
	display: flex;
}

.property-slider-widget-2 .owl-carousel .owl-stage .owl-item {
	padding-right: 0px;
}

.property-slider-widget-2 .property-spotlight-slide-2 {
	width: 98.3%;
	margin-left: 0px;
	margin-right: 0px;
}

.property-slider-widget-2 .property-spotlight-slide-2.ribbon-view-all-wrap {
	height: calc(100% - 10px);
	display: flex;
	justify-content: center;
	align-items: center;
}

.property-slider-widget-2 .property-spotlight-slide-2.ribbon-view-all-wrap .ribbon-view-all-icon {
	margin-bottom: 20px;
}

.property-slider-widget-2 .property-spotlight-slide-2.ribbon-view-all-wrap .ribbon-view-all-icon img {
	width: auto;
	margin: 0 auto;
	transition: 0.3s filter;
}

.property-slider-widget-2 .property-spotlight-slide-2.ribbon-view-all-wrap .ribbon-view-all-link:hover .ribbon-view-all-icon img {
	filter: brightness(90%);
}

.property-slider-widget-2 .property-spotlight-slide-2.ribbon-view-all-wrap .ribbon-view-all-link {
	text-transform: uppercase;
	font-weight: bold;
	text-decoration: none;
	border-bottom: 1px solid transparent;
	font-size: 20px;
	color: #333;
	height: 100%;
	width: 100%;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.add_to_cart_form.home {
	width: 100%;
}

.owl-carousel .owl-stage {
	margin: 0 auto;
}

button[type="submit"].add-to-cart-icon-4 {
	margin: 0;
	padding: 0;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	border: 0 none !important;
	background-color: #ffcb05;
	color: #fff;
	letter-spacing: 0;
	font-size: 0.75rem;
	line-height: 1rem;
	background-image: url('/20190619081922/assets/images/homepage-icons_triangle-LT-GREY-36.svg'), url('/20190619081922/assets/images/cart-icons-15.svg');
	background-position: 0% 50%, 84% 50%;
	background-size: contain, 25px 25px;
	background-repeat: no-repeat, no-repeat;
	text-decoration: none;
}

button[type="submit"].add-to-cart-icon-4:hover {
	background-color: hsla(47.51999999999999, 100%, 44.33%, 1) !important;
	box-shadow: none;
}

.present-text-wrapper .arrow-link:hover {
	background-color: transparent;
}

.property-slider-widget-2 {
	padding-left: 0;
	padding-right: 0;
}

.owl-carousel.owl-theme.property-spotlight-mask-2 {
	padding-left: 80px;
	padding-right: 70px;
}

.owl-carousel .owl-item {
	padding-right: 2px;
}

.property-slider-arrow-link-4.owl-prev {
	position: absolute;
	top: 40%;
	left: 20px;
}

.property-slider-arrow-link-4.owl-next {
	position: absolute;
	top: 40%;
	right: 15px;
}

.property-slider-arrow-link-4.owl-next.disabled,
.property-slider-arrow-link-4.owl-prev.disabled {
	visibility: hidden;
}

.ribbon-banner {
	background-image: none;
	padding: 0;
	min-height: auto;
}

.ribbon-banner .cms-website-content-region p {
	margin-bottom: 0;
}

.all-ribbons .item-add-to-cart {
	width: 100%;
}

.listing-details-column {
	height: 60px;
	justify-content: center;
}

.stephanis-drop {
	z-index: 1 !important;
}

.add-to-cart-text-2.pre-order,
.add-to-cart-text-2.pre-order-date {
	max-width: 200px;
}

button[type="submit"].wishlist-save-icon-2 {
	margin: 0;
	background-color: #ddd;
	border: 0 none !important;
}

button[type="submit"].wishlist-save-icon-2:hover {
	background-color: #ddd !important;
}

.property-spotlight-image-link-3.no-image-available {
	opacity: 0.7;
}

.property-spotlight-image-link-3 {
	background-size: auto 185px;
	border-bottom: 1px solid #dadada !important;
}

/*left nav*/
.left-nav-nested-list .left-nav-nested-list .left-nav-nested-list-link {
	padding-left: 2.5rem;
	background-position: 24px 50%;
}

.left-nav-list-link.selected {
	background-image: url('/20190619081922/assets/images/nav-arrow_blue-01.svg');
	background-position: 0px 50%;
	background-size: 11px 11px;
	background-repeat: no-repeat;
	color: #666;
}

.left-nav-nested-list-link.selected {
	background-image: url('/20190619081922/assets/images/nav-arrow_blue-01.svg');
	background-position: 12px 50%;
	background-size: 11px 11px;
	color: #666;
}

/*left sidebar*/
.left-sidebar-custom {
	padding-right: 20px;
	font-size: 0.875rem;
	line-height: 1.5em;
}

.left-sidebar-custom h1,
.left-sidebar-custom h2,
.left-sidebar-custom h3,
.left-sidebar-custom h4 {
	margin-top: 0rem;
	margin-bottom: 0.75rem;
	font-size: 1.25rem;
	line-height: 1.775rem;
	text-transform: none;
	font-weight: 600;
	letter-spacing: 0.01rem;
	color: #333;
}

/*right column*/
.right-sidebar-column .box {
	position: relative;
	margin-bottom: 2.441em;
	margin-left: 1em;
	border-top: 1px none rgba(0, 0, 0, 0.15);
	font-size: 0.875rem;
	line-height: 1.5em;
}

.right-sidebar-column h1,
.right-sidebar-column h2,
.right-sidebar-column h3,
.right-sidebar-column h4 {
	margin-top: 0rem;
	margin-bottom: 0.75rem;
	font-size: 1.25rem;
	line-height: 1.775rem;
	text-transform: none;
}

.box.documents .content > table {
	margin-bottom: 0.75rem;
}

.box.documents .content td:first-of-type {
	padding-right: 10px;
	width: 35px;
}

.box .contentbox_item {
	margin-bottom: 0.75rem;
}

/*styles*/
/*intro text*/
.intro_text_style {
	font-size: 1.1875em;
	line-height: 1.5em;
}

/*blockquotes*/
blockquote {
	border-left-color: #ffcb05;
	background-color: #f8f8f8;
	color: #333;
	font-size: 1.15rem;
	line-height: 1.75rem;
	font-weight: 400;
}

blockquote p {
	color: inherit;
	font-size: inherit;
	line-height: inherit;
	font-family: inherit;
	font-weight: inherit;
}

blockquote p:last-of-type {
	margin-bottom: 0;
}

/*horizontal ruler*/
.divider,
.cms_divider,
.cms_item_divider,
.item_divider {
	height: 1px !important;
	margin-top: 1.5em !important;
	margin-bottom: 1.5em !important;
	background-color: rgba(0, 0, 0, 0.15) !important;
	border-top: 0 none !important;
	border-bottom: 0 none !important;
}

hr:not(.fc-divider):not(.formBreak) {
	border-color: rgba(0, 0, 0, 0.15);
	border: 0px;
	color: rgba(0, 0, 0, 0.15);
	margin-bottom: 1.5em;
	margin-top: 1.5em;
	border-top: 1px solid;
}

/*messages*/
#session-message-wrapper .success {
	background-color: #079429;
	text-align: left;
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	color: #fff;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 400;
}

#session-message-wrapper .error {
	background-color: #c23737;
	text-align: left;
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	color: #fff;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 400;
}

#session-message-wrapper .success p,
#session-message-wrapper .success ul li,
#session-message-wrapper .error p,
#session-message-wrapper .error ul li {
	margin-top: 0;
	color: #fff;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 400;
}

.success *:last-child,
.error *:last-child {
	margin-bottom: 0;
}

#session-message-wrapper .success a,
#session-message-wrapper .error a {
	font-weight: 400;
	color: #fff;
	border-bottom-color: #fff;
}

#session-message-wrapper .success a:hover,
#session-message-wrapper .error a:hover {
	color: #fff;
	border-bottom-color: transparent;
}

/*images*/
img {
	height: auto;
}

td img {
	max-width: none;
}

@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
		margin-left: 0 !important;
		margin-right: 0 !important;
	}

	.owl-carousel.owl-theme.property-spotlight-mask-2 {
		padding-left: 70px;
		padding-right: 70px;
	}

	.property-slider-arrow-link-4.owl-next {
		right: -14px;
	}

	.property-slider-arrow-link-4.owl-prev {
		left: -14px;
	}
}

/*tables*/
table.styled {
	width: 100%;
	margin: 20px 0 10px 0;
	background-color: transparent;
	border-spacing: 0px;
}

table.styled th,
table.styled td {
	border-right: 1px solid white;
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

table.styled thead tr th {
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.625rem 0.625rem;
	text-align: left;
	background-color: #676767;
	color: white;
}

table.styled th {
	font-weight: normal;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.5rem 0.625rem;
	text-align: left;
	background-color: #b6b6b6;
	color: white;
}

table.styled th a {
	color: inherit;
	text-decoration: underline;
	border-bottom: none;
	font-weight: 400;
}

table.styled th a:hover {
	color: inherit;
	text-decoration: none;
}


table.styled td {
	padding: 0.375rem 0.625rem;
	font-size: 0.96rem;
	line-height: 1.4rem;
}

table.styled.striped tr:nth-child(even) {
	background: rgba(0, 0, 0, 0.05);
}

table.styled.striped tr:nth-child(odd) {
	background: transparent;
}

@media (max-width: 39.9375em) {
	table.styled.tablesaw {
		border: 1px solid grey;
	}

	table.styled.tablesaw th,
	table.styled.tablesaw td {
		border-right: 0 none;
		border-bottom: 0 none;
	}

	.tablesaw-stack tbody {
		border-top: 1px solid grey;
	}

	.tablesaw-stack tbody tr {
		border-bottom: 0 none;
	}
}

/*forms*/
.cms_label,
.label,
label {
	font-weight: 400;
}

label {
	display: inline;
	margin-bottom: 0;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="search"],
textarea,
select {
	font-size: 0.875rem;
	line-height: 1.42857143;
	padding: 4px 10px;
	border: 1px solid #cccccc;
	color: #333333;
}

textarea {
	resize: vertical;
}

input[type="search"].search-input-hidden {
	padding-left: 0px;
	border-style: none none solid;
	border-width: 1px;
	border-color: #fff #fff #333;
}

input[type="search"].search-input-hidden:focus {
	border-bottom-color: #ffcb05;
	color: #000;
}

/*links*/
a {
	cursor: pointer;
}

.search-facets a,
#products_module.responsive .item-wrapper .product-availability a,
a.s-continue-shopping.js-addtocartpopup-close,
.main-content-column a {
	border-bottom: 1px solid #ffc447;
	color: #666;
	font-weight: 500;
	text-decoration: none;
	cursor: pointer;
}

.search-facets a:hover,
#products_module.responsive .item-wrapper .product-availability a:hover,
a.s-continue-shopping.js-addtocartpopup-close:hover,
.main-content-column a:hover {
	border-bottom-color: transparent;
	color: #333;
}

.main-content-column h1 a,
.main-content-column h2 a,
.main-content-column h3 a,
.main-content-column h4 a,
.main-content-column h5 a,
.main-content-column h6 a {
	border-bottom-width: 2px;
	border-bottom-color: hsla(194.1322314049587, 0%, 72%, 0.5);
	color: #666;
}

.main-content-column h1 a:hover,
.main-content-column h2 a:hover,
.main-content-column h3 a:hover,
.main-content-column h4 a:hover,
.main-content-column h5 a:hover,
.main-content-column h6 a:hover {
	border-bottom-color: hsla(194.1322314049587, 0%, 72%, 0.5);
	color: #333;
}

/*buttons*/
.button,
.primary,
.secondary,
.tertiary,
button[type="submit"] {
	display: inline-block;
	margin-top: 0.5rem;
	margin-right: 0.5rem;
	margin-bottom: 0.5rem;
	padding: 0.5rem 1.5rem !important;
	border: 1px solid #ffcb05 !important;
	background-color: #ffcb05;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	color: #333;
	font-size: 0.85rem;
	font-weight: 600;
	text-align: center;
	letter-spacing: 0.05rem;
	text-decoration: none;
	text-transform: uppercase;
	cursor: pointer;
}

.button:hover,
.primary:hover,
.secondary:hover,
.tertiary:hover,
button[type="submit"]:hover {
	border-color: #666 !important;
	background-color: #666 !important;
	color: #fff;
}

.button.tertiary,
.tertiary,
button[type="submit"].tertiary {
	padding-right: 1rem !important;
	padding-left: 1rem !important;
	border-width: 2px !important;
	border-color: #ffcb05 !important;
	background-color: #fff;
	color: #333;
	font-size: 0.85rem;
	line-height: 1.5em;
}

.button.tertiary:hover,
.tertiary:hover,
button[type="submit"].tertiary:hover {
	border-color: hsla(194.1322314049587, 0%, 72%, 0.5) !important;
	background-color: #fff !important;
	color: #666;
}

.button.secondary,
.secondary,
button[type="submit"].secondary {
	border-color: #b5b5b5 !important;
	background-color: #b5b5b5;
}

.button.secondary:hover,
.secondary:hover,
button[type="submit"].secondary:hover {
	border-color: #ffcb05 !important;
	background-color: #ffcb05 !important;
	color: #333;
}

/*button links*/
a.primary,
a.secondary,
a.tertiary {
	font-weight: 600;
	color: #333;
}

a.primary:hover {
	color: #fff;
}

/*Custom inside page banner, placed as "header content" (for dynamic pages) or "description" (for ecommerce categories) or "content" (for static pages)
For this to work, we need to add a 1 row table containing one linked image in each td. The table should have the class "banner"*/
table.banner {
	width: 100% !important;
	border: 0 none;
	margin-left: 0.35rem;
	margin-top: 1rem;
}

table.banner tr {
	display: flex;
}

table.banner td {
	flex: 1 1 auto;
	border: 0 none;
}

table.banner td a {
	width: 100%;
}

table.banner td img {
	width: 100%;
}

@media (min-width: 992px) {
	/*header styles*/
	.search-column {
		display: block !important;
	}

	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened ~ .mm-slideout {
		-webkit-transform: translate(0, 0);
		-ms-transform: translate(0, 0);
		transform: translate(0, 0);
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	.menu-button-close {
		display: none !important;
	}

	.mobile-navigation-menu {
		display: none !important;
	}
}

@media (max-width: 1330px) {
	.mega-div-wrap {
		max-height: 750px;
	}
}

@media (max-width: 991px) {
	/*header styles*/
	.header-section {
		top: 0;
	}

	.search-column {
		display: none;
	}

	input[type="search"].search-input-hidden,
	input[type="search"].search-input-hidden:focus {
		border-bottom: 0 none;
	}

	.menu-button.w-nav-button:hover .mobile-menu-icon {
		color: #e4c700;
	}

	.mobile-navigation-menu.mm-menu_opened {
		background-color: #fff;
		display: block !important;
	}

	.main-nav-link.mobile-duplicate {
		width: 100%;
	}

	.extra-links.mm-listitem {
		background-color: #f0f0f0;
	}

	.mm-listitem:after {
		left: 0;
		border-bottom: 1px solid rgba(93, 63, 58, 0.15);
	}

	.extra-links.mm-listitem:after {
		border-bottom: 0 none;
	}

	.mm-menu .mm-btn_prev:hover,
	.mm-menu .mm-navbar__title:hover,
	.mm-menu .mm-btn_next:hover {
		color: #fff;
		background-color: #f1f1f1;
		cursor: pointer;
	}

	.mm-listitem .mm-btn_next:not(.mm-btn_fullwidth) {
		width: 15%;
		background-color: transparent;
		border-bottom-color: rgba(93, 63, 58, 0.15);
	}

	.mm-listitem:hover .mm-btn_next.mm-btn_fullwidth {
		background-color: transparent;
	}

	.mm-listitem .mm-btn_next:before {
		border-left-width: 1px;
		border-left-color: rgba(93, 63, 58, 0.15);
	}

	.mm-menu .mm-btn:after,
	.mm-menu .mm-btn:before,
	.mm-menu .mm-listview .mm-btn_next:after {
		border-color: rgba(93, 63, 58, 0.35);
	}

	.mm-btn_next:after,
	.mm-btn_prev:before {
		width: 9px;
		height: 9px;
		border-top: 3px solid transparent;
		border-left: 3px solid transparent;
	}

	.mm-btn_next:after {
		right: 6.5%;
	}

	.mm-btn_prev:before {
		margin-left: 0;
		left: 17px;
	}

	.mm-menu .mm-listview .mm-btn_next:hover {
		cursor: pointer;
	}

	.mm-menu .mm-listview .mm-btn_next + a {
		width: 85%;
		margin-right: 15%;
	}

	.mm-navbar {
		border-bottom: 1px solid rgba(93, 63, 58, 0.15);
		padding: 0 10px 0 40px;
		height: 40px;
	}

	.mm-menu .mm-navbar a,
	.mm-menu .mm-navbar > * {
		color: #333;
		height: 40px;
		border-bottom: 0 none !important;
	}

	.mm-navbar__title {
		font-size: 1rem;
		line-height: 1.35rem;
		font-weight: 600;
		text-align: left;
		padding-left: 8px !important;
		text-transform: uppercase;
		border-bottom: 0 none;
		color: #333;
	}

	.mm-navbar {
		background-color: #ffcb05;
	}

	.mm-navbar:hover {
		cursor: pointer;
		background-color: #ffcb05;
		border-bottom: 0px;
	}

	.mm-menu .mm-btn_prev:hover,
	.mm-navbar:hover .mm-navbar__title {
		background-color: #ffcb05;
		color: #333;
	}

	.mm-navbar:hover .mm-btn_prev:before {
		border-color: #f1f1f1;
	}

	.menu-custom-btns {
		height: 40px;
		text-align: right;
	}

	.menu-custom-btns .mobile-menu-close,
	.menu-custom-btns .mobile-menu-first-level {
		width: 40px;
		height: 40px;
		display: inline-block;
		color: #000;
		font-size: 2rem;
		float: right;
		text-align: center;
		font-family: 'webflow-icons' !important;
		background-color: #f0f0f0;
	}

	.menu-custom-btns .mobile-menu-close {
		font-size: 1rem;
	}

	.menu-custom-btns .mobile-menu-first-level {
		margin-right: 5px;
	}

	.menu-custom-btns .mobile-menu-first-level:after {
		content: "\e602";
	}

	.menu-custom-btns .mobile-menu-close:after {
		content: "\e900";
	}

	.mm-navbar + .mm-listview .menu-custom-btns {
		position: absolute;
		top: 0;
		width: 100%;
	}

	.nav-item-with-icon {
		display: -webkit-box !important;
		display: -webkit-flex !important;
		display: -ms-flexbox !important;
		display: flex !important;
		padding: 0 !important;
	}

	/*slideshow*/
	.slider-section {
		padding-top: 97px;
	}

	.slide-text-wrap h1,
	.slide-text-wrap h2,
	.slide-text-wrap h3 {
		font-size: 35px;
		line-height: 39px;
	}

	.slide-text-wrap p {
		display: block;
		max-width: 400px;
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
		font-size: 16px;
	}

	/*content boxes*/
	.content-box-section {
		padding-bottom: 2rem;
	}

	.two-link-div-wrapper {
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.cb-link-box-div.large._2 {
		margin-top: 2rem;
	}

	/*ribbons*/
	.property-slider-arrow-link-4.owl-prev {
		top: 122%;
		left: -15px;
	}

	.property-slider-arrow-link-4.owl-next {
		top: 122%;
		right: -25px;
	}

	.prop-spotlight-details-wrapper-3 {
		margin-top: 0;
	}

	.property-spotlight-slide-2 {
		padding-left: 0;
		padding-right: 0;
	}

	/*right sidebar*/
	.right-sidebar-column .box {
		margin-left: 0;
	}

	/*Custom inside page banner*/
	table.banner {
		margin-left: 0;
	}


	.icon-search-2-column-wrap.mobile {
		position: absolute;
		right: 0;
		top: 22px;
		left: auto;
		width: 70px;
		display: block;
		text-decoration: none;
		border-bottom: 1px solid transparent;
	}


	.top-icon-link-block.header.stores.mobile {
		position: static;
		margin: 0 auto !important;
		padding: 0px !important;
		display: block;
	}

	.hidden-register-button-div.mobile {
		position: static;
		left: 0;
		top: 0;
		display: block;
		width: auto;
		margin-left: 0;
		padding-top: 0rem;
	}

	.hidden-register-button-div.mobile .basket-amounts-div-2 {
		width: calc(100% - 6px);
		padding: 0px;
		margin: 0px;
		margin-right: 0rem;
		padding-top: 0rem;
		position: static;
		left: auto;
		overflow-wrap: break-word;
		display: block;
		margin-right: 3px;
		margin-left: 3px;
	}

	.hidden-register-button-div.mobile .basket-amounts-div-2 span {
		display: inline-block;
	}
}

@media (max-width: 767px) {
	.menu-button-close {
		background-size: 27px;
		left: 23px;
		top: 11px;
	}

	.icon-search-2-column-wrap.mobile {
		width: 60px;
		top: 15px;
	}

	/*header styles*/
	.brand-column {
		width: 100%;
	}

	.icon-search-2-column-wrap {
		position: fixed;
		top: 0.9rem;
		bottom: auto;
		display: flex;
		width: 11.5rem;
		padding-top: 0.8rem;
		padding-bottom: 0rem;
	}

	.top-icon-link-block.header.stores {
		width: 55px;
		margin-top: 15px;
	}

	.hidden-register-button-div {
		position: absolute;
		left: -4.2rem;
	}

	.basket-amounts-div-2 {
		left: -1.25rem;
		display: flex;
	}

	/*slideshow*/
	.slider-section {
		padding-top: 84px;
	}

	.slide-text-wrap h1,
	.slide-text-wrap h2,
	.slide-text-wrap h3 {
		padding-top: 1px;
		padding-right: 10px;
		padding-left: 8px;
		font-size: 26px;
		line-height: 32px;
	}

	.slide-text-wrap p {
		display: none;
	}
	/*ribbons*/

	.property-slider-widget-2 .property-spotlight-slide-2 {
		margin-left: 4px;
	}

	.property-slider-arrow-link-4.owl-next {
		right: -14px;
	}

	.mobile-navigation-menu {
		top: 84px;
	}

	.mobile-navigation-menu.extra-space {
		top: 124px;
	}
}

@media (max-width: 479px) {
	.hidden-register-button-div.mobile {
		margin-top: -6px;
	}

	.icon-search-2-column-wrap.mobile {
		top: 0;
		width: 55px;
	}

	.mobile-navigation-menu {
		top: 56px;
	}

	.mobile-navigation-menu.extra-space {
		top: 96px;
	}

	.menu-button-close {
		background-size: 22px;
		left: 0;
		top: 0;
	}

	/*header styles*/
	.icon-search-2-column-wrap {
		right: -108px;
		top: 0.1rem;
	}

	.hidden-register-button-div {
		top: 41.2031px;
	}

	.basket-icon-2 {
		max-height: 20px;
		max-width: 20px;
		margin-top: 0.25rem;
	}

	.basket-amounts-div-2 {
		font-size: 0.625rem;
		line-height: 0.6rem;
	}

	/*slideshow*/
	.slider-section {
		padding-top: 57px;
	}

	.slide-text-wrap h1,
	.slide-text-wrap h2,
	.slide-text-wrap h3 {
		font-size: 28px;
		line-height: 32px;
	}

	.owl-carousel.owl-theme.property-spotlight-mask-2 {
		padding-left: 50px;
		padding-right: 50px;
	}

	.property-slider-arrow-link-4.owl-next {
		right: -6px;
	}

	.property-slider-arrow-link-4.owl-prev {
		left: -6px;
	}

	.ui-form-buttons.ui-form-field-payment {
		padding: 0px !important;
		text-align: center;
	}
}
