/*module styling*/
/****************** General ************************/
/***************************************************/
/*horizontal ruler*/
.divider,
.cms_divider,
.cms_item_divider,
.item_divider,
.separator,
#submissionforms_module.cms_form .formBreak,
.module-forms .formBreak {
	height: 1px !important;
	margin-top: 1.5rem !important;
	margin-bottom: 1.5rem !important;
	background-color: rgba(0, 0, 0, 0.15) !important;
	border-top: 0 none !important;
	border-bottom: 0 none !important;
}

.collection > .node {
	border-top: 1px solid rgba(0, 0, 0, 0.15);
	margin: 1.5rem 0 0 0;
	padding: 1.5rem 0 0 0;
}

.section_separator {
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

/* dates */
#events_module.cms_list .cms_date,
#news_module.cms_list article time,
#news_module.cms_entity .cms_date h3 {
	font-weight: 500;
	color: #333;
}

/* ui form tags */
.ui-form-error {
	display: none;
}

.ui-form-container {
	border: 0px;
}

.ui-form-container .ui-form-legend {
	background: none;
	padding: 0px;
	border-bottom: 0px;
}

.ui-form-container .ui-form-fields {
	border-bottom: 0px;
}

.ui-form-container .ui-form-fields .ui-form-field.required .ui-form-label em,
.ui-form-container .required_information em {
	color: #eb0000;
	font-style: italic;
}

.ui-form-container .ui-form-fields .select2-container-multi .select2-choices {
	border: 1px solid #ccc;
	border-radius: 0px;
	padding: 4px 10px;
	font-size: 0.875rem;
	line-height: 1.42857143;
	color: #333333;
	box-shadow: none;
}

.ui-form-container .ui-form-fields .select2-container-multi .select2-choices .select2-search-field .select2-input {
	padding: 0px;
}

.ui-form-container .ui-form-fields .select2-container .select2-choice {
	box-shadow: none;
	border-radius: 0px;
	background: none;
	border: 1px solid #ccc;
}

.ui-form-buttons {
	padding: 0px;
	width: 100%;
	text-align: left;
}

.ui-form-note {
	line-height: 1rem;
	margin-top: 5px;
}

@media (max-width: 991px) {
	.ui-form-fields {
		padding: 0px;
	}

	.ui-form-label {
		position: static;
		padding: 0px;
		height: auto;
		display: block;
		width: 100%;
		left: 0px;
		text-align: left;
		top: auto;
		line-height: inherit;
	}

	.ui-form-input {
		width: 100%;
	}

	.ui-form-input input[type="text"],
	.ui-form-input input[type="password"],
	.ui-form-input input[type="email"],
	.ui-form-input input[type="file"],
	.ui-form-input select,
	.ui-form-input textarea,
	.ui-form-fields .select2-container,
	.ui-form-fields .select2-container-multi,
	.ui-form-fields .select2-container-multi .select2-choices .select2-search-field .select2-input {
		width: 100% !important;
		max-width: 100%;
	}

	.ui-form-panel {
		padding: 0 !important;
	}

	.ui-form-buttons {
		text-align: left;
	}
}

@media (max-width: 479px) {
	.ui-form-buttons button {
		margin-bottom: 10px;
		width: auto;
	}
}

/****************** Accounts ************************/
/***************************************************/
/*accounts*/
#account_module.form .form_container .login_field {
	width: 100%;
	max-width: 560px;
	margin-bottom: 15px;
}

#account_module.form .form_button.primary {
	width: auto !important;
}

#account_module.form .more_options ul {
	width: 100%;
	text-align: center;
	padding-left: 0;
}

#account_module.form .form_buttons {
	margin-top: 0;
}

#account_module.form .form_container input[type="password"] {
	margin-bottom: 10px;
}

#account_module.form .form_subheading {
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	font-family: Roboto, sans-serif;
	color: #333;
	font-size: 1.875rem;
	line-height: 2.375rem;
	font-weight: 600;
	letter-spacing: 0.02rem;
}

/*address form*/
#account_module .select2-container .select2-choice abbr {
	display: block;
	opacity: 0.7;
}

#account_module .select2-container.select2-container-disabled .select2-choice {
	background-color: rgb(235, 235, 228);
}

#account_module .select2-container .select2-choice {
	border: 1px solid #cccccc;
	border-radius: 0;
	height: auto;
	min-height: 30px;
}

/*edit address form*/
#account_module.form .ui-form-fields {
	margin-bottom: 10px;
}

#account_module.form .ui-form-label {
	margin-bottom: 3px;
}

#account_module.form .ui-form-label em {
	color: #eb0000;
	font-style: italic;
}

#account_orderhistory .order_section tr + tr {
	border-top: 1px solid #666;
}

#account_orderhistory .order_section tr + tr td:first-of-type {
	padding-top: 10px;
}

#account_orderhistory .order_section {
	width: 100%;
}

@media (max-width: 991px) {
	#account_module.form .form_buttons {
		text-align: left;
	}

	#account_module.form tr.required .field {
		background: none;
		padding-left: 0;
		display: block;
		width: 100%;
	}

	#account_module.form .form_item .label,
	#account_module.form .form_item.required .label {
		display: inline-block;
		width: 100%;
		text-align: left;
		padding-bottom: 5px;
	}

	#account_module.form .form_item .label label,
	#account_module.form .form_item.required .label label {
		display: inline;
	}

	#account_module.form tr.required .label:after {
		content: url('/core/public/shared/assets/images/required.gif');
		padding-left: 10px;
	}

	#account_module.form .form_item .field {
		display: block;
		padding-left: 0;
	}

	#account_module.form .more_options ul {
		text-align: left;
	}

	#account_orderhistory .order_section td {
		display: block;
		width: 100%;
		padding-bottom: 15px;
		text-align: left !important;
	}
}

@media (max-width: 767px) {
	#account_addresses .address_section td {
		display: block;
		width: 100%;
	}

	#account_addresses .address_section td br {
		display: none;
	}

	#account_addresses .address_section td a + br {
		display: block;
	}

	.accounts-history-pagination .pagination-wrapper td {
		display: block;
		width: 100%;
		text-align: center;
	}
}

@media (max-width: 479px) {
	#account_module.form .ui-form-fields input[type="text"],
	#account_module.form .ui-form-fields select {
		width: 100% !important;
	}
}

/****************** Events *********************/
/***********************************************/
#events_module.cms_list .event-current-day {
	padding-top: 1.5em;
	overflow: hidden;
}

#events_module.cms_list div.events_module_calendar .fc-view-container .fc-event {
	display: inline-block;
	width: calc(100% - 4px);
	box-shadow: none;
}

#fullcalendar-controls #categories .category-wrapper .category {
	top: 0px;
}

#events_module .category-wrapper {
	padding: 0;
	border-bottom: 0 none;
}

#events_module.cms_list .cms_date.cms_date_with_time_comments {
	margin-bottom: 10px;
}

#events_module.cms_list .cms_list_item .cms_title {
	width: 100%;
	font-size: 1rem;
}

#events_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0px;
	margin-bottom: 10px;
	padding-top: 0px;
}

#events_module.cms_list .cms_list_item .event-category-label {
	display: inline-block;
	margin-right: 4px;
}

#events_module.cms_list .event-category-label {
	margin-bottom: 10px;
}

#events_module.cms_form .cms_label,
#events_module.cms_form .cms_field,
#events_module.cms_form .cms_required,
#events_module.cms_form .cms_non_required,
#events_module.cms_form .cms_td_answers {
	background-color: transparent;
	vertical-align: top;
}

#events_module.cms_form .cms_required {
	background-position: center 10px;
}

.primary.event-registration-button {
	margin-top: 10px;
}

#events_module.cms_entity .cms_content > .cms_divider:first-of-type {
	display: none;
}

@media (max-width: 991px) {
	#events_module.cms_form .cms_field input[type="text"],
	#events_module.cms_form .cms_field input[type="password"],
	#events_module.cms_form .cms_field select,
	#events_module.cms_form .cms_field textarea,
	#events_module.cms_form .cms_field {
		display: block;
		clear: both;
		width: 100% !important;
		max-width: 100%;
	}

	#events_module.cms_form .cms_label {
		display: inline;
		text-align: left;
		padding-left: 0px;
		width: auto;
		float: left;
	}

	#events_module.cms_form .cms_required,
	#events_module.cms_form .cms_non_required {
		width: 20px;
		display: inline;
		text-align: left;
		padding-left: 0px;
		float: left;
	}
}

@media (max-width: 767px) {
	#events_module.cms_form .cms_info_req {
		float: none;
		text-align: right;
	}
}

/****************** FAQs *********************/
/***********************************************/
#faqs_module .cms_title h3 {
	margin-top: 0;
}

#faqs_module .cms_list_item > ul {
	margin: 0;
	list-style-type: disc;
}

#faqs_module .cms_list_item ul li {
	padding-bottom: 7px;
}

#faqs_module.cms_list .cms_list_subitem {
	padding-bottom: 5px;
}

#faqs_module.cms_list .cms_list_item .description,
#faqs_module.cms_list .cms_list_subitem .description {
	padding: 0 0 7px;
	list-style-type: none;
}

.box.tools .content .contentbox_item form input {
	width: 100%;
}

.box.tools .content .contentbox_item form .primary {
	margin-top: 0;
}

/****************** Locations *******************/
/***********************************************/
#locations_module.list .locations_list_title,
#locations_module.list .location_types_title {
	border-color: rgba(0, 0, 0, 0.15);
}

#locations_module.list .metadata2.address,
#locations_module.list .location-item-details-inner .location-item-website {
	padding-bottom: 10px;
}

#locations_module_display_view_contentbox {
	text-align: left;
}

#locations_module.entity {
	overflow: hidden;
}

#locations_module.list .location-item {
	width: 100%;
}

/****************** News ***********************/
/***********************************************/
.box.archive {
	overflow: hidden;
}

#news_module.cms_list article {
	padding-bottom: 1px;
}

#news_module.cms_list .cms_categories_section .cms_category_item .image {
	margin-bottom: 10px;
}

#news_module .image {
	width: auto;
	height: 100%;
}

#news_module.cms_list .cms_metadata1.cms_title h3 {
	margin-top: 0;
}

#news_module.cms_list article h2 {
	padding-top: 0;
}

.news_module.cms_category_list.header {
	margin-bottom: 20px;
}

#news_module.cms_entity .cms_date h3 {
	margin-top: 0;
}

#news_module.cms_entity .quaternary_title {
	margin-top: 0;
}

.archive_links {
	overflow: hidden;
	margin-bottom: 0.75rem;
}

.archive_links a,
.archive_links a.selected {
	padding-bottom: 0;
}

@media (max-width: 991px) {
	#news_module.cms_entity .cms_content .image {
		float: none !important;
		margin-left: 0;
		height: auto !important;
	}

	#news_module.cms_list .cms_categories_row {
		margin-bottom: 20px;
	}
}

/***************** Resources *******************/
/***********************************************/
.moduleDocuments-rss-feed-link {
	border-bottom-width: 0 !important;
}

#moduleDocuments_module.cms_list .cms_item_description {
	margin-top: 5px;
}

#moduleDocuments_module.cms_list .icon {
	display: inline-block;
	width: auto;
	height: auto;
	min-height: 2rem;
	min-width: 2rem;
}

.moduleDocuments-rss-feed-link {
	border-bottom-width: 0 !important;
	box-shadow: none !important;
}

/*************** Submission Forms ***************/
/************************************************/
#submissionforms_module.cms_form .cms_divider {
	display: none;
}

#submissionforms_module.cms_form .cms_required em {
	display: none;
}

#submissionforms_module.cms_form tr[data-required="true"] .cms_label label:after,
#submissionforms_module.cms_form tr[data-required="true"] .cms_label div:after {
	content: "*";
	padding-left: 5px;
	font-style: italic;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span {
	padding: 1px 27px;
}

#submissionforms_module.cms_form .cms_steps_wrapper span a {
	border-bottom: 0 none;
}

#submissionforms_module.cms_form .sigWrapper.current {
	height: 102px;
	width: 100%;
	max-width: 302px;
	display: block !important;
}

#submissionforms_module.cms_form .sigPad input {
	display: block;
}

#submissionforms_module.cms_form .cms_field,
#submissionforms_module.cms_form .cms_required,
#submissionforms_module.cms_form .cms_label {
	padding-bottom: 15px;
}

#submissionforms_module.cms_form .formSection {
	margin-bottom: 15px;
}

#submissionforms_module.cms_form .cms_field .ui-datepicker-trigger {
	vertical-align: baseline;
}

#submissionforms_module.cms_form .formBreak,
.module-forms .formBreak {
	border: 0 none;
	border-top: 1px solid rgba(0, 0, 0, 0.15);
	margin-top: 1.5em;
	margin-bottom: 1.5em;
}

#submissionforms_module.cms_form .cms_field.currency::before,
.responsive #submissionforms_module.cms_form .cms_field.currency::before {
	top: 40%;
	transform: translate(0px, -60%);
}

#submissionforms_module .ui-form-field-subtitle .ui-form-label label,
#submissionforms_module .ui-form-field-subtitle .ui-form-label span {
	font-size: 100%;
	font-weight: 400;
	color: #333;
}

#submissionforms_module .ui-form-label span {
	color: #333;
	font-weight: 400;
}

#submissionforms_module.cms_form .cms_field.currency::before,
.responsive #submissionforms_module.cms_form .cms_field.currency::before {
	top: 26%;
}

#submissionforms_module.cms_form .paragraph-text {
	width: 100%;
	margin: 0;
	padding: 0;
	float: none;
	color: #333;
	font-size: 1rem;
	line-height: 1.5rem;
}

.module-forms.confirmation .step-title {
	color: #333;
	font-size: 1.5rem;
	line-height: 1.975rem;
	font-weight: 600;
	letter-spacing: 0.02rem;
	margin-top: 20px;
}

.module-forms.confirmation pre {
	overflow: hidden;
}

@media (max-width: 991px) {
	.module-forms.confirmation td {
		display: block;
		width: 100%;
	}
}

@media (max-width: 767px) {
	#submissionforms_module.cms_form .cms_required,
	#submissionforms_module.cms_form .cms_label {
		padding-bottom: 0;
	}

	#submissionforms_module tr[data-required="true"] .cms_label::after {
		content: "";
		padding-left: 0;
	}

	#submissionforms_module tr[data-required="true"] .cms_label label::after,
	#submissionforms_module tr[data-required="true"] .cms_label div::after {
		content: "*";
		padding-left: 5px;
	}

	#submissionforms_module.cms_form .cms_field.currency::before,
	.responsive #submissionforms_module.cms_form .cms_field.currency::before {
		top: 37%;
		left: 4px;
	}
}

/******************** Search ********************/
/************************************************/
body.module-search.controller-pages-search .filter-child-container {
	margin-left: 5px !important;
}

#products_module.responsive .items #transition-overlay {
	z-index: 100;
}

html.disable-scrolling {
	overflow: auto;
}

html.disable-scrolling body {
	overflow: auto;
}

.search-module.search-form-top p {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.search-module.search-form-top input[type="search"] {
	margin-right: 10px;
}

.header_search.fly-out-menu-list-container {
	position: fixed;
	top: 97px !important;
	max-width: 500px;
	z-index: 10000;
}

.fixed-header ~ .header_search.fly-out-menu-list-container {
	top: 65px !important;
}

.inside_search.fly-out-menu-list-container {
	width: 500px;
}

.fly-out-menu-list-container ul.fly-out-menu-list li a {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 7px;
}

.fly-out-menu-list-container ul.fly-out-menu-list li a .item-image {
	height: auto;
	margin-right: 7px;
	flex-basis: 70px;
	flex-shrink: 0;
}

.fly-out-menu-list-container ul.fly-out-menu-list li a .description {
	flex-basis: calc(100% - 70px);
}

.fly-out-menu-list-container ul.fly-out-menu-list li a .description .item-title.cms_title h3 {
	margin: 0;
	font-size: 1rem;
	line-height: 1.5rem;
	white-space: normal;
}

.fly-out-menu-list-container ul.fly-out-menu-list li a .description .item-sku {
	margin-top: 4px;
	color: #8b8b8b;
	font-size: 0.75rem;
	line-height: 1.1rem;
}

@media (max-width: 991px) {
	.header_search.fly-out-menu-list-container,
	.fixed-header ~ .header_search.fly-out-menu-list-container {
		top: 138px !important;
		left: 0 !important;
		width: 100%;
		max-width: 100%;
	}

	.inside_search.fly-out-menu-list-container {
		top: 190px !important;
	}
}

@media (max-width: 767px) {
	.header_search.fly-out-menu-list-container,
	.fixed-header ~ .header_search.fly-out-menu-list-container {
		top: 123px !important;
	}

	.inside_search.fly-out-menu-list-container {
		left: 1.5rem !important;
		width: 300px;
	}
}

/******************* eCommerce ******************/
/************************************************/
/*category level 1 & 2 list page*/
#products_module.responsive .category-wrapper {
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

#products_module.responsive .category-wrapper .category-div-wrapper a.shop-cat-link-div {
	border-bottom: 0 none;
}

.category-title.level-1 {
	width: auto;
	min-width: 172px;
	transition: min-width 300ms ease-in;
}

.shop-cat-link-div.level-2 {
	text-align: left;
	font-weight: 400;
}

.shop-cat-link-div.level-2:hover .category-title.level-1 {
	width: auto;
	min-width: 100%;
}

/*level 3 list page*/
.top-filtering-div {
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

.side-filter-buttons {
	-webkit-box-flex: 0;
	-webkit-flex: 0;
	-ms-flex: 0;
	flex: 0;
}

.top-filtering-div.ribbons-top-filtering {
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

#products_module.responsive.cms_list .top-filtering-div {
	display: inline-block;
}

#products_module.responsive.cms_list .top-filtering-div .side-filter-buttons {
	display: inline-block;
}

#products_module.responsive.cms_list .top-filtering-div .side-filter-buttons .search-filter-button {
	padding-left: 4px;
	padding-right: 4px;
	line-height: 1.4rem;
}

.side-filter-buttons.ribbons-side-filter-buttons {
	flex: 0;
}


a.newsletter-submit-button.search-filter-button {
	color: #000;
	background-color: hsla(0, 0%, 75%, 0.75);
	border: 0 none;
}

a.newsletter-submit-button.search-filter-button:hover {
	background-color: #ffcb05;
}

a.newsletter-submit-button.search-filter-button.selected {
	background-color: #ffcb05;
}

.compare-button.primary {
	padding: 2px 17px !important;
	font-size: 0.75rem;
	border: 0 none !important;
}

.compare-button.primary:hover {
	background-color: hsla(0, 0%, 74.5%, 0.75) !important;
	border: 0 none !important;
	color: #000;
}

#products_module.responsive.cms_list > .pagination-wrapper {
	margin-left: 0.35rem;
	width: 100%;
	margin-bottom: 5px;
	padding-top: 5px;
}

.pagination-wrapper .pagination-next-page,
.pagination-wrapper .pagination-previous-page {
	padding: 0;
}

#products_module.responsive .pagination-wrapper .pagination-next-page a,
#products_module.responsive .pagination-wrapper .pagination-previous-page a {
	border-bottom: 1px solid #ffc447;
	line-height: 1.5rem;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
}

#products_module.responsive .pagination-wrapper .pagination-next-page a:hover,
#products_module.responsive .pagination-wrapper .pagination-previous-page a:hover {
	border-bottom: 1px solid transparent;
}

.pagination-filter.pagination-bg.pagination-current-items-top {
	display: none;
}

.pagination-filter.pagination-bg.pagination-page-selection-top {
	justify-content: flex-end;
	font-size: 0.875rem;
}

.pagination-filter.pagination-bg.pagination-page-selection-top .fly-out-menu-container_PerPageSelection {
	margin-right: 0;
}

.fly-out-menu-container .fly-out-menu-button {
	border: 1px solid #cccccc;
	color: #333333;
	font-size: 0.875rem;
	line-height: 1.42857143;
	padding: 4px 10px;
}

/*level 3 list page*/
#products_module.responsive .category-heading {
	margin-bottom: 1rem;
}

#products_module.responsive .category-heading table tr td img {
	max-width: 100%;
}

.purchase-info-wrapper {
	position: fixed;
	bottom: 0;
	right: 20px;
	z-index: 2;
	box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.5);
}

#products_module.responsive.cms_entity .shippingitem,
.item-wrapper .shippingitem,
.purchase-info-wrapper .shippingitem {
	position: static;
	top: 232px;
	width: 100%;
	margin-top: 0px;
	margin-right: 0px;
	padding-top: 3px;
	padding-bottom: 3px;
	padding-left: 3px;
	float: none;
	clear: none;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	cursor: pointer;
	background-color: #e8e8e8;
}

#products_module.responsive.cms_entity .shippingitem {
	padding-left: 0;
}

#products_module.responsive.cms_entity .shippingitem,
.item-wrapper .shippingitem {
	background-color: transparent;
	height: 36px;
	border-bottom: 1px solid #fff;
}

#products_module.responsive.cms_entity .shippingitem {
	height: auto;
}

.purchase-info-wrapper .shippingitem:hover {
	background-color: #efefef;
}

#products_module.responsive.cms_entity .shippingitem .shippingiconwrapper,
.item-wrapper .shippingitem .shippingiconwrapper,
.purchase-info-wrapper .shippingiconwrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	min-height: auto;
	min-width: 50px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: transparent;
	border-bottom: 0 none;
}

#products_module.responsive.cms_entity .shippingitem .shippingicon,
.item-wrapper .shippingitem .shippingicon,
.purchase-info-wrapper .shippingicon {
	height: 30px;
	min-height: 0px;
	min-width: 35px;
	padding-bottom: 5px;
	background-color: transparent;
	background-position: 50% 50%;
	background-size: 24px 24px;
	background-repeat: no-repeat;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
}

#products_module.responsive.cms_entity .shippingitem .shippingicon.freedelivery,
.purchase-info-wrapper .shippingicon.freedelivery {
	background-image: url('/20190625124421/assets/images/homepage-icons-truckgrey-31.svg');
}

#products_module.responsive.cms_entity .shippingitem .shippingicon.clickcollect,
.purchase-info-wrapper .shippingicon.clickcollect {
	margin-bottom: 3px;
	background-image: url('/20190625124421/assets/images/ClickCollect-Gray.svg');
}

#products_module.responsive.cms_entity .shippingitem .shippingicon.checkavailability,
.purchase-info-wrapper .shippingicon.checkavailability {
	background-image: url('/20190625124421/assets/images/Availability-Gray.svg');
}

#products_module.responsive.cms_entity .shippingitem .shippingicon.stephaniscard,
.purchase-info-wrapper .shippingicon.stephaniscard {
	background-image: url('/20190625124421/assets/images/Card-Gray.svg');
}

#products_module.responsive.cms_entity .shippingitem .shippingicon.transfer,
.purchase-info-wrapper .shippingicon.transfer {
	background-image: url('/20190625124421/assets/images/Transfer-Gray.svg');
}

#products_module.responsive.cms_entity .shippingitem .shippingicon.mtn,
.item-wrapper .shippingitem .shippingicon.mtn,
.purchase-info-wrapper .shippingicon.mtn {
	background-image: url('/20190625124421/assets/images/epic.png');
}

#products_module.responsive.cms_entity .shippingitem .shippingttextwrapper,
.item-wrapper .shippingitem .shippingttextwrapper,
.purchase-info-wrapper .shippingttextwrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: auto;
	min-height: 0px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	opacity: 1;
}

#products_module.responsive.cms_entity .product-details-info-wrapper .price-and-shipping-div .shipping-box .shippingitem.mtn-wrapper .shippingiconwrapper {
	display: block;
	color: #333;
}

#products_module.responsive.cms_entity .product-details-info-wrapper .price-and-shipping-div .shipping-box .shippingitem.mtn-wrapper .shippingiconwrapper .mtn-plan {
	display: flex;
	margin-bottom: 3px;
}

#products_module.responsive.cms_entity .product-details-info-wrapper .price-and-shipping-div .shipping-box .shippingitem.mtn-wrapper .shippingiconwrapper .mtn-plan .shippingicon.mtn {
	width: 63px;
	height: auto;
	background-size: contain;
	background-position: 50%;
}

#products_module.responsive.cms_entity .product-details-info-wrapper .price-and-shipping-div .shipping-box .shippingitem.mtn-wrapper .shippingiconwrapper .mtn-plan .shippingttextwrapper {
	display: block;
	font-size: 0.85rem;
	line-height: 1.1rem;
	flex-grow: 1;
}

#products_module.responsive.cms_entity .product-details-info-wrapper .price-and-shipping-div .shipping-box .shippingitem.mtn-wrapper .shippingiconwrapper .mtn-plan .shippingttextwrapper .present-text {
	color: #000;
}

#products_module.responsive.cms_entity .product-details-info-wrapper .price-and-shipping-div .shipping-box .shippingitem.mtn-wrapper .shippingiconwrapper .mtn-plan .arrow-link {
	margin: 0;
	height: auto;
}

#products_module.responsive.cms_entity .product-details-info-wrapper .price-and-shipping-div .shipping-box .shippingitem.mtn-wrapper .shippingiconwrapper .mtn-cost {
	font-size: 0.8rem;
	font-weight: normal;
	line-height: 0.9rem;
}

#products_module.responsive.cms_entity .product-details-info-wrapper .price-and-shipping-div .shipping-box .shippingitem.mtn-wrapper .shippingiconwrapper .mtn-cost strong {
	color: #000;
}

#products_module.responsive.cms_entity .shippingitem .shippingttextwrapper,
.item-wrapper .shippingitem .shippingttextwrapper {
	width: auto;
}

.item-wrapper .shippingitem .present-text,
.purchase-info-wrapper .present-text {
	padding-right: 0.5rem;
	color: #676767;
	font-size: 0.7rem;
	line-height: 0.95rem;
	font-weight: 500;
	text-transform: capitalize;
}

.item-wrapper .shippingitem .present-text {
	font-size: 0.75rem;
	line-height: 1rem;
	color: #606060;
}

.purchase-info-wrapper .arrow-link {
	display: block;
	width: 18px;
	height: 18px;
	min-width: 18px;
	margin-right: 0.5rem;
	padding: 0.5rem 0.325rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	background-color: transparent;
	background-image: url('/20190625124421/assets/images/arrow-grey-icons-06.svg');
	background-position: 50% 50%;
	background-size: 12px 12px;
	background-repeat: no-repeat;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #000;
	font-size: 0.75rem;
	line-height: 0.85rem;
	text-align: center;
	text-decoration: underline;
}

.purchase-info-wrapper .modal-close-wrap-mini {
	position: absolute;
	top: -13px;
	right: -12px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 24px;
	height: 24px;
	float: right;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border: 1px none #000;
	border-radius: 50%;
	background-color: #efefef;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	text-align: center;
	box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.5);
}

.purchase-info-wrapper .modal-close-link-mini {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 24px;
	height: 24px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	opacity: 0.6;
	border-bottom: 0 none;
}

.purchase-info-wrapper .modal-x-mini {
	display: block;
	width: 16px;
	height: 16px;
}

/*product tile*/
.stephanis-card-dropdown .listing-details {
	text-transform: capitalize;
}

.item-add-to-cart.js-item-add-to-cart {
	width: 100%;
}

.spotlight-list-text.tile-product-name {
	font-weight: 500;
	-webkit-line-clamp: 2;
	height: 40px;
	overflow: hidden;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
}

.in-stock-product-code-div {
	align-items: center;
	justify-content: space-between;
	padding: 5px 0;
}

.in-stock-product-code-div.only-sku {
	justify-content: center;
}

.product-code,
.product-availability,
.product-select-for-compare {
	width: auto;
	font-size: 0.75rem;
	line-height: 1rem;
	white-space: nowrap;
}

.product-availability a {
	border-bottom: 1px solid #ffc447;
	color: #666;
	font-weight: 500;
	text-decoration: none;
	cursor: pointer;
}

.product-availability a:hover {
	border-bottom-color: transparent;
	color: #333;
}

.product-select-for-compare {
	cursor: pointer;
	color: #666;
}

.listing-details-heading.large-now-price {
	margin-top: -13px;
}

.listing-details-heading.large-now-price.with-sale {
	margin-top: 8px;
}

.listing-detail-column-wrapper-2:not(.trio) .large-now-price.centered span.item-price {
	margin-top: 8px;
	display: inline-block;
}

.spotlight-unordered-list.no-specs {
	min-height: 52px;
}

.spotlight-list-wrapper-3.simplified {
	min-height: 120px;
}

@media (max-width: 991px) {
	.compare-row {
		margin-right: 0;
	}

	#products_module.responsive.cms_list > .pagination-wrapper {
		margin-left: 0;
	}

	#cross_sells .items,
	#products_module.responsive .items.grid-view {
		margin-right: 0;
	}
}

@media (max-width: 720px) {
	.spotlight-unordered-list {
		min-height: 0;
	}

	#products_module.responsive .item-wrapper {
		padding-left: 0;
		padding-right: 0;
	}
}

/*add to cart popup*/
#addtocartpopup .product-added-info .addtocartpopup-image-wrapper {
	line-height: 1.5rem;
	margin-bottom: 0;
}

/*product details*/
#products_module.responsive.cms_entity .carousel-slider {
	margin-top: 26px;
}

#products_module.responsive.cms_entity .product-details-slideshow-wrapper {
	padding-right: 1vw;
	width: 40%;
}

#products_module.responsive.cms_entity .product-details-desc-text {
	margin-bottom: 10px;
	font-size: 0.9rem;
	line-height: 1.35rem;
}

#products_module.responsive.cms_entity .product-details-spec-column._1 {
	margin-bottom: 0;
}

#products_module.responsive.cms_entity .see-full-specs {
	border-bottom: 0 none !important;
	display: inline-block;
	margin-bottom: 1.5rem;
	padding-bottom: 0.25rem;
	padding-top: 0;
	font-size: 0.85rem;
	line-height: 1.2rem;
	opacity: 0.6;
	text-decoration: underline !important;
	color: #333 !important;
	font-weight: 700 !important;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

#products_module.responsive.cms_entity .see-full-specs:hover {
	opacity: 1;
}

#products_module.responsive.cms_entity .info-and-pricing {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

#products_module.responsive.cms_entity .product-details-info-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 60%;
	padding-right: 0vw;
	padding-left: 1vw;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	border-left: 1px solid rgba(0, 0, 0, 0.1);
}

#products_module.responsive.cms_entity .product-details-column {
	padding-left: 0;
	width: 100%;
}

#products_module.responsive.cms_entity .product-description-div {
	width: 55%;
	margin-bottom: 0rem;
}

#products_module.responsive.cms_entity .price-and-shipping-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 45%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

#products_module.responsive.cms_entity .listing-details-heading.large-now-price-product-details.details-pricing,
#products_module.responsive.cms_entity .listing-details-heading.product-details.details-pricing-red {
	font-size: 1.5rem;
}

#products_module.responsive.cms_entity .listing-details.large-was-price-product-details.centeredlisting {
	margin-top: 0.1rem;
	text-align: center;
}

#products_module.responsive.cms_entity .add-to-cart-region {
	margin-bottom: 1rem;
}

#products_module.responsive.cms_entity .notify-when-available-button {
	margin-bottom: 0;
	padding-left: 0px !important;
	padding-right: 0px !important;
}

#products_module.responsive.cms_entity .shippingitem .shippingiconwrapper {
	justify-content: flex-start;
}

#products_module.responsive.cms_entity .present-text {
	font-size: 0.85rem;
	font-weight: 700;
}

#products_module.responsive.cms_entity .shipping-box.more-info {
	background-color: hsla(0, 0%, 74.5%, 0.35);
}

#products_module.responsive.cms_entity .shipping-box.more-info .present-text {
	padding-right: 0.5rem;
	color: #676767;
	font-size: 0.7rem;
	line-height: 0.95rem;
	font-weight: 500;
	text-transform: capitalize;
}

#products_module.responsive.cms_entity .shipping-box.more-info .shippingttextwrapper {
	width: 100%;
}

#products_module.responsive.cms_entity .shippingitem.mtn-wrapper {
	margin-bottom: 1rem;
	background-color: hsla(0, 0%, 74.5%, 0.35);
	padding: 12px 8px;
}

#products_module.responsive.cms_entity .shippingitem.mtn-wrapper .present-text {
	font-size: 0.95rem;
}

#products_module.responsive.cms_entity .shippingicon.mtn {
	width: 40px;
	height: 40px;
	min-width: 40px;
	margin-right: 10px;
	margin-bottom: 0px;
	background-size: 40px 40px;
}

#products_module.responsive.cms_entity .product-detail-spec-label,
#products_module.responsive.cms_entity .product-detail-spec-label-numbers {
	border-bottom: 0 none;
	line-height: 1.2rem;
}

#products_module.responsive.cms_entity .product-details-specs-tbl {
	width: 100%;
}

#products_module.responsive.cms_entity .product-details-specs-tbl td {
	font-size: 0.85rem;
	font-weight: 400;
	vertical-align: top;
	padding-bottom: 10px;
	line-height: 1.1rem;
}

#products_module.responsive.cms_entity .product-details-specs-tbl td.label {
	font-weight: 700;
	width: auto;
	white-space: nowrap;
	padding-right: 10px;
}

#products_module.responsive.cms_entity .product-details-specs-tbl td.value {
	width: 99%;
}

#products_module.responsive.cms_entity .product-details-tabs .tab-item {
	padding-right: 1rem;
	padding-left: 1rem;
	border-style: solid solid none none;
	border-width: 1px;
	border-color: rgba(184, 184, 184, 0.5);
	background-color: #f1f1f1;
	border-radius: 0;
	margin-right: 0;
	font-weight: 700;
	color: #222222;
	text-transform: none;
}

#products_module.responsive.cms_entity .product-details-tabs .tab-item:hover {
	background-color: #f1f1f1;
	cursor: pointer;
}

#products_module.responsive.cms_entity .product-details-tabs .tab-item:not(.selected-tab-item):first-of-type {
	border-left: 1px solid rgba(184, 184, 184, 0.5);
}

#products_module.responsive.cms_entity .product-details-tabs .selected-tab-item {
	padding-right: 1rem;
	padding-left: 1rem;
	border-style: solid;
	border-width: 1px;
	border-color: rgba(184, 184, 184, 0.5);
	background-color: #ffcb05;
	border-radius: 0;
}

#products_module.responsive.cms_entity .product-details-tabs .selected-tab-item:hover {
	background-color: #ffcb05;
	cursor: pointer;
}

#products_module.responsive.cms_entity .product-details-tabs .tab-item-content[id|=stockAvailability], /*if id atttibute "starts with" substring*/
#products_module.responsive.cms_entity .product-details-tabs .tab-item-content[id|=specifications],
#products_module.responsive.cms_entity .product-details-tabs .tab-item-content[id|=colours],
#products_module.responsive.cms_entity .product-details-tabs .tab-item-content[id|=mtn] {
	padding: 0;
}

#products_module.responsive.cms_entity .product-details-tabs .tab-item-content {
	border-radius: 0;
}

#products_module.responsive.cms_entity .product-details-tabs .tab-item-content .specs-padding {
	padding: 15px;
}

#products_module.responsive.cms_entity .tab-content-wrapper {
	border-style: solid solid none;
	border-width: 1px;
	border-color: rgba(184, 184, 184, 0.5);
	background-color: #fff;
}

#products_module.responsive.cms_entity .tab-spec-list {
	margin-bottom: 0;
	padding-right: 10px;
}

#products_module.responsive.cms_entity .tab-spec-list-item {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: auto;
	padding: 0rem 0rem 0rem 1rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom: 1px solid rgba(184, 184, 184, 0.5);
	font-size: 0.85rem;
	line-height: 1rem;
	font-weight: 700;
}

#products_module.responsive.cms_entity .tab-spec-list-item .epic-plan-details {
	display: flex;
	align-items: center;
	font-weight: bold;
	width: 100%;
	padding-right: 10px;
	font-size: 0.95rem;
	align-items: flex-end;
}

#products_module.responsive.cms_entity .tab-spec-list-item .epic-plan-details > div {
	margin-right: 1%;
	width: 12%;
}

#products_module.responsive.cms_entity .tab-spec-list-item .epic-plan-details > div:last-child {
	margin-right: 0px;
}

#products_module.responsive.cms_entity .tab-spec-list-item .epic-plan-details div.icon {
	margin-right: 7px;
	width: 45px;
	align-self: center;
}

#products_module.responsive.cms_entity .tab-spec-list-item .epic-plan-details div.icon .mtn-tab-image {
	margin-right: 0px;
}

#products_module.responsive.cms_entity .tab-spec-list-item .epic-plan-details div.plan {
	width: calc(27% - 53px);
}

#products_module.responsive.cms_entity .tab-spec-list-item .epic-plan-details div.plan .plan-title {
	font-size: 1.3rem;
	font-weight: bold;
	line-height: 1.4rem;
}

#products_module.responsive.cms_entity .tab-spec-list-item .epic-plan-details div.price-container {
	flex-grow: 1;
	text-align: right;
	min-width: 24%;
}

#products_module.responsive.cms_entity .tab-spec-list-item .epic-plan-details div.price {
	text-align: center;
	margin-right: 0px;
	display: inline-block;
	min-width: 31%;
}

#products_module.responsive.cms_entity .tab-spec-list-item .epic-plan-details div.price .price-text {
	font-size: 1.4rem;
	line-height: 1.4rem;
	font-weight: bold;
}

#products_module.responsive.cms_entity .tab-spec-list-item .epic-plan-details span.small-text {
	font-size: 0.67rem;
	line-height: 0.7rem;
	font-weight: normal;
}

@media (max-width: 991px) {
	#products_module.responsive.cms_entity .tab-spec-list-item .epic-plan-details {
		font-size: 0.9rem;
		line-height: 0.9rem;
	}

	#products_module.responsive.cms_entity .tab-spec-list-item .epic-plan-details > div,
	#products_module.responsive.cms_entity .tab-spec-list-item .epic-plan-details div.plan,
	#products_module.responsive.cms_entity .tab-spec-list-item .epic-plan-details div.price.monthly {
		margin-left: 0px;
		margin-right: 2%;
	}

	#products_module.responsive.cms_entity .tab-spec-list-item .epic-plan-details div.plan .plan-title,
	#products_module.responsive.cms_entity .tab-spec-list-item .epic-plan-details div.price .price-text {
		font-size: 1.1rem;
		line-height: 1.1rem;
	}

	#products_module.responsive.cms_entity .tab-spec-list-item .epic-plan-details span.small-text {
		font-size: 0.6rem;
		line-height: 0.6rem;
	}
}

@media (max-width: 767px) {
	#products_module.responsive.cms_entity .tab-spec-list-item .epic-plan-details {
		flex-wrap: wrap;
		align-items: flex-start;
	}

	#products_module.responsive.cms_entity .product-details-wrapper .add-to-cart-region .add-to-cart-text-2.add-to-cart {
		font-size: 1.1rem;
		max-width: 200px;
	}

	#products_module.responsive.cms_entity .product-details-tabs .tab-item:after {
		background-image: url('../images/arrow-grey-icons-06.svg');
		background-repeat: no-repeat;
		background-position: center;
		background-size: 12px;
		content: "";
		display: inline;
		float: right;
		width: 12px;
		height: 24px;
	}

	#products_module.responsive.cms_entity .product-details-tabs .selected-tab-item:after {
		transform: rotate(90deg);
		height: 28px;
	}

	#products_module.responsive.cms_entity .tab-spec-list-item .epic-plan-details > div,
	#products_module.responsive.cms_entity .tab-spec-list-item .epic-plan-details div.plan {
		margin-left: 0px;
		margin-right: 7px;
		width: calc(33% - 7px);
		margin-bottom: 12px;
	}

	#products_module.responsive.cms_entity .tab-spec-list-item .epic-plan-details div.icon {
		width: 45px;
	}

	#products_module.responsive.cms_entity .tab-spec-list-item .epic-plan-details div.plan {
		width: calc(100% - 53px);
		margin-right: 0px;
	}

	#products_module.responsive.cms_entity .tab-spec-list-item .epic-plan-details div.price-container {
		flex-grow: 0;
		text-align: left;
		min-width: auto;
		width: 66%;
		display: flex;
	}

	#products_module.responsive.cms_entity .tab-spec-list-item .epic-plan-details div.price {
		text-align: left;
		width: 47%;
		min-width: auto;
	}
}

@media (max-width: 380px) {
	#products_module.responsive.cms_entity .tab-spec-list-item .epic-plan-details {
		font-size: 0.77rem;
	}
}

#products_module.responsive.cms_entity .tab-spec-list.specifications {
	width: 100%;
}

#products_module.responsive.cms_entity .tab-spec-list.specifications td {
	font-size: 0.85rem;
	font-weight: 400;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-right: 1rem;
	padding-left: 1rem;
	border-bottom: 1px solid rgba(184, 184, 184, 0.5);
	vertical-align: top;
}

#products_module.responsive.cms_entity .tab-spec-list.specifications td.label {
	white-space: nowrap;
	font-weight: 700;
	padding-right: 7px;
}

#products_module.responsive.cms_entity .tab-spec-list.specifications td.value {
	width: 99%;
	padding-left: 0px;
}

#products_module.responsive.cms_entity .tab-spec-list > .tab-spec-list-item:last-of-type {
	border-bottom: 0 none;
}

#products_module.responsive.cms_entity .tab-spec-list-item {
	font-size: 0.85rem;
	font-weight: 400;
	padding-top: 10px;
	padding-bottom: 10px;
}

#products_module.responsive.cms_entity .tab-item-content .tab-subtitle {
	padding: 10px 1rem;
	margin: 0px;
	border-bottom: 1px solid rgba(184, 184, 184, 0.5);
}

#products_module.responsive.cms_entity .tab-specifications .specs-padding {
	font-size: 0.85rem;
	font-weight: 400;
}

#products_module.responsive.cms_entity .tab-stockAvailability .tab-spec-list-item {
	padding-top: 3px;
	padding-bottom: 3px;
}

#products_module.responsive.cms_entity .mtn-tab-image {
	width: 45px;
	display: inline-block;
	margin-right: 10px;
}

#products_module.responsive.cms_entity .color-flex-row {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

#products_module.responsive.cms_entity .color-flex-col-1 {
	flex: 0 0 150px;
}

#products_module.responsive.cms_entity .color-flex-col-2 {
	flex: 1 1 calc(100% - 230px);
	padding: 0 15px;
}

#products_module.responsive.cms_entity .color-flex-col-3 {
	flex: 0 0 80px;
}

#products_module.responsive.cms_entity .color-flex-row .product-code-product-details {
	display: inline-block;
	margin-top: 5px;
	margin-bottom: 5px;
}

#products_module.responsive.cms_entity .listing-details-heading.grey-price {
	color: #606060;
}

#products_module.responsive.cms_entity .redx,
#products_module.responsive.cms_entity .greencheck,
#products_module.responsive.cms_entity .calltel {
	display: inline-block;
	width: 12px;
	vertical-align: middle;
}

#products_module.responsive.cms_entity .calltel {
	width: 15px;
}

#products_module.responsive.cms_entity .stockstatus {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
	height: auto;
	margin-top: 0rem;
	margin-bottom: 0rem;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	padding-left: 0.5rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-left: 1px solid hsla(0, 0%, 72.2%, 0.5);
}

#products_module.responsive.cms_entity .please-call-spec-text {
	margin-left: 5px;
	color: #ed7d31;
	line-height: 1.05rem;
	text-transform: uppercase;
	font-weight: 700;
	background-image: none;
	padding-left: 0;
}

#products_module.responsive.cms_entity .out-of-stock-spec-text {
	margin-left: 5px;
	color: #b91313;
	line-height: 0.85rem;
	text-transform: uppercase;
	font-weight: 700;
}

#products_module.responsive.cms_entity .in-stock-spec-text {
	margin-left: 5px;
	color: #71a753;
	line-height: 0.85rem;
	text-transform: uppercase;
	font-weight: 700;
}

#products_module.responsive.cms_entity .videos-tab-wrapper {
	display: block;
}

#products_module.responsive.cms_entity .video-wrapper {
	margin-bottom: 40px;
}

#products_module.responsive.cms_entity .videos-tab-video {
	min-width: 800px;
	min-height: 500px;
}

#products_module.responsive.cms_entity .freegifts {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-top: 20px;
	margin-bottom: 0px;
	-webkit-box-orient: horizontal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	border-top: 1px solid rgba(0, 0, 0, 0.15);
	padding-top: 20px;
}

#products_module.responsive.cms_entity .present-text-3.details-gift-text {
	text-transform: uppercase;
}

#products_module.responsive.cms_entity .freegiftitems {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	justify-content: flex-start;
}

#products_module.responsive.cms_entity .one-third {
	border-bottom: 0 none;
	text-align: center;
}

#products_module.responsive.cms_entity .product-detail-page.one-third {
	flex: 0 1 150px;
	text-align: left;
	position: relative;
	display: flex;
}

#products_module.responsive.cms_entity .present-icon-wrapper-3 {
	height: 100%;
	max-height: 158px;
}

#products_module.responsive.cms_entity .present-icon-wrapper-3:after {
	content: ' ';
	height: 0;
	position: absolute;
	width: 0;
	right: -158px;
	border: 79px solid transparent;
	border-left-color: hsla(0, 0%, 75%, 0.35);
}

#products_module.responsive.cms_entity .freegiftblock.one-third {
	padding-left: 40px;
}

#products_module.responsive.cms_entity .freegiftitems .freegiftblock.one-third:first-of-type {
	padding-left: 100px;
}

#products_module.responsive.cms_entity img.image-3 {
	max-height: 100px;
}

#products_module.responsive.cms_entity .product-details-wrapper .product-details-column .freegifts .freegiftitems .free-gift-image img.image-3 {
	max-height: 130px;
}

#products_module.responsive.cms_entity .present-icon-2 {
	min-height: 75px;
	min-width: 75px;
	background-size: 75px;
}

#products_module.responsive.cms_entity .present-text-3.details-gift-text {
	font-size: 1.5375rem;
	line-height: 1.5rem;
	padding-left: 10px;
}

#products_module.responsive.cms_entity .freegiftdescriptor {
	margin-top: 0px;
}

#products_module.responsive.cms_entity .freegiftdescriptor .present-text-3 {
	padding-right: 0rem;
	color: #676767;
	font-size: 0.8rem;
	line-height: 0.95rem;
	font-weight: 500;
	text-align: center;
}

#products_module.responsive.cms_entity .worth {
	text-transform: capitalize;
}

#products_module.responsive.cms_entity .worth-price {
	font-weight: 700;
}

#products_module.responsive.cms_entity .freegiftitems .present-icon-wrapper-3 {
	padding: 40px 0;
}

@media (max-width: 991px) {
	#products_module.responsive.cms_entity .product-details-info-wrapper {
		width: 100%;
		margin-top: 0.5rem;
		border-left-style: none;
	}

	#products_module.responsive.cms_entity .stock-location-text {
		width: 65%;
		max-width: none;
	}

	#products_module.responsive.cms_entity .product-details-slideshow-wrapper {
		padding-right: 0;
		width: 100%;
	}

	#products_module.responsive.cms_entity .product-details-specs-tbl td.value {
		width: 99%;
	}
}

@media (max-width: 767px) {
	#products_module.responsive.cms_entity .carousel-slider {
		margin-top: 15px;
	}

	#products_module.responsive.cms_entity .freegifts {
		border-top: 0 none;
	}

	#products_module.responsive.cms_entity .info-and-pricing {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	#products_module.responsive.cms_entity .product-description-div {
		width: 100%;
	}

	#products_module.responsive.cms_entity .price-and-shipping-div {
		width: 100%;
	}

	#products_module.responsive.cms_entity .tab-spec-list-item {
		-webkit-box-align: start;
		-ms-flex-align: start;
		-webkit-align-items: flex-start;
		align-items: flex-start;
	}

	#products_module.responsive.cms_entity .color-flex-row {
		flex-direction: column;
	}

	#products_module.responsive.cms_entity .color-flex-col-1,
	#products_module.responsive.cms_entity .color-flex-col-2,
	#products_module.responsive.cms_entity .color-flex-col-3 {
		width: 100%;
		text-align: center;
		flex-basis: auto;
	}

	#products_module.responsive.cms_entity .color-flex-col-2 {
		margin: 5px 0;
	}

	#products_module.responsive.cms_entity .tab-spec-list-item {
		padding-right: 0.75rem;
		padding-left: 0.75rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	#products_module.responsive.cms_entity .stock-location-text {
		max-width: 100%;
		padding-bottom: 0px;
		text-align: center;
	}

	#products_module.responsive.cms_entity .stockstatus {
		width: auto;
		padding-right: 0.5rem;
		border-left-style: none;
	}

	#products_module.responsive.cms_entity .please-call-spec-text {
		text-align: center;
	}

	#products_module.responsive.cms_entity .video-wrapper {
		float: none;
		clear: both;
		width: 100%;
		position: relative;
		padding-bottom: 56.25%;
		height: 0;
	}

	#products_module.responsive.cms_entity .videos-tab-video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		min-width: 0;
		min-height: 0;
	}

	#products_module.responsive.cms_entity .freegifts {
		margin-top: 10px;
	}

	#products_module.responsive.cms_entity .freegiftitems {
		text-align: center;
		display: block;
	}

	#products_module.responsive.cms_entity .freegiftblock.one-third {
		padding-left: 0px !important;
		display: inline-block;
		margin: 0 auto;
	}

	#products_module.responsive.cms_entity .freegiftitems .product-detail-page.one-third {
		justify-content: center;
	}

	#products_module.responsive.cms_entity .freegiftitems .present-icon-wrapper-3:after {
		content: ' ';
		border: none;
		border-left: 100px solid transparent;
		border-right: 100px solid transparent;
		border-top: 35px solid hsla(0, 0%, 75%, 0.35);
		z-index: 1000;
		display: block;
		background-color: #fff;
		position: static;
	}

	#products_module.responsive.cms_entity .freegiftitems .present-text-wrapper {
		width: auto;
	}

	#products_module.responsive.cms_entity .freegiftitems .present-icon-wrapper-3 {
		flex-wrap: wrap;
		width: 200px;
		padding: 10px 10px 0;
	}

	#products_module.responsive.cms_entity .tab-spec-list.specifications td,
	#products_module.responsive.cms_entity .tab-spec-list.specifications td.label,
	#products_module.responsive.cms_entity .tab-spec-list.specifications td.value {
		display: block;
		width: 100%;
		white-space: normal;
		padding-left: 10px;
		padding-right: 10px;
	}

	#products_module.responsive.cms_entity .present-icon-2 {
		background-size: 60px;
		min-width: 60px;
	}

	#products_module.responsive.cms_entity .tab-spec-list.specifications td.label {
		border-bottom: 0px;
		padding-bottom: 0px;
	}

	#products_module.responsive.cms_entity .tab-spec-list.specifications td.value {
		padding-top: 0px;
	}
}

@media (max-width: 479px) {
	#products_module.responsive.cms_entity .freegiftitems {
		flex-direction: column;
		width: 100%;
	}

	#products_module.responsive.cms_entity .present-icon-wrapper-3:after {
		border: 0 none;
	}

	#products_module.responsive.cms_entity .present-icon-wrapper-3 {
		height: auto;
	}

	#products_module.responsive.cms_entity .product-detail-page.one-third {
		flex-basis: auto;
		justify-content: center;
	}

	#products_module.responsive.cms_entity .present-text-3.details-gift-text {
		padding-right: 20px;
	}

	#products_module.responsive.cms_entity .freegiftitems .freegiftblock.one-third:first-of-type,
	#products_module.responsive.cms_entity .freegiftblock.one-third {
		padding-left: 0;
	}

	#products_module.responsive.cms_entity .product-details-specs-tbl td.value {
		width: auto;
	}
}

/*cart*/
.order-product-notifications-dismiss-all button.order-product-notification-dismiss-all-button[type=submit],
.order-product-notifications-dismiss-all button.order-product-notification-dismiss-all-button {
	color: #000000 !important;
	background: #ffee8c !important;
	border: 1px solid #000000 !important;
	font-family: sans-serif;
	border-radius: 2px !important;
	text-decoration: none !important;
	width: auto !important;
}

.order-product-notifications-dismiss-all button.order-product-notification-dismiss-all-button[type=submit]:hover,
.order-product-notifications-dismiss-all button.order-product-notification-dismiss-all-button:hover {
	background: #ffffff !important;
}

/*checkout*/
#s-customer #s-sign-in em {
	color: #eb0000;
}

#s-customer #s-sign-in .ui-form-label {
	height: auto;
}

#s-checkout .ui-data-button[data-ordershipmentid] {
	right: 0 !important;
}

#s-checkout #shipping-options {
	margin-top: 40px;
}

#s-checkout .select2-container .select2-choice,
#s-customer-register .select2-container .select2-choice {
	height: 33px;
	padding-left: 10px;
	padding-right: 10px;
}

#s-checkout .select2-container .select2-choice abbr,
#s-customer-register .select2-container .select2-choice abbr {
	display: block;
	opacity: 0.7;
	top: 9px;
}

#s-checkout .select2-container .select2-choice .select2-arrow,
#s-customer-register .select2-container .select2-choice .select2-arrow {
	background-color: transparent;
	background-image: none;
	border: 0 none;
}

#s-checkout .select2-container .select2-choice .select2-arrow b,
#s-customer-register .select2-container .select2-choice .select2-arrow b {
	background-position: 0 3px;
}

#s-checkout .select2-container.select2-container-disabled,
#s-customer-register .select2-container.select2-container-disabled {
	background-color: rgb(235, 235, 228);
}

#s-checkout .select2-container .select2-choice > .select2-chosen,
#s-customer-register .select2-container .select2-choice > .select2-chosen {
	height: 100%;
	line-height: 2.3;
	font-weight: 400;
	font-size: 0.875rem;
	color: #333333;
}

#s-checkout .ui-form-fields-payment-methods-radio .ui-form-label {
	height: auto;
}

#s-checkout .ui-form-fields-payment-methods-radio.ui-form-field-subtitle .ui-form-label span {
	font-size: 100%;
	font-weight: 400;
	color: #333;
}

#s-checkout #ui-ship-to-addresses .ship-to-address-option .form-ship-to-address .ui-form-container > a {
	word-break: break-word;
}

#s-checkout h2.add-a-new-address-header {
	border-top: 1px solid rgba(0, 0, 0, 0.15);
}

/*search facets*/
.facets-left-col {
	width: 21%;
	margin-bottom: 0px;
	padding-right: 0px;
}

.top-filtering-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: auto;
	margin-top: 0rem;
	margin-right: 0rem;
	margin-left: 0.35rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.search-show-mobile-filter-container {
	margin-top: 10px;
}

.search-facets-container {
	overflow: hidden;
	margin-top: -1.5rem;
}

.search-facet-filter-applied-clear-all {
	display: inline-block;
}

.search-facet-clear-all,
.search-facets .clear-facet-link {
	display: inline-block;
	width: auto;
	float: none;
}

.search-facets .clear-facet-link {
	margin-bottom: 14px;
}

.search-facets .search-facet .price-range-symbols {
	padding-top: 3px;
	color: #333;
}

.search-facets .search-facet .price-range-button a {
	background-color: #ffcb05;
	height: 30px;
	min-width: auto;
	margin-right: 0px;
	margin-bottom: 0px;
	margin-left: 5px;
	padding-top: 4px;
	font-size: 0.75rem;
	font-weight: 500;
	text-align: center;
	text-transform: uppercase;
	border-bottom: 0 none;
	flex: 0 auto;
	color: #000;
}

.search-facets .search-facet .price-range-button a:hover {
	background-color: hsla(0, 0%, 74.5%, 0.75);
}

.search-facets .search-facet.longer {
	margin-bottom: 0;
	padding-bottom: 0;
}

.search-facet.collapsed .label-section-title-2 {
	background-image: url('/20190625095811/assets/images/search-icons-plus-grey.svg');
	background-size: 15px 15px;
	background-repeat: no-repeat;
}

.search-facet.collapsed:hover .label-section-title-2 {
	background-image: url('/20190625095811/assets/images/search-icons-plus-white.svg');
}

.search-facet-options-list .search-facet-options-list-items {
	max-height: 245px;
	overflow-y: auto;
	padding-top: 10px;
	margin-bottom: 10px;
}

.search-facets .search-facet {
	padding: 0;
}

.search-facet-show-all-button {
	font-weight: bold;
	font-size: 0.9em;
	border-bottom: 1px solid #ffcb05;
	cursor: pointer;
	margin-bottom: 20px;
}

.search-facet-show-all-button-container {
	padding-bottom: 10px;
}

.side-filter-buttons {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.search-show-mobile .side-filter-buttons.clearall {
	padding-right: 10px;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-start;
	margin-bottom: 10px;
}

.side-filter-buttons.clearall {
	padding-right: 10px;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.filter-products-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-left: 0rem;
	float: none;
	border-bottom: 1px solid #e4c700;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #333;
	font-size: 0.75rem;
	text-decoration: none;
}

.search-filter-title-2 {
	width: auto;
	height: 51px;
	padding: 0.35rem 0rem;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	color: #676767;
	font-size: 1rem;
	line-height: 2.5rem;
	font-weight: 600;
}

.filter-checkbox {
	padding-top: 0.1rem;
	padding-bottom: 0.1rem;
	padding-left: 0rem;
	font-size: 0.825rem;
	line-height: 1.25rem;
	display: flex;
}

.search-facets .search-facet .filter-checkbox-box {
	margin-top: 0;
}

.search-facets .search-facet .filter-checkbox-box {
	height: 14px;
	width: 14px;
}

.search-facets .search-facet .checkbox-label {
	margin: 0 5px;
	font-size: 0.9em;
}

div.search-facet-options-list div.search-facet-options-list-items div.filter-checkbox input.filter-checkbox-box + label.checkbox-label {
	font-weight: normal;
}

.controller-pages-search .search-facet-options-list .search-facet-options-list-items > .filter-checkbox > .checkbox-label {
	font-weight: bold;
}

.search-facets .search-facet .filter-checkbox {
	height: auto;
	margin-bottom: 7px;
}

.clear-facet-link {
	font-size: 0.75rem;
}

.grouped-options-header {
	padding: 0.3rem 0.25rem;
	border-left: 4px solid transparent;
	clear: both;
	cursor: pointer;
}

.grouped-options-title {
	padding-right: 20px;
	background-image: url('../images/search-icons-plus-grey.svg');
	background-position: 100% 50%;
	background-size: 12px 12px;
	background-repeat: no-repeat;
	color: #333;
	font-weight: 400;
	font-size: 0.9rem;
	line-height: 1.5rem;
}

.grouped-options-header:hover {
	background-color: hsla(0, 0%, 85%, 0.49);
}

.grouped-options-header.expanded .grouped-options-title {
	background-image: url('../images/search-icons-minus-grey.svg');
}

.grouped-options-header.expanded {
	background-color: hsla(0, 0%, 85%, 0.39);
}

.grouped-options-header.expanded:hover {
	background-color: hsla(0, 0%, 85%, 0.39);
}

.grouped-options {
	display: none;
	overflow: hidden;
	padding-left: 0.75rem;
	padding-right: 0.75rem;
	padding-top: 7px;
	background-color: hsla(0, 0%, 85%, 0.19);
}

#search-facets-form .top-filtering-div .side-filter-buttons.clearall {
	line-height: 0.8rem;
	text-align: center;
	margin-top: 2px;
	padding-right: 5px;
	padding-left: 5px;
}

#search-facets-form .top-filtering-div .side-filter-buttons.clearall a.search-facet-clear-all {
	line-height: 0.9rem;
}

#search-facets-form .top-filtering-div .search-filter-title-2 {
	line-height: 1.2rem;
	display: flex;
	align-items: center;
}

#search-facets-form .search-face-type-price .search-facet-options-list .search-facet-options-list-items {
	margin-bottom: 5px;
}

#search-facets-form .search-face-type-price .search-facet-options-list .search-facet-options-list-items .price-range-symbols,
#search-facets-form .search-face-type-price .search-facet-options-list .search-facet-options-list-items .price-range,
#search-facets-form .search-face-type-price .search-facet-options-list .search-facet-options-list-items .price-range-button {
	margin-bottom: 5px;
}

.search-facets-container {
	padding-bottom: 7px;
}

.search-facets-container .selected-facet-option {
	display: inline-block !important;
	padding: 4px 7px;
	margin-right: 6px;
	margin-bottom: 8px;
	background-color: #e9e9e9;
	box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.2);
	font-weight: bold;
	color: #000;
}

.search-facets-container .selected-facet-option a {
	text-decoration: none;
	background-color: #fff;
	padding: 7px 8px;
	margin-right: 4px;
	border-radius: 70%;
	line-height: 10px;
	color: #000;
	display: inline-block;
}

.search-facets-container .selected-facet-option a:hover {
	background-color: #ffcb05;
}

.search-show-screen {
	display: none !important;
}

.search-facet-region-ad-container {
	margin-bottom: 25px;
}

@media (max-width: 1380px) and (min-width: 721px) {
	/*product tile*/
	.in-stock-product-code-div > div {
		padding-left: 0;
		padding-right: 0;
		padding-bottom: 2px;
	}
}

@media (min-width: 991px) {
	/*search facets*/
	.collapse-facets-wrapper {
		display: none !important;
	}

	#search-facets-form {
		display: block !important;
	}

	.search-show-mobile {
		display: none !important;
	}

	.search-show-screen {
		display: block !important;
		margin-top: 10px;
	}
}

@media (max-width: 991px) {
	/*search facets*/
	.label-section-title-2 {
		background-color: hsla(0, 0%, 85%, 0.49);
	}

	.label-section-title-2:hover {
		color: #333;
	}

	.top-filtering-div {
		height: auto;
		margin-top: 0.25rem;
		margin-bottom: 0.25rem;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.search-filter-title-2 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 85%;
		padding-left: 0.35rem;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.search-heading-div-2 {
		width: 100%;
	}

	.facets-left-col {
		width: 100%;
	}

	.left-nav-column-div {
		padding-right: 0px;
	}

	.collapse-facets-wrapper {
		display: block !important;
		background-color: #ffcb05;
		padding: 0.45rem 0.5rem;
		margin-bottom: 1px;
		-webkit-transition: all 300ms ease;
		transition: all 300ms ease;
	}

	.collapse-facets-wrapper:hover {
		background-color: hsla(0, 0%, 75%, 0.75);
	}

	#collapse-facets {
		border-bottom: 0 none;
		color: #000;
		width: 100%;
		display: inline-block;
		position: relative;
	}

	#collapse-facets:after {
		content: "\e603";
		font-family: 'webflow-icons' !important;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		line-height: 1;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		position: absolute;
		right: 15px;
		top: 5px;
		-webkit-transition: transform 300ms ease;
		transition: transform 300ms ease;
	}

	#collapse-facets.expanded:after {
		-webkit-transform: rotateX(180deg);
		transform: rotateX(180deg);
	}

	.search-facets-container {
		border-right: 0 none;
		margin-top: 0;
		margin-right: 0;
	}

	#search-facets-form {
		display: none;
	}

	.search-facets .search-facet .checkbox-label {
		margin: 0 5px 5px 7px;
		line-height: 15px;
		font-size: 1.1em;
	}

	.side-filter-buttons {
		margin-top: 0px;
		padding-right: 0px;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.filter-products-link {
		margin-left: 0rem;
	}

	/*ribbons*/
	.top-filtering-div {
		margin-left: 0;
	}
	/*checkout*/
	#s-checkout fieldset:not(.collapsed) {
		padding: 0 15px 15px;
	}
}

@media (max-width: 767px) {
	/*level 3 list page*/
	.pagination-wrapper .pagination-filter.pagination-bg.pagination-page-selection-top {
		display: flex;
	}

	.fly-out-menu-container .fly-out-menu-button,
	.pagination-filter.pagination-bg.pagination-page-selection-top {
		font-size: 0.75rem;
	}

	.pagination-filter.pagination-bg.pagination-sort-items {
		max-width: 30%;
	}

	.pagination-wrapper .pagination-sort-items .fly-out-menu-container_SortBy {
		width: 100%;
	}

	.fly-out-menu-container .fly-out-menu-button {
		width: 100% !important;
	}

	/*search facets*/
	.top-filtering-div {
		height: auto;
		margin-right: 0rem;
		margin-left: 0rem;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-align-content: space-between;
		-ms-flex-line-pack: justify;
		align-content: space-between;
	}

	.search-filter-title-2 {
		width: 80%;
		border-bottom: 1px none #d8d8d8;
	}

	.side-filter-buttons {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-top: 0px;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
		-webkit-align-content: space-between;
		-ms-flex-line-pack: justify;
		align-content: space-between;
	}

	.filter-products-link {
		margin-left: 0rem;
	}

	/*checkout*/
	#s-checkout fieldset.collapsed .ui-form-collapsed-panel > div > * {
		float: none;
		width: 100%;
	}

	#s-checkout fieldset.collapsed .ui-form-collapsed-panel div .ui-data-button,
	#s-checkout fieldset.collapsed .ui-data-payment .ui-data-button {
		position: relative !important;
		bottom: auto !important;
		right: auto !important;
	}

	#s-checkout fieldset.collapsed .ui-data-payment .ui-data-button {
		margin-left: 1rem;
	}

	#s-checkout fieldset.collapsed .ui-data-payments {
		padding-bottom: 0;
		margin-bottom: 0;
	}

	#search-facets-form .top-filtering-div .search-filter-title-2 {
		width: 70%;
	}
}

@media (max-width: 500px) {
	/*level 3 list page*/
	.pagination-filter.pagination-bg.pagination-sort-items {
		max-width: 50%;
	}
}

@media (max-width: 400px) {
	/*level 3 list page*/
	.pagination-filter.pagination-bg.pagination-sort-items {
		max-width: none;
		width: 100%;
	}
	/*product tile*/
	.in-stock-product-code-div > div {
		padding-left: 0;
		padding-right: 0;
		padding-bottom: 2px;
	}
}
